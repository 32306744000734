import React from "react";
import "./sidefund.css";
import ProgressBar from "react-bootstrap/ProgressBar";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import { Link, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
const Sidefund = () => {
  const { state } = useLocation();
  return (
    <div className="sidefund">
      <Link
        className="contribute-btn"
        style={{
          textDecoration: "none",
        }}
      >
        <div
          style={{
            width: "330px",

            background: "#111",
            padding: "10px",
            textAlign: "center",
            color: "#fff",
            fontSize: "28px",
            fontWeight: "600px",
            borderColor: "#111 ",
          }}
        >
          Contribute Now
        </div>
      </Link>

      <div
        style={{
          marginTop: "50px",
        }}
      >
        <Link
          className=""
          to="/"
          style={{
            textDecoration: "none",
          }}
        >
          <div
            style={{
              width: "330px",

              background: "#111",
              padding: "10px",
              textAlign: "center",
              color: "#fff",
              fontSize: "24px",
              fontWeight: "600px",
              borderColor: "#111 ",
            }}
          >
            <FacebookIcon />
            <span
              style={{
                padding: "70px",
              }}
            >
              Share
            </span>
          </div>
        </Link>
      </div>

      <div style={{ marginTop: "30px", fontSize: "32px" }}>
        ₹ {state?.raisedAmt}
      </div>

      <div
        style={{
          fontSize: "20px",
        }}
      >
        raised of ₹ {state?.goalAmt} goal
      </div>
      <br></br>

      <ProgressBar now={Number((state?.raisedAmt / state?.goalAmt) * 100)} />

      <div
        style={{
          marginTop: "30px",
          width: "330px",

          background: "#111",
          padding: "10px",
          textAlign: "center",
          color: "#fff",
          fontSize: "24px",
          fontWeight: "600px",
          borderColor: "#111 ",
        }}
      >
        <EmailIcon />
        <span
          style={{
            padding: "70px",
          }}
        >
          {" "}
          Contact
        </span>
      </div>

      <div
        style={{
          marginTop: "30px",
          boxShadow:
            " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          width: "330px",
          height: "auto",

          background: "#FFF",
          padding: "20px",
          textAlign: "center",
          color: "#111",
          fontSize: "24px",
          fontWeight: "550",
          borderColor: "#111 ",
        }}
      >
        <p style={{}}> Top Donor's </p>

        <ul
          style={{
            marginTop: "20px",
            textAlign: "center",
            marginLeft: "-30px",
          }}
        >
          <li className="list-donors" style={{ marginTop: "10px" }}>
            {" "}
            <div>
              <img
                src="./color.jpg"
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              ></img>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              Full Name
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              Rs : 10,0000
            </div>
          </li>

          <li className="list-donors" style={{ marginTop: "10px" }}>
            {" "}
            <div>
              <img
                src="./color.jpg"
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              ></img>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              Full Name
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              Rs : 10,0000
            </div>
          </li>

          <li className="list-donors" style={{ marginTop: "10px" }}>
            {" "}
            <div>
              <img
                src="./color.jpg"
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              ></img>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              Full Name
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              Rs : 10,0000
            </div>
          </li>

          <li className="list-donors" style={{ marginTop: "10px" }}>
            {" "}
            <div>
              <img
                src="./color.jpg"
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              ></img>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              Full Name
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              Rs : 10,0000
            </div>
          </li>

          <li className="list-donors" style={{ marginTop: "10px" }}>
            {" "}
            <div>
              <img
                src="./color.jpg"
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              ></img>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              Full Name
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              Rs : 10,0000
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidefund;
