import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
function Aboutus() {
  /* ======================================================================================================== */
  // post banner
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //   edit Banner modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  //   delete Banner modal
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  /* ======================================================================================================== */
  //   add about us modal
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //   edit about us modal
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // delete about us modal
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  /* ======================================================================================================== */

  const [bannerimg, setbannerimg] = useState("");
  const [Banners, setBanners] = useState([]);
  const [Details, setDetails] = useState({});

  const obj = {
    bannerimg: bannerimg,
  };

  const postBanner = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/postAboutusBanner`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          getBanner();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getBanner = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getAboutusBanner")
        .then((res) => {
          if (res.status === 200) {
            setBanners(res.data.aboutusBannerList);
          }
        });
    } catch (error) {
      console.log(error);
      setBanners([]);
    }
  };

  const obj1 = {
    bannerimg: bannerimg,
  };

  const editBanner = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/editAboutusBanner/${Details?._id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setbannerimg("");
          getBanner();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const deleteBanner = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deleteAboutusBanner/${Details?._id}`,
        method: "delete",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getBanner();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */

  // add about us
  const [aboutusimg, setaboutusimg] = useState("");
  const [Body, setBody] = useState("");
  const [AboutusList, setAboutusList] = useState([]);
  const [AboutusDetails, setAboutusDetails] = useState({});

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setBody(data);
  };
  const aboutusObj = {
    img: aboutusimg,
    content: Body,
  };
  const postAboutus = async (e) => {
    e.preventDefault();
    try {
      try {
        const config = {
          url: `/admin/postAboutus`,
          method: "post",
          baseURL: "https://srivinayaka.info/api",
          headers: { "content-type": "multipart/form-data" },
          data: aboutusObj,
        };
        await axios(config).then((res) => {
          if (res.status === 201) {
            alert(res.data.success);
            setaboutusimg("");
            setBody("");
            getAboutusList();
            handleClose3();
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAboutusList = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getAboutusList")
        .then((res) => {
          if (res.status === 200) {
            setAboutusList(res.data.AboutusList);
          }
        });
    } catch (error) {
      console.log(error);
      setAboutusList([]);
    }
  };

  const serviceobj1 = {
    img: aboutusimg,
    content: Body,
  };

  const editAboutus = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/editAboutus/${AboutusDetails?._id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: serviceobj1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setaboutusimg("");
          setBody("");
          getAboutusList();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const deleteAboutus = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deleteAboutus/${AboutusDetails?._id}`,
        method: "delete",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAboutusList();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */
  useEffect(() => {
    const adminToken = localStorage.getItem("adminToken");
    if (!adminToken) {
      alert("Please login first");
      window.location.assign("/admin");
    }
    getBanner();
    getAboutusList();
  }, []);

  return (
    <div>
      <div
        className="main-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>About us Banner</h4>
        <Button onClick={handleShow}>Add+</Button>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Banner</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Banners?.map((item, i) => {
              return (
                <tr>
                  <td>{++i}.</td>
                  <td>
                    <img
                      src={`https://srivinayaka.info/Aboutus/${item?.bannerimg}`}
                      // {`https://srivinayaka.info/Services/${item?.bannerimg}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "100px",
                      }}
                      alt=""
                    />
                  </td>
                  <td>
                    <BiEdit
                      style={{
                        color: "blue",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setDetails(item);
                        handleShow1();
                      }}
                    />
                    /
                    <AiOutlineDelete
                      style={{ color: "red", marginLeft: "10px" }}
                      onClick={() => {
                        setDetails(item);
                        handleShow2();
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div
        className="secondary-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>About us</h4>
        <Button onClick={handleShow3}>Add+</Button>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Image</th>
              {/* <th>Topic</th> */}
              <th>Body</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {AboutusList?.map((item, i) => {
              return (
                <tr>
                  <td>{++i}.</td>
                  <td>
                    <img
                      src={`https://srivinayaka.info/Aboutus/${item?.img}`}
                      // {`https://srivinayaka.info/Services/${item?.img}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "100px",
                      }}
                      alt=""
                    />
                  </td>
                  {/* <td>{item?.topic}</td> */}
                  <td>{parse(item?.content)}</td>
                  <td>
                    <div className="d-flex">
                      <BiEdit
                        style={{
                          color: "blue",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          setAboutusDetails(item);
                          handleShow4();
                        }}
                      />
                      /
                      <AiOutlineDelete
                        style={{ color: "red", marginLeft: "10px" }}
                        onClick={() => {
                          setAboutusDetails(item);
                          handleShow5();
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {/* add banner modal - starts */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Banner:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                required
                onChange={(e) => setbannerimg(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => postBanner(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add banner modal - ends */}

      {/* edit banner modal - starts */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Banner:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                onChange={(e) => setbannerimg(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => editBanner(e)}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit banner modal - ends */}

      {/* delete banner modal - starts */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the Details?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => deleteBanner(e)}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete banner modal - ends */}

      {/* add About us modal - starts */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Add About us content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Image:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                required
                onChange={(e) => setaboutusimg(e.target.files[0])}
              />
            </Form.Group>
            {/* <Form.Group controlId="">
              <Form.Label>Topic:</Form.Label>
              <Form.Control
                type="text"
                // value={Topic}
                required
                // onChange={(e) => setTopic(e.target.value)}
              />
            </Form.Group> */}
            {/* <Form.Group controlId="">
              <Form.Label>Body:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={Body}
                required
                onChange={(e) => setBody(e.target.value)}
              />
            </Form.Group> */}
          </Form>
          <label>Body: </label>
          <CKEditor
            editor={ClassicEditor}
            data={Body}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => postAboutus(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add About us modal - ends */}

      {/* edit About us modal - starts */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Edit About us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Image:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                required
                onChange={(e) => setaboutusimg(e.target.files[0])}
              />
            </Form.Group>
            {/* <Form.Group controlId="">
              <Form.Label>Topic:</Form.Label>
              <Form.Control
                type="text"
                // value={Topic}
                // placeholder={ServiceDetails?.topic}
                required
                // onChange={(e) => setTopic(e.target.value)}
              />
            </Form.Group> */}
            {/* <Form.Group controlId="">
              <Form.Label>Body:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={Body}
                placeholder={AboutusDetails?.content}
                required
                onChange={(e) => setBody(e.target.value)}
              />
            </Form.Group> */}
          </Form>
          <label>Body: </label>
          <CKEditor
            editor={ClassicEditor}
            data={AboutusDetails?.content}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => editAboutus(e)}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit About us modal - ends */}

      {/* delete About us modal - starts */}
      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>Delete About us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the About us details?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => deleteAboutus(e)}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete About us modal - ends */}
    </div>
  );
}

export default Aboutus;
