import axios from "axios";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BiLogOutCircle } from "react-icons/bi";
import { BsFillChatDotsFill, BsWhatsapp } from "react-icons/bs";
import { GrContact } from "react-icons/gr";

function VolunteerHeader() {
  {
    /* ======================================================================================================= */
  }

  const logout = () => {
    try {
      localStorage.removeItem("voluteerToken");
      localStorage.removeItem("encodeVolInfo");
      alert("logout successfully");
      window.location.assign("/");
    } catch (error) {
      console.log(error);
      alert("something went wrong");
    }
  };
  {
    /* ======================================================================================================= */
  }

  const [Vol, setVol] = useState({});
  const getVolunteerById = async (id) => {
    try {
      const res = await axios.get(
        `https://srivinayaka.info/api/volunteer/getVolunteerByid/${id}`
      );
      if (res.status === 200) {
        setVol(res.data.volunteerDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  {
    /* ======================================================================================================= */
  }

  useEffect(() => {
    const volDetails = JSON.parse(localStorage.getItem("encodeVolInfo"));
    getVolunteerById(volDetails?.id);
  }, []);
  return (
    <>
      <div
        className="header"
        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand href="#">
              {Vol?.vPic ? (
                <img
                  src={`https://srivinayaka.info/Volunteer/${Vol?.vPic}`}
                  alt="logo"
                  style={{
                    width: "75px",
                    height: "75px",
                    borderRadius: "50%",
                    border: "2px solid black",
                  }}
                />
              ) : (
                <img
                  src="./man.png"
                  alt="logo"
                  style={{
                    width: "75px",
                    height: "75px",
                    borderRadius: "50%",
                    border: "2px solid black",
                  }}
                />
              )}
              <div style={{ fontSize: "17px", fontWeight: "600" }}>
                {Vol?.vfName} {Vol?.vlName}
                <div
                  className="d-block"
                  style={{ fontSize: "14px", fontWeight: "600" }}
                >
                  ID:{Vol?._id}
                </div>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="ms-auto my-2 my-lg-0"
                // style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link href="#">
                  <BsFillChatDotsFill
                    style={{ fontSize: "30px", paddingRight: "5px" }}
                  />
                  Contact admin
                </Nav.Link>
                <Nav.Link href="#">
                  <BsWhatsapp
                    style={{
                      fontSize: "25px",
                      color: "green",
                      paddingRight: "5px",
                    }}
                  />
                  Chat with admin
                </Nav.Link>
                <Nav.Link href="#">
                  <BiLogOutCircle
                    style={{ fontSize: "25px", color: "red" }}
                    onClick={logout}
                  />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default VolunteerHeader;
