import React from "react";

 function Paymentconfirm() {
  return (
    <div>
      <h4 style={{ textAlign: "center", padding: "5% 5% 1% 5%" }}>
        <img
          src="../7efs.gif"
          // style={{ width: "200px", height: "200px" }}
        ></img>
        <p style={{ textAlign: "center", padding: "5% 5% 1% 5%",fontSize:"18px",fontWeight:"bold" }}>"Thank you for your generous donation."</p>
      </h4>
      <h5 style={{ textAlign: "center", padding: "0% 5% 5% 5%" }}>
        <a href="/">
        <button
          style={{
            textAlign: "center",
            padding: "1% 2%",
            borderRadius: "20px",
            color: "white",
            backgroundColor: "#25ae88",
            border: "none",
          }}
        >
          Back
        </button>
        </a>
      </h5>
    </div>
  );
}
export default Paymentconfirm