import React from "react";
import "./Payment.css";
import { Link } from "react-router-dom";
const Payment = () => {
  return (
    <div
      className="container"
      style={{
        maxWidth: "600px",
      }}
    >
      <div className="row">
        <div className="col">
          <form className="border">
            <p className="h2 fw-medium text-center p-3">Your Info</p>

            <p className="fs-5 text-center">
              {" "}
              Are you representing an Individual or Organization?
            </p>

            <div class="form-check text-center mt-4">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Individual
              </label>
            </div>
            <div class="form-check  text-center">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Organization
              </label>
            </div>

            <div className="">
              <form className="p-5">
                <div className="row">
                  <div class="mb-3 col">
                    <label for="exampleFormControlInput1" class="form-label">
                      Name / Organization Name
                    </label>
                    <input
                      type="text"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="Name"
                    />
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col">
                    <label for="exampleFormControlInput1" class="form-label">
                      Email / Organization Mail
                    </label>
                    <input
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                    />
                  </div>
                </div>

                <Link to="/pay">
                  {" "}
                  <button className="btn-submit border">Continue</button>
                </Link>
              </form>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Payment;
