import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import moment from "moment";
function Post() {
  const navigate = useNavigate();
  function nextPage(val) {
    navigate("/post", {
      state: val,
    });
  }
  /* ============================================================================================================== */

  const [blogs, setblogs] = useState([]);
  const allLatestBlogs = async () => {
    try {
      const res = await axios.get(
        "https://srivinayaka.info/api/admin/latestBlogs"
      );
      if (res.status === 200) {
        setblogs(res.data.BlogList);
      }
    } catch (error) {
      console.log(error);
      setblogs([]);
    }
  };

  /* ============================================================================================================== */

  useEffect(() => {
    allLatestBlogs();
  }, []);
  return (
    <>
      {blogs?.map((item) => {
        return (
          <Card  className="trt0">
            <Card.Img
              variant="top"
              src={`https://srivinayaka.info/Blogs/${item?.img}`}
              style={{ height: "410px",  }}
            />
            <Card.Body>
              <Card.Title
                style={{
                  fontSize: "28px",
                  color: "#444444",
                  fontWeight: "600",
                  padding: "20px",
                }}
              >
                {item?.topic}
              </Card.Title>
              <Card.Text
                className="card-date"
                style={{ fontSize: "14px", color: "#6B6b6b" }}
              >
                {" "}
                {moment(item?.createdAt).format(`DD MMMM YYYY`)}
              </Card.Text>
              <Card.Text
                className="text-card"
                style={{
                  fontSize: "",
                  color: "",
                }}
              >
                {parse(item?.content?.substr(0, 300))}…
              </Card.Text>

              <span
                onClick={() => nextPage(item)}
                variant="primary"
                className="post-btn"
              >
                Read More
              </span>
            </Card.Body>
          </Card>
        );
      })}
      <br />
    </>
  );
}

export default Post;
