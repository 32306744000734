import React from "react";
import VolunteerHeader from "../Volunteer/VolunteerHeader";
import VolunteerSideBar from "./VolunteerSideBar";
import "./admin.css"

function VolunteerMain(props) {
  return (
    <div className="dash">
      <div className="row me-0">
        <div className="col-md-2 p-0">
          <div className="left-side">
            <VolunteerSideBar />
          </div>
        </div>

        <div className="col-md-10 p-0 right-h ">
          <VolunteerHeader />
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default VolunteerMain;
