import React from 'react'
import Image from 'react-bootstrap/Image';
import HeadBanner from './HeadBanner';
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';
import Navbar from '../Components/Navbar/Navbar';
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import './Servicespage.css'
const Newspage = () => {
  return (
    <div>
      <Header></Header>
      <Navbar/>
      <div >

      

<Image src="./4.jpg" style={{width:'1351px'}} fluid />




</div>




        
        <Footer></Footer>
    </div>
  )
}

export default Newspage