import React from "react";
import { Button } from "react-bootstrap/cjs";

function Volunteerdashboard() {
  return (
    <>
      <div className="ad-dash mt-5">
        <div className="container">
          <div className="vina">
            <div className="vina-left">
             
              
                  <div className="row">
                    <div className="col-md-8">
                     <div className="row">
                   <div className="col-md-4">
                   <div className="lab">
                        <label>From</label><br/>
                        <input type="text" placeholder="from " className="vi_0"/>
                      </div>
                   </div>
                   <div className="col-md-4">
                   <div className="lab">
                        <label>To</label><br/>
                        <input type="text" placeholder="from " className="vi_0"/>
                      </div>
                   </div>
                   <div className="col-md-4">
                   <div className="lab mt-4">
                      
                        <Button>Submit</Button>
                      </div>
                   </div>
                      
                     </div>
                     </div>
                     <div className="col-md-4">
                     <div className="to-2">

                     </div>
                    </div>
                  
              
              </div>
            </div>
         <div className="np-p mt-4">
            <div className="row">
                <div className="col-md-4">
                    <div className="dask">
                        <div className="do-p">No of Donars</div>
                        <div className="no-p"><p>6</p></div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="dask">
                        <div className="do-p">Total Amount</div>
                        <div className="no-p"><p>1000</p></div>
                    </div>
                </div>
            </div>
         </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Volunteerdashboard;
