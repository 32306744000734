import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { AiOutlineEye } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./idcard.css";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";

function NotApprovedVol() {
  const admintoken = localStorage.getItem("adminToken");
  const adminDetails = JSON.parse(localStorage.getItem("encodeInfo"));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Vol, setVol] = useState({});

  const formdata = new FormData();

  const createPDF = async () => {
    try {
      const pdf = new jsPDF("portrait", "pt", "a4");
      const data = await html2canvas(document.querySelector("#pdf"));
      const img = data.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      console.log("before", pdf);
      pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
      // pdf.save("VolunteerID.pdf");
      const PDF = new File([pdf.output("blob")], `${volID}.pdf`, {
        type: "application/pdf",
      });
      console.log("GHGHGHGH", PDF);
      formdata.set("ID_card", PDF);
      // formdata.set("adminid", adminid);
      formdata.set("volunteerid", volID);
      formdata.set("adminid", adminDetails.id);
      formdata.set("adminToken", admintoken);
      formdata.set("adminDetails", JSON.stringify(adminDetails));
      const config = {
        url: "/admin/volunteer/approveVolunteer",
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setvolID("");
          nonAppVols();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose();
    }
  };

  {
    /* ========================================================================================================== */
  }
  const [nonAppVolList, setnonAppVolList] = useState([]);
  const [volID, setvolID] = useState("");

  const nonAppVols = async () => {
    try {
      let res = await axios.get(
        `https://srivinayaka.info/api/admin/volunteer/nonApproveVolList`
      );
      if (res.status === 200) {
        setnonAppVolList(res.data.nonApprovedVolList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  {
    /* ========================================================================================================== */
  }

  const blockStatusChng = async (e, id, val) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/volunteer/changeBlockStatus/" + id,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: { adminToken: admintoken, blockStatus: val },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setvolID("");
          nonAppVols();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  {
    /* ========================================================================================================== */
  }
  useEffect(() => {
    nonAppVols();
  }, []);
  return (
    <div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>ID</th>
                <th>Name</th>
                <th>S/O</th>
                <th>Email Id</th>
                <th>Pnone number</th>
                <th>whatsapp number</th>
                <th>Address</th>
                <th>Age</th>
                <th>Volunteer Interest</th>
                <th>GPS</th>
                <th>Aadhar number</th>
                <th>Pan number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {nonAppVolList.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}.</td>
                    <td>{item?._id}</td>
                    <td>
                      <div className="d-flex">
                        <img
                          src={`https://srivinayaka.info/Volunteer/${item?.vPic}`}
                          style={{
                            width: "40px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                        <span>
                          {item?.vfName} {item?.vlName}
                        </span>
                      </div>
                    </td>
                    <td>{item?.so}</td>
                    <td>{item?.email}</td>
                    <td>{item?.phno}</td>
                    <td>{item?.whatsapp}</td>
                    <td>
                      Dno./Street: {item?.street}, {item?.address},{" "}
                      {item?.taluka}, {item?.dist}, {item?.pincode},{" "}
                      {item?.city}, {item?.state} , {item?.country}
                    </td>
                    <td>{item?.age}</td>
                    <td>
                      {item?.volunteeringInterest == "fulltime" ? (
                        <>Full-Time</>
                      ) : (
                        <>Half-Time</>
                      )}
                    </td>
                    <td>
                      <a href={item?.gpsLoc} target="_blank">
                        {item?.gpsLoc}
                      </a>
                    </td>
                    <td>{item?.aadhar}</td>
                    <td>{item?.pan}</td>
                    <td>
                      <div className="d-flex">
                        <AiOutlineEye
                          title="Approve Volunteer"
                          style={{ color: "#b51512", marginLeft: "10px" }}
                          onClick={() => {
                            setVol(item);
                            setvolID(item?._id);
                            handleShow();
                          }}
                        />
                        {item.blockStatus ? (
                          <>
                            <Button
                              style={{
                                backgroundColor: "red",
                                marginLeft: "10px",
                              }}
                              onClick={(e) => {
                                // setvolID(item?._id);
                                blockStatusChng(e, item?._id, "false");
                              }}
                            >
                              Unblock
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              style={{
                                backgroundColor: "green",
                                marginLeft: "10px",
                              }}
                              onClick={(e) => {
                                // setvolID(item?._id);
                                blockStatusChng(e, item?._id, "true");
                              }}
                            >
                              Block
                            </Button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {/* show ID card and approval button - modal: starts */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Approve Volunteer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="pdf" className="id_vl container">
                <div class="id-card-wrapper ">
                  <div className="logo-company">
                    {Vol.vPic ? (
                      <img
                        src={`https://srivinayaka.info/Volunteer/${Vol?.vPic}`}
                        alt="adminlogo"
                        style={{ width: "364px", height: "100px" }}
                      />
                    ) : (
                      <img
                        src="./implogo.jpeg"
                        alt="adminlogo"
                        style={{ width: "364px", height: "100px" }}
                      />
                    )}
                  </div>
                  <div class="id-card semicircle">
                    <div class="profile-row">
                      <div class="dp  ">
                        <div class="dp-arc-outer"></div>
                        <div class="dp-arc-inner"></div>
                        <img src="./man.png" />
                      </div>
                      <div class="desc">
                        <h1>
                          {Vol?.vfName} {Vol?.vlName}
                        </h1>
                        <p>ID: #{Vol?._id}</p>
                        <p>Place: {Vol?.city}</p>
                        <p>Phone: {Vol?.phno}</p>
                        <p>
                          EXPIRES:{" "}
                          <span>
                            {moment(
                              moment(Vol?.createdAt).add(1, "years").calendar()
                            ).format("DD/MM/YYYY")}
                          </span>
                        </p>
                      </div>
                      <div className="desc_0">
                        <p>VOLUNTEER</p>
                      </div>
                      <p className="text-center">www.vinayakatrust.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={createPDF}
                style={{ backgroundColor: "#8cb603" }}
              >
                Approve
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show ID card and approval button - modal: ends */}
        </div>
      </div>
    </div>
  );
}

export default NotApprovedVol;
