import React from "react";
import "./header.css";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <ul className="header-list">
      <li>
        <div className="sri" style={{}}>
          Welcome to Sri Vinayaka Charitable Trust,
        </div>
      </li>
      <li
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "50%",
          textAlign: "end",
        }}
      >
        <div className="sri" style={{ margin: "0px 30px" }}>
          srivinayakacharitabletrust@gmail.com
        </div>
        <div className="sri" style={{ margin: "0px 30px" }}>
          08095719080
        </div>
      </li>
      <li>
        <Link to="/volunteerlogin">
          {" "}
          <div className="sri"> volunteer's Login</div>
        </Link>
      </li>

      <li>
        <div className="login">
          <Link to="/login">Login</Link>
          <Link to="/reigster">Signup</Link>
        </div>
      </li>
    </ul>
  );
};

export default Header;
