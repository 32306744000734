import React from "react";
// import './Pay.css'
import { Link } from "react-router-dom";
const Pay = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <p className="h1 fw-medium text-center mb-5"> Details</p>

          <form className="border">
            <div className="border-top border-bottom p-5">
              <div className="row align-items-center justify-content-between">
                <div className="col">
                  <p> 1. Your Order </p>
                </div>

                <div className="col text-end">
                  <span>$25.75</span>{" "}
                  <button className="btn btn-primary ml-4 mr-2">Edit</button>
                </div>
              </div>
            </div>

            <div className="">
              <form className="p-5">
                <p className="h3 fw-medium  mb-5">Payment</p>

                <div className="row">
                  <div class="mb-3 col">
                    <label for="exampleFormControlInput1" class="form-label">
                      Full Name (as it appears on the card)
                    </label>
                    <input
                      type="text"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder=" FullName"
                    />
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col">
                    <label for="exampleFormControlInput1" class="form-label">
                      Card Number
                    </label>
                    <input
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="  Card Number"
                    />
                  </div>
                </div>

                <div className="mb-4 mt-2">
                  Accepted Cards <img src="" alt="imagehere"></img>
                </div>

                <div className="row">
                  <div class="mb-3 col-lg-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      Expiration Month
                    </label>
                    <select
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="  Card Number"
                    >
                      <option> Choose...</option>
                      <option></option>
                      <option></option>
                      <option></option>
                    </select>
                  </div>{" "}
                  <p className="h1 fw-medium text-center mb-5">Receipt</p>
                  <div class="mb-3 col-lg-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      Expiration Year
                    </label>
                    <select
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="  Card Number"
                    >
                      <option> Choose...</option>
                      <option></option>
                      <option></option>
                      <option></option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col-lg-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      Security Code (CVV)
                    </label>
                    <input
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="  Card Number"
                    />
                  </div>
                </div>

                <p className="h3 fw-medium  mb-5 mt-5">Billing Address</p>

                <div className="row">
                  <div class="mb-3 col">
                    <label for="exampleFormControlInput1" class="form-label">
                      Street Address
                    </label>
                    <input
                      type="text"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col">
                    <label for="exampleFormControlInput1" class="form-label">
                      Street Address 2
                    </label>
                    <input
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder=" "
                    />
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col">
                    <label for="exampleFormControlInput1" class="form-label">
                      City
                    </label>
                    <input
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder=" "
                    />
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col-lg-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      State / Province
                    </label>
                    <select
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="  Card Number"
                    >
                      <option> Choose...</option>
                      <option></option>
                      <option></option>
                      <option></option>
                    </select>
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      Zip / Postal Code
                    </label>
                    <input
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col-lg-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      Country
                    </label>
                    <select
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="  Card Number"
                    >
                      <option> Choose...</option>
                      <option></option>
                      <option></option>
                      <option></option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col-lg-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      Phone Number
                    </label>
                    <input
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="  Card Number"
                    />
                  </div>
                </div>

                <Link to="/receipt">
                  {" "}
                  <button className="btn-submit border">Continue</button>
                </Link>
              </form>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Pay;
