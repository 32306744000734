import React from "react";
import { Link } from "react-router-dom";
import "./Payment.css";
const Receipt = () => {
  return (
    <div
      className="container"
      style={{
        maxWidth: "500px",
      }}
    >
      <div className="row">
        <div className="col">
          <div className="border">
            <p className="h3 fw-medium text-center border p-3">Receipt</p>

            <div className=" mt-5 ">
              <div
                className="border  "
                style={{ margin: "auto", maxWidth: "250px" }}
              >
                <div className="mt-3 mb-3 text-center fs-5">
                  {" "}
                  Thank You for your Donation
                </div>

                <div className="mb-3 " style={{}}>
                  <div className="row">
                    <div className="col fs-5 text-center">RS: 10,000</div>
                  </div>
                </div>
              </div>

              <div className="border mt-5">
                <div className="mt-4 text-center ">
                  Sri Vinayaka Charitable Trust
                </div>
                <div className="  text-center">
                  <Link>srivinayakacharitabletrust@gmail.com</Link>
                </div>
                <div className=" text-center">08095719080</div>

                <div className=" text-center ">
                  SriVinayakaCharitableTrust.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
