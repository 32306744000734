import React, { useEffect, useState } from "react";
import "./idcard.css";
import axios from "axios";
import moment from "moment";

function VolunteerIdCard() {
  {
    /* ======================================================================================================= */
  }

  const [Vol, setVol] = useState({});
  const getVolunteerById = async (id) => {
    try {
      const res = await axios.get(
        `https://srivinayaka.info/api/volunteer/getVolunteerByid/${id}`
      );
      if (res.status === 200) {
        setVol(res.data.volunteerDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  {
    /* ======================================================================================================= */
  }

  useEffect(() => {
    const volDetails = JSON.parse(localStorage.getItem("encodeVolInfo"));
    getVolunteerById(volDetails?.id);
  }, []);
  return (
    <>
      <div className="id_vl container">
        <div className="row me-0">
          <div className="col-md-3">
            <div class="id-card-wrapper mt-3">
              <div className="logo-company">
                {Vol?.vPic ? (
                  <img
                    src={`https://srivinayaka.info/Volunteer/${Vol?.vPic}`}
                    alt="adminlogo"
                    style={{ width: "256px", height: "100px" }}
                  />
                ) : (
                  <img
                    src="./implogo.jpeg"
                    alt="adminlogo"
                    style={{ width: "256px", height: "100px" }}
                  />
                )}
              </div>
              <div class="id-card semicircle">
                <div class="profile-row">
                  <div class="dp  ">
                    <div class="dp-arc-outer"></div>
                    <div class="dp-arc-inner"></div>
                    <img src="./man.png" />
                  </div>
                  <div class="desc">
                    <h1>
                      {Vol?.vfName} {Vol?.vlName}
                    </h1>
                    <p>ID: #{Vol?._id}</p>
                    <p>Place: {Vol?.city}</p>
                    <p>Phone: {Vol?.phno}</p>
                    <p>
                      EXPIRES:{" "}
                      <span>
                        {moment(
                          moment(Vol?.createdAt).add(1, "years").calendar()
                        ).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </div>
                  <div className="desc_0">
                    <p>VOLUNTEER</p>
                  </div>
                  <p className="text-center">www.vinayakatrust.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VolunteerIdCard;
