import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";

function Founderandtrustee() {
  /* ======================================================================================================== */
  //   add founder modal
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //   edit founder modal
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // delete founder modal
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  /* ======================================================================================================== */

  // add trustee modal
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // edit trustee modal
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // delete trustee modal
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  /* ======================================================================================================== */

  // add founder
  const [founderimg, setfounderimg] = useState("");
  const [Body, setBody] = useState("");
  const [FounderList, setFounderList] = useState([]);
  const [FounderDetails, setFounderDetails] = useState({});

  // add Trustee
  const [trusteeimg, settrusteeimg] = useState("");
  const [trusteename, settrusteename] = useState("");
  //  const [trusteeBody, settrusteeBody] = useState("");
  const [TrusteeList, setTrusteeList] = useState([]);
  const [TrusteeDetails, setTrusteeDetails] = useState({});

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setBody(data);
  };
  const founderObj = {
    img: founderimg,
    content: Body,
  };
  const postFounder = async (e) => {
    e.preventDefault();
    try {
      try {
        const config = {
          url: `/admin/postFounder`,
          method: "post",
          baseURL: "https://srivinayaka.info/api",
          headers: { "content-type": "multipart/form-data" },
          data: founderObj,
        };
        await axios(config).then((res) => {
          if (res.status === 201) {
            alert(res.data.success);
            setfounderimg("");
            setBody("");
            getFounderList();
            handleClose3();
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFounderList = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getFounderList")
        .then((res) => {
          if (res.status === 200) {
            setFounderList(res.data.founderList);
          }
        });
    } catch (error) {
      console.log(error);
      setFounderList([]);
    }
  };

  const founderObj1 = {
    img: founderimg,
    content: Body,
  };

  const editFounder = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/editFounder/${FounderDetails?._id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: founderObj1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setfounderimg("");
          setBody("");
          getFounderList();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const deleteFounder = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deleteFounder/${FounderDetails?._id}`,
        method: "delete",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getFounderList();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */

  const trusteeObj = {
    img: trusteeimg,
    name: trusteename,
    content: Body,
  };
  const postTrustee = async (e) => {
    e.preventDefault();
    try {
      try {
        const config = {
          url: `/admin/postTrustee`,
          method: "post",
          baseURL: "https://srivinayaka.info/api",
          headers: { "content-type": "multipart/form-data" },
          data: trusteeObj,
        };
        await axios(config).then((res) => {
          if (res.status === 201) {
            alert(res.data.success);
            settrusteeimg("");
            settrusteename("");
            setBody("");
            getTrusteeList();
            handleClose6();
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTrusteeList = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getTrusteeList")
        .then((res) => {
          if (res.status === 200) {
            setTrusteeList(res.data.trusteeList);
          }
        });
    } catch (error) {
      console.log(error);
      setTrusteeList([]);
    }
  };

  const trusteeObj1 = {
    img: trusteeimg,
    name: trusteename,
    content: Body,
  };

  const editTrustee = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/editTrustee/${TrusteeDetails?._id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: trusteeObj1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          settrusteeimg("");
          settrusteename("");
          setBody("");
          getTrusteeList();
          handleClose7();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const deleteTrustee = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deleteTrustee/${TrusteeDetails?._id}`,
        method: "delete",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getTrusteeList();
          handleClose8();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getTrusteeList();
  }, []);
  return (
    <div>
      <div
        className="secondary-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>Founder</h4>
        <Button onClick={handleShow3}>Add+</Button>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Image</th>
              {/* <th>Topic</th> */}
              <th>Body</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {FounderList?.map((item, i) => {
              return (
                <tr>
                  <td>{++i}.</td>
                  <td>
                    <img
                      src={`https://srivinayaka.info/Founder/${item?.img}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "100px",
                      }}
                      alt=""
                    />
                  </td>
                  {/* <td>{item?.topic}</td> */}
                  <td> {parse(`<div>${item?.content}</div>`)} </td>
                  <td>
                    <div className="d-flex">
                      <BiEdit
                        style={{
                          color: "blue",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          setFounderDetails(item);
                          handleShow4();
                        }}
                      />
                      /
                      <AiOutlineDelete
                        style={{ color: "red", marginLeft: "10px" }}
                        onClick={() => {
                          setFounderDetails(item);
                          handleShow5();
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div
        className="secondary-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>Trustee</h4>
        <Button onClick={handleShow6}>Add+</Button>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Image</th>
              <th>Name</th>
              <th>Body</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {TrusteeList?.map((item, i) => {
              return (
                <tr>
                  <td>{++i}.</td>
                  <td>
                    <img
                      src={`https://srivinayaka.info/Trustee/${item?.img}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "100px",
                      }}
                      alt=""
                    />
                  </td>
                  <td>{item?.name}</td>
                  <td> {parse(`<div>${item?.content}</div>`)} </td>
                  <td>
                    <div className="d-flex">
                      <BiEdit
                        style={{
                          color: "blue",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          setTrusteeDetails(item);
                          handleShow7();
                        }}
                      />
                      /
                      <AiOutlineDelete
                        style={{ color: "red", marginLeft: "10px" }}
                        onClick={() => {
                          setTrusteeDetails(item);
                          handleShow8();
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {/* add founder modal - starts */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Add Founder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Image:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                required
                onChange={(e) => setfounderimg(e.target.files[0])}
              />
            </Form.Group>
          </Form>
          <label>Body: </label>
          <CKEditor
            editor={ClassicEditor}
            data={Body}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => postFounder(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add founder modal - ends */}

      {/* edit founder modal - starts */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Founder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Image:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                required
                onChange={(e) => setfounderimg(e.target.files[0])}
              />
            </Form.Group>
          </Form>
          <label>Body: </label>
          <CKEditor
            editor={ClassicEditor}
            data={FounderDetails?.content}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => editFounder(e)}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit founder modal - ends */}

      {/* delete founder modal - starts */}
      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Founder details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the founder details?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => deleteFounder(e)}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete founder modal - ends */}

      {/* add trustee modal - starts */}
      <Modal show={show6} onHide={handleClose6}>
        <Modal.Header closeButton>
          <Modal.Title>Add Trustee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Image:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                required
                onChange={(e) => settrusteeimg(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group controlId="">
              <Form.Label>Trustee Name:</Form.Label>
              <Form.Control
                type="text"
                value={trusteename}
                required
                onChange={(e) => settrusteename(e.target.value)}
              />
            </Form.Group>
          </Form>
          <label>Body: </label>
          <CKEditor
            editor={ClassicEditor}
            data={Body}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose6}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => postTrustee(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add Trustee modal - ends */}

      {/* edit Trustee modal - starts */}
      <Modal show={show7} onHide={handleClose7}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Trustee details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Image:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                required
                onChange={(e) => settrusteeimg(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group controlId="">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder={TrusteeDetails?.name}
                value={trusteename}
                required
                onChange={(e) => settrusteename(e.target.value)}
              />
            </Form.Group>
          </Form>
          <label>Body: </label>
          <CKEditor
            editor={ClassicEditor}
            data={TrusteeDetails?.content}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose7}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => editTrustee(e)}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit Trustee modal - ends */}

      {/* delete Trustee modal - starts */}
      <Modal show={show8} onHide={handleClose8}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Trustee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete all the Trustee details?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose8}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => deleteTrustee(e)}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete Trustee modal - ends */}
    </div>
  );
}

export default Founderandtrustee;
