import React, { useEffect, useState } from "react";
import "./aboutpage.css";
import Navbar from "../Components/Navbar/Navbar";
import Header from "../Components/Header/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeadBanner from "./HeadBanner";
import Footer from "../Components/Footer/Footer";
import { Diversity1TwoTone } from "@mui/icons-material";
import { ThemeProvider } from "react-bootstrap";

import ReactElasticCarousel from "react-elastic-carousel";
import Navigation from "../Navigation";
import axios from "axios";
import parse from "html-react-parser";
const Aboutpage = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 4, itemsToScroll: 2 },
    { width: 760, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];

  const [AboutusBannerList, setAboutusBannerList] = useState([]);
  const getAboutusBanner = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getAboutusBanner")
        .then((res) => {
          if (res.status === 200) {
            setAboutusBannerList(res.data.aboutusBannerList);
          }
        });
    } catch (error) {
      console.log(error);
      setAboutusBannerList([]);
    }
  };

  const [Aboutuslist, setAboutusList] = useState([]);
  const AboutusList = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getAboutusList")
        .then((res) => {
          if (res.status === 200) {
            // console.log(res);
            setAboutusList(res.data.AboutusList);
          }
        });
    } catch (error) {
      console.log(error);
      setAboutusList([]);
    }
  };

  const [FounderList, setFounderList] = useState([]);
  const getFounderList = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getFounderList")
        .then((res) => {
          if (res.status === 200) {
            setFounderList(res.data.founderList);
          }
        });
    } catch (error) {
      console.log(error);
      setFounderList([]);
    }
  };

  const [TrusteeList, setTrusteeList] = useState([]);
  const getTrusteeList = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getTrusteeList")
        .then((res) => {
          if (res.status === 200) {
            setTrusteeList(res.data.trusteeList);
          }
        });
    } catch (error) {
      console.log(error);
      setTrusteeList([]);
    }
  };

  const [VolList, setVolList] = useState([]);
  const getVolList = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/volunteer/getAllVolunteer")
        .then((res) => {
          if (res.status === 200) {
            setVolList(res.data.allVolunteer);
          }
        });
    } catch (error) {
      console.log(error);
      setVolList([]);
    }
  };

  useEffect(() => {
    getAboutusBanner();
    AboutusList();
    getFounderList();
    getTrusteeList();
    getVolList();
  }, []);
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      <div>
        <Header />
        <Navigation />

        <Container fluid style={{ padding: "0px" }}>
          <img
            src={`https://srivinayaka.info/Aboutus/${AboutusBannerList[0]?.bannerimg}`}
            style={{
              width: "100%",
              height: "250px",
            }}
          ></img>
        </Container>

        <Container fluid style={{}}>
          <div
            className
            style={{
              fontSize: "28px",
              color: "#DA4453",
              fontWeight: "500",
              textAlign: "center",
              paddingTop: "30px",
              paddingBottom: "-30px",
            }}
          >
            About Us
          </div>

          <br></br>

          <Row>
            <Col
              className=" founder-image1"
              md={{ span: 5 }}
              style={{ margin: "20px 30px 20px 70px", padding: "0px" }}
            >
              {" "}
              <img
                src={`https://srivinayaka.info/Aboutus/${Aboutuslist[0]?.img}`}
                alt=""
                style={{ width: "100%", height: "auto", borderRadius: "0px" }}
              />
            </Col>
            <Col
              md={{ span: 5 }}
              style={{ padding: "0px", margin: "20px 20px 70px 30px" }}
            >
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "32px",
                  color: "#6B6b6b",
                }}
              >
                {parse(`<div>${Aboutuslist[0]?.content}</div>`)}
              </p>{" "}
            </Col>
          </Row>
        </Container>

        <Container fluid style={{}}>
          <div
            className
            style={{
              fontSize: "28px",
              color: "#DA4453",
              fontWeight: "500",
              textAlign: "center",
              paddingTop: "30px",
              paddingBottom: "-30px",
            }}
          >
            Founder
          </div>
          <br></br>

          <Row>
            <Col
              className="founder-text"
              md={{ span: 5 }}
              style={{ padding: "0px", margin: "20px 20px 30px 70px" }}
            >
              <p
                style={{
                  textAlign: "justify",
                  lineHeight: "32px",
                  color: "#6B6b6b",
                }}
              >
                {parse(`<div>${FounderList[0]?.content}</div>`)}
              </p>{" "}
            </Col>

            <Col
              className="founder-image"
              md={{ span: 5 }}
              style={{ margin: "20px 30px 20px 50px", padding: "0px" }}
            >
              {" "}
              <img
                src={`https://srivinayaka.info/Founder/${FounderList[0]?.img}`}
                alt=""
                style={{ width: "100%", height: "auto", borderRadius: "0px" }}
              />
            </Col>
          </Row>
        </Container>

        <Container fluid style={{}}>
          <div
            className
            style={{
              fontSize: "28px",
              color: "#DA4453",
              fontWeight: "500",
              textAlign: "center",
              paddingTop: "30px",
              paddingBottom: "-30px",
            }}
          >
            Trustee
          </div>

          <Row style={{ marginTop: "-5px" }}>
            <ReactElasticCarousel breakPoints={breakPoints}>
              {TrusteeList?.map((item) => {
                return (
                  <div
                    md={{ span: 3 }}
                    style={{ padding: "0px", margin: "auto" }}
                  >
                    {" "}
                    <div class="card c" style={{ width: "18rem" }}>
                      <img
                        src={`https://srivinayaka.info/Trustee/${item?.img}`}
                        style={{
                          height: "150px",
                          width: "270px",
                          marginTop: "-10px",
                        }}
                        class="cit image-card"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title"> Name : {item?.name}</h5>
                        <p>{parse(`<div>${item?.content}</div>`)}</p>
                      </div>
                    </div>{" "}
                  </div>
                );
              })}
            </ReactElasticCarousel>
          </Row>
        </Container>

        {/* ==================================       Volunteers       ===================================== */}
        <Container fluid style={{}}>
          <div
            className
            style={{
              fontSize: "28px",
              color: "#DA4453",
              fontWeight: "500",
              textAlign: "center",
              paddingTop: "30px",
              paddingBottom: "-30px",
            }}
          >
            Volunteers
          </div>

          <Row>
            <ReactElasticCarousel breakPoints={breakPoints}>
              {VolList?.filter((item1) => item1?.approveStatus === true)?.map(
                (item) => {
                  return (
                    <div
                      md={{ span: 3 }}
                      style={{ padding: "0px", margin: "auto" }}
                    >
                      {" "}
                      <div class="card c" style={{ width: "18rem" }}>
                        <img
                          src={`https://srivinayaka.info/Volunteer/${item?.vPic}`}
                          style={{
                            borderRadius: "%",
                            height: "150px",
                            width: "270px",
                            marginTop: "-10px",
                          }}
                          class="cit image-card"
                          alt="..."
                        />
                        <div class="card-body">
                          <h5 class="card-title">
                            {" "}
                            Name : {`${item?.vfName} ${item?.vlName}`}{" "}
                          </h5>

                          <h5 class="card-text">Location : {item?.city}</h5>
                          {/* <h5 class="card-text">Donation Rs : 10,0000</h5> */}
                        </div>
                      </div>{" "}
                    </div>
                  );
                }
              )}
            </ReactElasticCarousel>
          </Row>
        </Container>

        {/* =================================        Donors ================================ */}
        <Container fluid style={{}}>
          <div
            className
            style={{
              fontSize: "28px",
              color: "#DA4453",
              fontWeight: "500",
              textAlign: "center",
              paddingTop: "30px",
              paddingBottom: "-30px",
            }}
          >
            Our Humble Donor's
          </div>

          <Row>
            <ReactElasticCarousel breakPoints={breakPoints}>
              <div md={{ span: 3 }} style={{ padding: "0px", margin: "auto" }}>
                {" "}
                <div class="card c" style={{ width: "18rem" }}>
                  <img
                    src="./trustee2.jpg"
                    style={{
                      borderRadius: "%",
                      height: "150px",
                      width: "270px",
                      marginTop: "-10px",
                    }}
                    class="cit image-card"
                    alt="..."
                  />
                  <div class="card-body">
                    <h5 class="card-title"> Name : prem Kumar</h5>

                    <h5 class="card-text">Location : Banglore</h5>
                    <h5 class="card-text">Donation Rs : 10,0000</h5>
                  </div>
                </div>{" "}
              </div>

              <div md={{ span: 3 }} style={{ margin: "auto", padding: "0px" }}>
                {" "}
                <div class="card c" style={{ width: "18rem" }}>
                  <img
                    src="./trustee1.jpg"
                    style={{
                      borderRadius: "%",
                      height: "150px",
                      width: "270px",
                      marginTop: "-10px",
                    }}
                    class="cit image-card"
                    alt="..."
                  />
                  <div class="card-body">
                    <h5 class="card-title"> Name : Narun Kumar</h5>
                    <h5 class="card-text">Location : Banglore</h5>
                    <h5 class="card-text">Donation Rs : 10,0000</h5>
                  </div>
                </div>
              </div>

              <div md={{ span: 3 }} style={{ margin: "auto", padding: "0px" }}>
                {" "}
                <div class="card c" style={{ width: "18rem" }}>
                  <img
                    src="./trustee.jpg"
                    style={{
                      borderRadius: "%",
                      height: "150px",
                      width: "270px",
                      marginTop: "-10px",
                    }}
                    class="cit image-card"
                    alt="..."
                  />
                  <div class="card-body">
                    <h5 class="card-title"> Name : Nitheesh Kumar</h5>
                    <h5 class="card-text">Location : Banglore</h5>
                    <h5 class="card-text">Donation Rs : 10,0000</h5>
                  </div>
                </div>
              </div>

              <div md={{ span: 3 }} style={{ margin: "auto", padding: "0px" }}>
                <div class="card c" style={{ width: "18rem" }}>
                  <img
                    src="./trustee3.jpg"
                    style={{
                      borderRadius: "%",
                      height: "150px",
                      width: "270px",
                      marginTop: "-10px",
                    }}
                    class="cit image-card"
                    alt="..."
                  />
                  <div class="card-body">
                    <h5 class="card-title"> Name : Arjun Kumar</h5>
                    <h5 class="card-text">Location : Banglore</h5>
                    <h5 class="card-text">Donation Rs : 10,0000</h5>
                  </div>
                </div>
              </div>

              <div md={{ span: 3 }} style={{ padding: "0px", margin: "auto" }}>
                {" "}
                <div class="card c" style={{ width: "18rem" }}>
                  <img
                    src="./trustee2.jpg"
                    style={{
                      borderRadius: "%",
                      height: "150px",
                      width: "270px",
                      marginTop: "-10px",
                    }}
                    class="cit image-card"
                    alt="..."
                  />
                  <div class="card-body">
                    <h5 class="card-title"> Name : prem Kumar</h5>

                    <h5 class="card-text">Location : Banglore</h5>
                    <h5 class="card-text">Donation Rs : 10,0000</h5>
                  </div>
                </div>{" "}
              </div>
            </ReactElasticCarousel>
          </Row>
        </Container>

        {/* ==========================     Footer ======================== */}

        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default Aboutpage;
