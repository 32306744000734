import React from "react";
import "./headbanner.css";
const HeadBanner = () => {
  return (
    <div>
      <div className="headbanner">
        <p className="text-hs">Contact Us</p>
      </div> 
    </div>
  );
};

export default HeadBanner;
