import { Component } from "react";
// import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  contribute = { clicked: false };
  handleContribute = () => {
    this.setState({
      clicked: !this.contribute.clicked,
    });
  };

 

  render() {
    return (
      <>
        <nav classname=' border-bottom'>
          <div id="mobile" onClick={this.handleClick}>
            <i
              id="bar"
              className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
            ></i>
          </div>
          <Link to="/" className="logo-c">
           

            <div className=" row logo justify-content-space-evenly  align-items-center">
              <div className=" col-lg-2 col-sm-2  col-2 p-1">
                {" "}
                <img
                  src="./vinayakalogo.png"
                  className="logo-v"
                  style={{
                    maxWidth: "60px",
                    minWidth: "30px",

                    viewBox: "0 0 100 51",
                  }}
                ></img>
              </div>
              <div className=" col-lg-6 col-sm-8  col-8 ">
                {" "}
                <img src="./logot.jpeg" className="img-text-logo"></img>
              </div>
            </div>


          </Link>

{/* <NavLink to="/" class="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto link-body-emphasis text-decoration-none">
<img
                  src="./vinayakalogo.png"
                  className="logo-v"
                  style={{
                    maxWidth: "60px",
                    minWidth: "30px",

                    viewBox: "0 0 100 51",
                  }}
                ></img> <span class="  logo-text ml-">Sri Vinayaka Charitable Trust,</span>
      </NavLink> */}



          <div>
            <ul
              id="navbar"
              className={this.state.clicked ? "#navbar active" : "#navbar"}
            >
              <li>
                {/* <Link 
                 className=""
                 to="/">
                  Home
                </Link>  */}
                <NavLink  className='link-body-emphasis'
                  to="/"
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/aboutpage"
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                  to="/services"
                >
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                  to="/gallery"
                >
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                  to="/blog"
                >
                  Blog
                </NavLink>
              </li>
              {/* <li>
                <Link to="/volunteerform">Fundraiser</Link>
              </li> */}
              <li>
                <NavLink
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                  to="/volunteerform"
                >
                  Become a Volunteer
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={(isActive) => ({
                    color: isActive ? "" : "",
                  })}
                  to="/contactus"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>

          <Link
            
            className="nav-btn"
            style={{ textDecoration: "none", color: "#DA4453" }}
          >
            {/* <button
              type="button"
              class="btn btn-outline-danger btn-32"
             
             
            >
              DONATE NOW
            </button> */}

            <button
             data-toggle="modal" data-target="#exampleModal"
             class="button-75" role="button"><span class="text">Donate</span></button>

          </Link>
        </nav>

        <div
          class="modal fade "
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog " role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Donate
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div
                  style={{
                    padding: "20px auto",
                  }}
                >
                       <Form.Floating
            className="mb-3 "
            style={{
              textAlign: "center",
            }}
          >
            <div className="row w-100 align-items-center justify-content-evenly mb-3 ">
              <div className="col-2">
                <Button
                  variant="outline-success"
                >
                  {" "}
                  ₹100
                </Button>
              </div>
              <div className="col-2">
                <Button
                  variant="outline-success"
                >
                  ₹150
                </Button>
              </div>
              <div className="col-2">
                <Button
                  variant="outline-success"
                
                >
                  {" "}
                  ₹200
                </Button>
              </div>
              <div className="col-2">
                <Button
                  variant="outline-success"
                
                >
                  {" "}
                  ₹250
                </Button>
              </div>
            </div>

            <div className="row w-100  align-items-center justify-content-evenly">
              <div className="col-2">
                <Button
                  variant="outline-success"
                
                >
                  {" "}
                  ₹300
                </Button>
              </div>
              <div className="col-2">
                <Button
                  variant="outline-success"
                
                >
                  ₹400
                </Button>
              </div>
              <div className="col-2">
                <Button
                  variant="outline-success"
               
                >
                  {" "}
                  ₹450
                </Button>
              </div>
              <div className="col-2">
                <Button
                  variant="outline-success"
                 
                >
                  {" "}
                  ₹500
                </Button>
              </div>
            </div>

            <br></br>

            <div style={{ padding: "5px 10px 5px 10px " }}>
              <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                  Amount
                </InputGroup.Text>
                <Form.Control
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                />
              </InputGroup>
            </div>

            <Row>
              <Col>
                <Form.Label style={{ marginLeft: "10px" }}>
                  Donation Frequency
                </Form.Label>

                <div
                  style={{ padding: "0px 10px 5px 10px ", marginTop: "-2px" }}
                >
                  <Form.Select
                    aria-label="Default select example"
                    style={{ height: "37px", padding: "4px" }}
                  >
                    <option value="1">One-time</option>
                    <option value="2">Monthly</option>
                    <option value="3">Quarterly </option>
                    <option value="4">Annually</option>
                  </Form.Select>
                </div>
              </Col>

              <Col>
                <Form.Label style={{ marginLeft: "0px" }}>End Date</Form.Label>
                <Form.Control
                  style={{ marginLeft: "0px", width: "" }}
                  type="date"
                />
              </Col>
            </Row>

            <div style={{ padding: "5px 10px 5px 10px " }}>
              <FloatingLabel controlId="floatingTextarea2" label="Message">
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                />
              </FloatingLabel>
            </div>

            <div style={{ padding: "5px 10px 5px 10px " }}>
              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="">
                  <Form.Check
                    inline
                    style={{
                      marginTop: "10px",
                    }}
                    label="Post my donation amount"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="Post my name"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </div>
          </Form.Floating>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
               <Link to='/payment'>
               <button type="button" class="btn btn-outline-success">
                  Donate
                </button>
               </Link>
                
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Navbar;
