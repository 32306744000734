import React, { useState } from "react";
import "../Admin/admin.css";
import { Button } from "react-bootstrap";
import axios from "axios";

function AdminLogin() {
  {
    /* =============================================================================================== */
  }

  const [Email, setEmail] = useState("");
  const [Pwd, setPwd] = useState("");

  const adminLogin = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/adminLogin",
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: {
          email: Email,
          pwd: Pwd,
        },
      };

      await axios(config).then((res) => {
        if (res.status === 200) {
          // alert(res.data.success);
          // res.cookie("token", res.data.token, { httpOnly: true });
          localStorage.setItem("adminToken", res.data.token);
          localStorage.setItem("encodeInfo", JSON.stringify(res.data.encode));
          window.location.assign("/dashboard");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  {
    /* =============================================================================================== */
  }

  return (
    <>
      <div className="add">
        <div className="container">
          <div className="fw_90">
            <div className="add_0">
              <div className="im09">
                <img src="./implogo.jpeg" alt="login" className="di_90" />
              </div>
              <div className="add-90">
                <form>
                  <div className="sd_00 mb-2">
                    <label>Email</label> <br />
                    <input
                      type="text"
                      placeholder="email@gmail.com"
                      className="name_0"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="sd_00 mb-2">
                    <label>Password</label>
                    <br />
                    <input
                      type="password"
                      placeholder="password"
                      className="name_0"
                      onChange={(e) => setPwd(e.target.value)}
                    />
                  </div>
                  <div className="sd_00 mt-2">
                    <Button
                      style={{ color: "white" }}
                      onClick={(e) => adminLogin(e)}
                    >
                      Login
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
