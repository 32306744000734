import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
function RequestFund() {
  /* ======================================================================================================== */
  //   approval modal
  const formdata = new FormData();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [cause, setcause] = useState("");
  const [fullName, setfullName] = useState("");
  const [email, setemail] = useState("");
  const [phno, setphno] = useState();
  const [img1, setimg1] = useState("");
  const [img2, setimg2] = useState("");
  const [img3, setimg3] = useState("");
  const [bannerimg, setbannerimg] = useState("");
  const [content, setcontent] = useState("");
  const [goalAmt, setgoalAmt] = useState();
  // const [raisedAmt, setraisedAmt] = useState();
  const [req, setreq] = useState({});

  /* ======================================================================================================== */

  //   edit cause modal
  //   const [show1, setShow1] = useState(false);
  //   const handleClose1 = () => setShow1(false);
  //   const handleShow1 = () => setShow1(true);

  /* ======================================================================================================== */

  //   delete cause modal
  //   const [show2, setShow2] = useState(false);
  //   const handleClose2 = () => setShow2(false);
  //   const handleShow2 = () => setShow2(true);

  /* ======================================================================================================== */

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setcontent(data);
  };
  // const obj = {
  //   fullName: fullName,
  //   email: email,
  //   phno: phno,
  //   bannerimg: bannerimg,
  //   img1: img1,
  //   img2: img2,
  //   img3: img3,
  //   content: content,
  //   goalAmt: goalAmt,
  // };
  const approveFund = async (e, id) => {
    e.preventDefault();

    formdata.set("fullName", fullName);
    formdata.set("email", email);
    formdata.set("phno", phno);
    formdata.set("cause", cause);
    formdata.set("bannerimg", bannerimg);
    formdata.set("img1", img1);
    formdata.set("img2", img2);
    formdata.set("img3", img3);
    formdata.set("content", content);
    formdata.set("goalAmt", goalAmt);

    try {
      const config = {
        url: `/admin/approveFundraise/${id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getReqFund();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [Requsers, setRequsers] = useState([]);
  const getReqFund = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/user/getreqFundraise")
        .then((res) => {
          if (res.status === 200) {
            setRequsers(res.data.reqUsers);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const BlockstatusFund = async (e, id, state) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/blockstatusFund/${id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
        data: {
          state: state,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getReqFund();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getReqFund();
  }, []);

  return (
    <div>
      <div
        className="main-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>Fund requested</h4>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Full name</th>
              <th>Email</th>
              <th>Phone no.</th>
              <th>Cause</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Requsers.map((item, i) => {
              return (
                <tr>
                  <td> {++i}. </td>
                  <td> {item?.fullName}</td>
                  <td> {item?.email}</td>
                  <td> {item?.phno}</td>
                  <td> {item?.cause}</td>
                  <td>
                    <Button
                      onClick={(e) => {
                        setreq(item);
                        handleShow();
                      }}
                      style={{ marginRight: "10px" }}
                    >
                      Approved
                    </Button>
                    {item?.isBlock ? (
                      <Button
                        onClick={(e) => {
                          BlockstatusFund(e, item?._id, false);
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        Unblock
                      </Button>
                    ) : (
                      <Button
                        onClick={(e) => {
                          BlockstatusFund(e, item?._id, true);
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        Block
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {/* approval  modal - starts */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Fill the details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder={req?.fullName}
                value={fullName}
                required
                onChange={(e) => setfullName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder={req?.email}
                value={email}
                required
                onChange={(e) => setemail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Phone no.</Form.Label>
              <Form.Control
                type="number"
                placeholder={req?.phno}
                value={phno}
                required
                onChange={(e) => setphno(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Cause</Form.Label>
              <Form.Control
                type="text"
                placeholder={req?.cause}
                value={cause}
                required
                onChange={(e) => setcause(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label htmlFor="upload1">Banner Image</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                accept="image/*"
                required
                onChange={(e) => setbannerimg(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label htmlFor="upload2">Image1</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                accept="image/*"
                required
                onChange={(e) => setimg1(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label htmlFor="upload3">Image2</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                accept="image/*"
                required
                onChange={(e) => setimg2(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label htmlFor="upload4">Image3</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                accept="image/*"
                required
                onChange={(e) => setimg3(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Content</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={content}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Goal Amount</Form.Label>
              <Form.Control
                type="number"
                value={goalAmt}
                required
                onChange={(e) => setgoalAmt(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => approveFund(e, req?._id)}>
            Approved
          </Button>
        </Modal.Footer>
      </Modal>
      {/* approval modal - ends */}

      {/* edit Disease modal - starts */}
      {/* <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Disease name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label> Disease Name: </label>
          <input
            type="text"
            value={cause}
            // placeholder={Causeval?.diseaseName}
            required
            // onChange={(e) => setcause(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            //   onClick={handleClose1}
          >
            Close
          </Button>
          <Button
            variant="primary"
            //   onClick={(e) => editCause(e)}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/* edit Disease modal - ends */}

      {/* delete Disease modal - starts */}
      {/* <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the Disease name?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            //   onClick={(e) => deleteCause(e)}
          >
            delete
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/* delete Disease modal - ends */}
    </div>
  );
}

export default RequestFund;
