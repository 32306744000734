import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";

function ApprovedVol() {
  const admintoken = localStorage.getItem("adminToken");
  const adminDetails = JSON.parse(localStorage.getItem("encodeInfo"));
  {
    /* ========================================================================================================== */
  }

  const blockStatusChng = async (e, id, val) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/volunteer/changeBlockStatus/" + id,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: { adminToken: admintoken, blockStatus: val },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          // setvolID("");
          appVols();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  {
    /* ========================================================================================================== */
  }
  const [AppVolList, setAppVolList] = useState([]);
  const appVols = async () => {
    try {
      let res = await axios.get(
        `https://srivinayaka.info/api/admin/volunteer/approveVolList`
      );
      if (res.status === 200) {
        setAppVolList(res.data.approvedVolList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  {
    /* ========================================================================================================== */
  }
  useEffect(() => {
    appVols();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>ID</th>
                <th>Name</th>
                <th>S/O</th>
                <th>Email Id</th>
                <th>Pnone number</th>
                <th>whatsapp number</th>
                <th>Address</th>
                <th>Age</th>
                <th>Volunteer Interest</th>
                <th>GPS</th>
                <th>Aadhar number</th>
                <th>Pan number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {AppVolList?.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}.</td>
                    <td>{item?._id}</td>
                    <td>
                      <div className="d-flex">
                        <img
                          src={`https://srivinayaka.info/Volunteer/${item?.vPic}`}
                          style={{
                            width: "40px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                        <span>
                          {item?.vfName} {item?.vlName}
                        </span>
                      </div>
                    </td>
                    <td>{item?.so}</td>
                    <td>{item?.email}</td>
                    <td>{item?.phno}</td>
                    <td>{item?.whatsapp}</td>
                    <td>
                      Dno./Street: {item?.street}, {item?.address},{" "}
                      {item?.taluka}, {item?.dist}, {item?.pincode},{" "}
                      {item?.city}, {item?.state} , {item?.country}
                    </td>
                    <td>{item?.age}</td>
                    <td>
                      {item?.volunteeringInterest == "fulltime" ? (
                        <>Full-Time</>
                      ) : (
                        <>Half-Time</>
                      )}
                    </td>
                    <td>
                      <a href={item?.gpsLoc} target="_blank">
                        {item?.gpsLoc}
                      </a>
                    </td>
                    <td>{item?.aadhar}</td>
                    <td>{item?.pan}</td>
                    <td>
                      <div className="d-flex">
                        {/* <AiOutlineEye
                          title="Approve Volunteer"
                          style={{ color: "#b51512", marginLeft: "10px" }}
                          onClick={() => {
                            setvolID(item?._id);
                            handleShow();
                          }}
                        /> */}
                        {item.blockStatus ? (
                          <>
                            <Button
                              style={{
                                backgroundColor: "red",
                                marginLeft: "10px",
                              }}
                              onClick={(e) => {
                                // setvolID(item?._id);
                                blockStatusChng(e, item?._id, "false");
                              }}
                            >
                              Unblock
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              style={{
                                backgroundColor: "green",
                                marginLeft: "10px",
                              }}
                              onClick={(e) => {
                                // setvolID(item?._id);
                                blockStatusChng(e, item?._id, "true");
                              }}
                            >
                              Block
                            </Button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default ApprovedVol;
