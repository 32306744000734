import React, { useEffect, useState } from "react";
import "./slider.css";
import Carousel from "react-bootstrap/Carousel";
import { Button } from "react-bootstrap";
import axios from "axios";

const Slider = () => {
  /* ======================================================================================================== */
  const [Banners, setBanners] = useState([]);
  const getImages = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getbanner")
        .then((res) => {
          if (res.status === 200) {
            setBanners(res.data.bannerList);
          }
        });
    } catch (error) {
      console.log(error);
      setBanners([]);
    }
  };

  /* ======================================================================================================== */
  useEffect(() => {
    getImages();
  }, []);
  return (
    <>
      <Carousel variant="dark" fade className="slider">
        {Banners.map((item1) => {
          return (
            <Carousel.Item>
              <img
                className="d-block w-100 img-home"
                style={{
                  height: "550px",
                }}
                src={`https://srivinayaka.info/Banner/${item1?.bannerimg}`}
                alt="First slide"
              />
              <Carousel.Caption>
                <ul>
                  <li
                    style={{
                      marginLeft: "0px",
                      fontSize: "63px",
                      fontWeight: "bold",
                      padding: "0px",
                      color: "white",
                    }}
                  >
                    How Love
                  </li>
                  <li
                    style={{
                      fontSize: "63px",
                      fontWeight: "bold",
                      color: "#FAB130",
                      marginTop: "-30px",
                      padding: "0px",
                    }}
                  >
                    Transforms
                  </li>
                  <li
                    style={{
                      fontSize: "63px",
                      fontWeight: "bold",
                      marginTop: "-30px",
                      marginRight: "150px",
                      color: "white",
                    }}
                  >
                    Lives
                  </li>
                  <li>
                    {" "}
                    <Button size="lg" variant="outline-light">
                      Donate
                    </Button>
                  </li>
                </ul>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
        {/* ) : 
        (
          <Carousel.Item>
            <img
              style={{
                height: "550px",
              }}
              className="d-block w-100 img-home"
              src="slide1.webp"
              alt="Second slide"
            />

            <Carousel.Caption>
              {" "}
              <ul>
                <li
                  style={{
                    marginLeft: "0px",
                    fontSize: "63px",
                    fontWeight: "bold",
                    padding: "0px",
                    color: "white",
                  }}
                >
                  How Love
                </li>
                <li
                  style={{
                    fontSize: "63px",
                    fontWeight: "bold",
                    color: "#FAB130",
                    marginTop: "-30px",
                    padding: "0px",
                  }}
                >
                  Transforms
                </li>
                <li
                  style={{
                    fontSize: "63px",
                    fontWeight: "bold",
                    marginTop: "-30px",
                    marginRight: "150px",
                    color: "white",
                  }}
                >
                  Lives
                </li>
                <li>
                  {" "}
                  <Button size="lg" variant="outline-light">
                    Donate
                  </Button>
                </li>
              </ul>
            </Carousel.Caption>
          </Carousel.Item>
        )} */}
        {/* <Carousel.Item>
          <img
         
            style={{
              height: "550px",
            }}
            className="d-block w-100 img-home"
            src="slide1.webp"
            alt="Second slide"
          />

          <Carousel.Caption>
            {" "}
            <ul>
              <li
                style={{
                  marginLeft: "0px",
                  fontSize: "63px",
                  fontWeight: "bold",
                  padding: "0px",
                  color: "white",
                }}
              >
                How Love
              </li>
              <li
                style={{
                  fontSize: "63px",
                  fontWeight: "bold",
                  color: "#FAB130",
                  marginTop: "-30px",
                  padding: "0px",
                }}
              >
                Transforms
              </li>
              <li
                style={{
                  fontSize: "63px",
                  fontWeight: "bold",
                  marginTop: "-30px",
                  marginRight: "150px",
                  color: "white",
                }}
              >
                Lives
              </li>
              <li>
                {" "}
               
                <Button size="lg" variant="outline-light">Donate</Button>
              </li>
            </ul>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{
              height: "550px",
            }}
            className="d-block w-100 img-home"
            src="slide2.webp"
            alt="Third slide"
          />

          <Carousel.Caption>
            <ul>
              <li
                style={{
                  marginLeft: "0px",
                  fontSize: "63px",
                  fontWeight: "bold",
                  padding: "0px",
                  color: "white",
                }}
              >
                How Love
              </li>
              <li
                style={{
                  fontSize: "63px",
                  fontWeight: "bold",
                  color: "#FAB130",
                  marginTop: "-30px",
                  padding: "0px",
                }}
              >
                Transforms
              </li>
              <li
                style={{
                  fontSize: "63px",
                  fontWeight: "bold",
                  marginTop: "-30px",
                  marginRight: "150px",
                  color: "white",
                }}
              >
                Lives
              </li>
              <li>
                <Button size="lg" variant="outline-light">Donate</Button>
                
               
              </li>
            </ul>
          </Carousel.Caption>
        </Carousel.Item> */}
      </Carousel>

      <div className="slider-text"></div>

      <div
        style={{
          marginTop: "-275px",
          background: "#E3EDF9",
          padding: "10px",
          textAlign: "center",
          color: "black",
          fontSize: "15px",
          fontWeight: "400",
        }}
      >
        "SERVICE TO MAN KIND SERVICE TO GOD"{" "}
      </div>
    </>
  );
};

export default Slider;
