import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeadBanner from "./HeadBanner";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Navbar from "../Components/Navbar/Navbar";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

import Stack from "react-bootstrap/Stack";
import { Container } from "react-bootstrap";
import "./Servicespage.css";
import axios from "axios";
import Navigation from "../Navigation";
import useRazorpay from "react-razorpay";
import parse from "html-react-parser";

function ServicesIdPage() {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Razorpay = useRazorpay();

  /* ============================================================================================================== */

  const [fullName, setfullName] = useState("");
  const [phno, setphno] = useState("");
  const [email, setemail] = useState("");
  const [donatetype, setDonatetype] = useState("");
  const [amt, setamt] = useState(0);
  const [remark, setremark] = useState("");
  const [payid, setpayid] = useState("");
  const [expDate, setexpDate] = useState("");
  const [anonymous, setanonymous] = useState(false);
  const [data, setdata] = useState({});
  const [individualOrORG, setindividualOrORG] = useState("");

  /* ============================================================================================================== */

  const donarValidation = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: `/user/userDonationVal`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/x-www-form-urlencoded" },
        data: {
          fullName: fullName,
          phno: phno,
          email: email,
          donateType: donatetype,
          amt: Number(amt),
          remark: remark,
          expDate: expDate,
          individualOrORG: individualOrORG,
          anonymous: anonymous,
          // volToken: volToken,
        },
        json: true,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          setdata(res.data.data);
          posttransaction();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ============================================================================================================== */

  const addDonar = async () => {
    data.payid = payid;
    try {
      const config = {
        url: `/user/userDonation`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/x-www-form-urlencoded" },
        data: data,
        json: true,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          // donarList();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [BannerList, setBannerList] = useState([]);
  const getBannerList = async () => {
    try {
      const res = await axios.get(
        `https://srivinayaka.info/api/admin/getServiceBanner`
      );
      if (res.status === 200) {
        setBannerList(res.data.serviceBannerList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [ServiceDetails, setServiceDetails] = useState({});
  const getServiceVal = async (val) => {
    try {
      const res = await axios.get(
        `https://srivinayaka.info/api/admin/getServiceById/${val}`
      );
      if (res.status === 200) {
        setServiceDetails(res.data.service);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* ============================================================================================================== */

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const posttransaction = async () => {
    if (!fullName || !phno || !email || !donatetype || !amt) {
      return alert("please fill all the neccessary field");
    }
    try {
      const config = {
        data: {
          key: "rzp_test_5vCRZ6rLM2wGN4",
          amount: Number(amt) * 100,
          currency: "INR",
          name: "Vinayaka Trust",
          description: "Order Amount",
          image: "/Logo-1.jpg",
          email: email,
          handler: function (response) {
            alert(response.razorpay_payment_id);
            setpayid(response.razorpay_payment_id);
          },
          prefill: {
            email: email,
            phno: phno,
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };

  /* ============================================================================================================== */
  useEffect(() => {
    if (payid) {
      addDonar();
    }
  }, [payid]);

  useEffect(() => {
    if (id) {
      getServiceVal(id);
      getBannerList();
    }
  }, []);

  return (
    <div>
      <Header></Header>
      <Navigation />
      <Container fluid style={{ padding: "0px" }}>
        <img
          src={`https://srivinayaka.info/Services/${BannerList[0]?.bannerimg}`}
          style={{
            width: "100%",
            height: "250px",
          }}
        ></img>
      </Container>
      <div
        className
        style={{
          fontSize: "28px",
          color: "#DA4453",
          fontWeight: "500",
          textAlign: "center",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        {ServiceDetails?.topic}
      </div>

      <Stack gap={2} className="col-md-10 mx-auto">
        <img
          src={`https://srivinayaka.info/Services/${ServiceDetails?.img}`}
          alt=""
        ></img>
      </Stack>

      <Container>
        <div
          style={{
            textAlign: "justify",
            lineHeight: "32px",
            color: "#6B6b6b",
          }}
        >
          {parse(`<div>${ServiceDetails?.content}</div>`)}
        </div>

        <br />
        <br />

        {/* <div
          style={{
            textAlign: "justify",
            lineHeight: "32px",
            color: "#6B6b6b",
          }}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis sed
          doloremque dignissimos asperiores dolorem ullam? Et adipisci repellat
          veniam doloremque ratione provident corporis, voluptatem laborum
          impedit rerum rem recusandae architecto!
        </div> */}

        <div className="">
          {/* <div>
            Do you like this awesome and completely free WordPress theme?
          </div> */}
          <div>
            {/* <Button variant="outline-danger" onClick={handleShow}>
              DONATE NOW
            </Button> */}

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Donate</Modal.Title>
              </Modal.Header>{" "}
              <Modal.Body>
                <div
                  style={{
                    padding: "20px auto",
                  }}
                >
                  <Form.Floating className="mb-3">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",

                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(25)}
                        >
                          {" "}
                          ₹25
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",
                            marginLeft: "30px",
                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(50)}
                        >
                          ₹50
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",
                            marginLeft: "30px",
                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(70)}
                        >
                          {" "}
                          ₹70
                        </Button>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        marginLeft: "-30px",
                      }}
                    >
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",
                            marginLeft: "30px",
                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(100)}
                        >
                          {" "}
                          ₹100
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",
                            marginLeft: "30px",
                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(200)}
                        >
                          ₹200
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="outline-success"
                          style={{
                            fontSize: "24px",
                            marginLeft: "30px",
                            marginTop: "20px",
                            borderColor: "",
                            fontWeight: "bold",

                            width: "120px",
                            height: "50px",
                          }}
                          onClick={(e) => setamt(500)}
                        >
                          {" "}
                          ₹500
                        </Button>
                      </div>
                    </div>
                    <br></br>

                    <div style={{ padding: "5px 10px 5px 10px " }}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-default">
                          Amount
                        </InputGroup.Text>
                        <Form.Control
                          aria-label="Default"
                          aria-describedby="inputGroup-sizing-default"
                          value={amt}
                          onChange={(e) => setamt(e.target.value)}
                          required
                        />
                      </InputGroup>
                    </div>

                    <Row>
                      <Col>
                        <Form.Label style={{ marginLeft: "10px" }}>
                          Donation Freequency
                        </Form.Label>

                        <div
                          style={{
                            padding: "0px 10px 5px 10px ",
                            marginTop: "-2px",
                          }}
                        >
                          <Form.Select
                            aria-label="Default select example"
                            style={{ height: "37px", padding: "4px" }}
                            onChange={(e) => setDonatetype(e.target.value)}
                          >
                            <option value="">Select one</option>
                            <option value="onetime">One-time</option>
                            <option value="monthly">Monthly</option>
                            <option value="quaterly">Quaterly</option>
                            <option value="yearly">Annually</option>
                          </Form.Select>
                        </div>
                      </Col>
                      {donatetype == "monthly" ||
                      donatetype == "quaterly" ||
                      donatetype == "yearly" ? (
                        <Col>
                          <Form.Label style={{ marginLeft: "0px" }}>
                            Expiry Date
                          </Form.Label>
                          <Form.Control
                            style={{ marginLeft: "0px", width: "" }}
                            type="date"
                            onChange={(e) => setexpDate(e.target.value)}
                          />
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>

                    <p className="fs-5 text-center mt-4">
                      Are you representing an Individual or Organization?*
                    </p>

                    <div className="row mt-3">
                      <div class=" col form-check  text-center">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          // checked
                          onChange={(e) => setindividualOrORG("individual")}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Individual
                        </label>
                      </div>
                      <div class=" col form-check  text-center">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          // checked
                          onChange={(e) => setindividualOrORG("organization")}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Organization
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className="row mt-4">
                        <div class="mb-3 col">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Name / Organization Name
                          </label>
                          <input
                            type="text"
                            class="form-c border"
                            id="exampleFormControlInput1"
                            placeholder="Name"
                            onChange={(e) => setfullName(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div class="mb-3 col">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Email / Organization Mail
                          </label>
                          <input
                            type="email"
                            class="form-c border"
                            id="exampleFormControlInput1"
                            placeholder="name@example.com"
                            onChange={(e) => setemail(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div class="mb-3 col">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Phone no. / Organization Phone no.
                          </label>
                          <input
                            type="number"
                            class="form-c border"
                            id="exampleFormControlInput1"
                            placeholder="9876543210"
                            onChange={(e) => setphno(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "5px 10px 5px 10px ",
                        fontSize: "16px",
                        color: "#111",
                      }}
                    >
                      Message
                    </div>

                    <div style={{ padding: "5px 10px 5px 10px " }}>
                      <FloatingLabel
                        controlId="floatingTextarea2"
                        label="Message"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a comment here"
                          style={{ height: "100px" }}
                          onChange={(e) => setremark(e.target.value)}
                        />
                      </FloatingLabel>
                    </div>

                    <div style={{ padding: "5px 10px 5px 10px " }}>
                      {["checkbox"].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Form.Check
                            inline
                            style={{
                              marginTop: "10px",
                            }}
                            label="Anonymous"
                            name="group1"
                            type={type}
                            id={`inline-${type}-1`}
                            onChange={() => setanonymous(!anonymous)}
                          />
                        </div>
                      ))}
                    </div>
                  </Form.Floating>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <div>
                  <Button
                    variant="outline-success"
                    onClick={(e) => donarValidation(e)}
                  >
                    {" "}
                    Donate
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </Container>

      <Container></Container>

      <Footer></Footer>
    </div>
  );
}

export default ServicesIdPage;
