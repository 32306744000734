import React from "react";
import Body from "./Components/Body/Body";
import Gallerymain from "./Components/Gallery/Gallerymain";

import { Route, Routes } from "react-router-dom";

import Servicespage from "./pages/Servicespage";
import ServicesidPage from "./pages/ServicesIdPage";
import Newscomp from "./Comp/NewsH/Newscomp";
import Contactus from "./Components/Contactus/Contactus";
import Login from "./Components/Login/Login";
import Aboutpage from "./pages/Aboutpage";
// import Blog1 from './Components/Blog/Blog1'
import Blogpost from "./Components/Carsouel.js/Blogpost";
import Formv from "./Components/VolunnteerForm/Formv";
import Blog2 from "./Components/Blog/Blog2";
import Signup from "./Signup";
import Signin from "./Components/Login/Signin";
import Newspage from "./pages/Newspage";
import Fundraiser from "./Components/Startafundraiser/Fundraiser";
import Contributenow from "./Components/Startafundraiser/Contributenow";
import Payment from "./Payment";
import Pay from "./Pay";
import Receipt from "./Receipt";
// Admin Painel
import AdminLogin from "./Components/Admin/AdminLogin";
import Admindashboard from "./Components/Admin/Admindashboard";
import Dashboard from "./Components/Admin/Dashboard";
import AdminProducts from "./Components/Admin/AdminExpense";
import AdminHeader from "./Components/Admin/AdminHeader.";
import AdminDonerlist from "./Components/Admin/AdminDonerlist";
import AdminProfile from "./Components/Admin/AdminProfile";
import AdimnBankDetails from "./Components/Admin/AdminBankDetails";
import AdminExpense from "./Components/Admin/AdminExpense";
import IdCard from "./Components/Admin/IdCard";
import ChangePassword from "./Components/Admin/ChangePassword";
import Volunteerdashboard from "./Components/Volunteer/Volunteerdashboard";
import VolunteerDonerlist from "./Components/Volunteer/VolunteerDonerlist";
import VolunteerProfile from "./Components/Volunteer/VolunteerProfile";
import VolunteerBankDetails from "./Components/Volunteer/VolunteerBankDetails";
import VolunteerExpense from "./Components/Volunteer/VolunteerExpense";
import VolunteerIdCard from "./Components/Volunteer/VolunteerIdCard";
import VolunteerChangePassword from "./Components/Volunteer/VolunteerChangePassword";
import VolunteerMain from "./Components/Volunteer/VolunteerMain";
import NotApprovedVol from "./Components/Admin/NotApprovedVol";
import ApprovedVol from "./Components/Admin/ApprovedVol";
import Banner1 from "./Components/Admin/Banner1";
import Addcause1 from "./Components/Admin/AddCause1";
import ReqFund1 from "./Components/Admin/RequestFund";
import ApprFund1 from "./Components/Admin/ApprovedFund";
import Gallery1 from "./Components/Admin/Gallery1";
import Blog1 from "./Components/Admin/Blog1";
import Contactus1 from "./Components/Admin/Contactus1";
import Service1 from "./Components/Admin/Service1";
import Aboutus from "./Components/Admin/Aboutus";
import Founderandtrustee1 from "./Components/Admin/Founderandtrustee";
import Volunteerlogin from "./Volunteerlogin";
import Navigation from "./Navigation";
import Register from './Register'
import Donationhistory from './Donationhistory'
import Profile from './Profile'
import TermsCondition from "./Components/TermsCondition";
import Disclaimer from "./Components/Disclaimer";
import PrivacyPolice from "./Components/PrivacyPolice";
import Paymentconfirm from "./pages/Paymentconfirm";
// import Paymentconfirm from "./pages/Paymentconfirm";



const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Body />}></Route>
        <Route path="/gallery" element={<Gallerymain />}></Route>
        <Route path="/volunteerform" element={<Formv />}></Route>
        <Route path="/volunteerlogin" element={<Volunteerlogin />}></Route>
        <Route path="/navigation" element={<Navigation />}></Route>

        <Route path="/receipt" element={<Receipt />}></Route>
        <Route path="/donationhistory" element={<Donationhistory />}></Route>

        <Route path="/services" element={<Servicespage />}></Route>
        <Route path="/services/:id" element={<ServicesidPage />}></Route>
        <Route path="/newspage" element={<Newspage />}></Route>
        <Route path="/contactus" element={<Contactus />}></Route>
        <Route path="/login" element={<Signin />}></Route>
        <Route path="/aboutpage" element={<Aboutpage />}></Route>
        <Route path="/blog" element={<Blog2 />}></Route>
        <Route path="/post" element={<Blogpost />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/fundraiser" element={<Fundraiser />}></Route>
        <Route path="/contribute" element={<Contributenow />}></Route>
        <Route path="/payment" element={<Payment />}></Route>
        <Route path="/paymentconfirm" element={<Paymentconfirm/>}></Route>
        <Route path="/pay" element={<Pay />}></Route>
        <Route path="/reigster" element={<Register />}></Route>
        <Route path="/userprofile" element={<Profile />}></Route>

        {/* AdminPainel */}
        <Route path="/admin" element={<AdminLogin />}></Route>
        <Route
          path="/dashboard"
          element={
            <Dashboard
              children={
                <>
                  <Admindashboard />
                </>
              }
            />
          }
        />
        <Route
          path="/not-approved"
          element={
            <Dashboard
              children={
                <>
                  <NotApprovedVol />
                </>
              }
            />
          }
        />
        <Route
          path="/approved"
          element={
            <Dashboard
              children={
                <>
                  <ApprovedVol />
                </>
              }
            />
          }
        />
        <Route
          path="/banner"
          element={
            <Dashboard
              children={
                <>
                  <Banner1 />
                </>
              }
            />
          }
        />

        <Route
          path="/a-addCause"
          element={
            <Dashboard
              children={
                <>
                  <Addcause1 />
                </>
              }
            />
          }
        />

        <Route
          path="/a-fundreq"
          element={
            <Dashboard
              children={
                <>
                  <ReqFund1 />
                </>
              }
            />
          }
        />

        <Route
          path="/a-fundapproved"
          element={
            <Dashboard
              children={
                <>
                  <ApprFund1 />
                </>
              }
            />
          }
        />

        <Route
          path="/gallery1"
          element={
            <Dashboard
              children={
                <>
                  <Gallery1 />
                </>
              }
            />
          }
        />

        <Route
          path="/blog1"
          element={
            <Dashboard
              children={
                <>
                  <Blog1 />
                </>
              }
            />
          }
        />
        <Route
          path="/contact-us"
          element={
            <Dashboard
              children={
                <>
                  <Contactus1 />
                </>
              }
            />
          }
        />

        <Route
          path="/a-aboutus"
          element={
            <Dashboard
              children={
                <>
                  <Aboutus />
                </>
              }
            />
          }
        />

        <Route
          path="/a-founderandtrustee"
          element={
            <Dashboard
              children={
                <>
                  <Founderandtrustee1 />
                </>
              }
            />
          }
        />

        <Route
          path="/a-service"
          element={
            <Dashboard
              children={
                <>
                  <Service1 />
                </>
              }
            />
          }
        />
        <Route
          path="/volunteer-dashboard"
          element={
            <VolunteerMain
              children={
                <>
                  <Volunteerdashboard />
                </>
              }
            />
          }
        />
        <Route
          path="/doner-list"
          element={<Dashboard children={<AdminDonerlist />} />}
        />
        <Route
          path="/volunteer-doner-list"
          element={<VolunteerMain children={<VolunteerDonerlist />} />}
        />
        <Route
          path="/profile"
          element={<Dashboard children={<AdminProfile />} />}
        />
        <Route
          path="/volunteer-profile"
          element={<VolunteerMain children={<VolunteerProfile />} />}
        />
        <Route
          path="/bank-details"
          element={<Dashboard children={<AdimnBankDetails />} />}
        />
        <Route
          path="/volunteer-bank-details"
          element={<VolunteerMain children={<VolunteerBankDetails />} />}
        />
        <Route
          path="/volunteer-expense"
          element={<VolunteerMain children={<VolunteerExpense />} />}
        />
        <Route
          path="/expense"
          element={<Dashboard children={<AdminExpense />} />}
        />
        <Route
          path="/volunteer-id-card"
          element={<VolunteerMain children={<VolunteerIdCard />} />}
        />
        <Route path="/id-card" element={<Dashboard children={<IdCard />} />} />
        <Route
          path="/change-password"
          element={<Dashboard children={<ChangePassword />} />}
        />
        <Route
          path="/volunteer-change-password"
          element={<VolunteerMain children={<VolunteerChangePassword />} />}
        />
          <Route
          path="/terms&condition"
          element={<TermsCondition/>} 
        />
        <Route
          path="/disclaimer"
          element={<Disclaimer/>} 
        />
    
      <Route
          path="/privacy-policy"
          element={<PrivacyPolice/>} 
        />
      </Routes>
    </div>
  );
};

export default App;
