import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Volunteerlogin = () => {
  {
    /* =============================================================================================== */
  }

  const [Email, setEmail] = useState("");
  const [Pwd, setPwd] = useState("");

  const volLogin = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/volunteer/volunteerLogin",
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: {
          email: Email,
          volPwd: Pwd,
        },
      };

      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          // res.cookie("token", res.data.token, { httpOnly: true });
          localStorage.setItem("voluteerToken", res.data.token);
          localStorage.setItem(
            "encodeVolInfo",
            JSON.stringify(res.data.encode)
          );
          window.location.assign("/volunteer-dashboard");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  {
    /* =============================================================================================== */
  }

  return (
    <div>
      <div className="signin">
        <div className="Auth-form-container">
          <form className="Auth-form">
            <div className="Auth-form-content">
              <h3 className="Auth-form-title">Sign In</h3>
              <div className="form-group mt-3">
                <label>Email Address</label>
                <input
                  type="email or tel"
                  className="form-control mt-1"
                  placeholder="Enter email"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  value={Pwd}
                  onChange={(e) => setPwd(e.target.value)}
                />
              </div>
              <div className="d-grid gap-2 mt-3">
                <button
                  type="submit"
                  className="btn btn-primary btn-signin"
                  onClick={volLogin}
                >
                  Submit
                </button>
              </div>
              <p className="forgot-password text-right mt-2">
                Forgot <a href="#">password?</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Volunteerlogin;
