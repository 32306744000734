import React, { useState } from "react";
import { Link } from "react-router-dom";

function SideBar() {
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [acc5, setacc5] = useState(false);
  const [acc6, setacc6] = useState(false);
  const [acc7, setacc7] = useState(false);
  const [acc8, setacc8] = useState(false);
  const [acc9, setacc9] = useState(false);
  const [acc10, setacc10] = useState(false);
  const [acc11, setacc11] = useState(false);
  const [acc12, setacc12] = useState(false);
  const [acc13, setacc13] = useState(false);
  const [acc14, setacc14] = useState(false); //for blog
  const [acc15, setacc15] = useState(false); //for about us
  const [acc16, setacc16] = useState(false); //for Founder & Trustee
  const [acc17, setacc17] = useState(false); // fundraiser
  const [acc18, setacc18] = useState(false);
  const [acc19, setacc19] = useState(false);
  const [acc20, setacc20] = useState(false);

  const [ele, setele] = useState(false);
  const [ele1, setele1] = useState(false);

  return (
    <div className="si09">
      <div className="lo-ad">
        <img
          src="./implogo.jpeg"
          alt="adminlogo"
          style={{ width: "230px", height: "57px", padding: "0px 11px" }}
        />
      </div>
      <ul className="">
        <Link to="/dashboard">
          <li
            className={`a-ele ${acc ? "active-0" : ""}`}
            onClick={() => {
              setacc(true);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Dashboard
          </li>
        </Link>
        <Link to="#">
          <li
            className={`a-ele ${acc7 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(true);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setele(!ele);
            }}
          >
            Volunteers
          </li>
        </Link>
        {ele ? (
          <>
            <Link to="/not-approved">
              <li
                style={{ margin: "10px 40px" }}
                className={`a-ele ${acc8 ? "active-0" : "null"}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(true);
                  setacc9(false);
                  setacc10(false);
                  setacc11(false);
                  setacc12(false);
                  setacc13(false);
                  setacc14(false);
                  setacc15(false);
                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                Not Approved
              </li>
            </Link>
            <Link to="/approved">
              <li
                style={{ margin: "10px 40px" }}
                className={`a-ele ${acc9 ? "active-0" : "null"}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  setacc9(true);
                  setacc10(false);
                  setacc11(false);
                  setacc12(false);
                  setacc13(false);
                  setacc14(false);
                  setacc15(false);
                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                Approved
              </li>
            </Link>
          </>
        ) : (
          <></>
        )}
        <Link to="/banner">
          <li
            className={`a-ele ${acc10 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);

              setacc8(false);
              setacc9(false);
              setacc10(true);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Home page Banner
          </li>
        </Link>

        <Link to="/a-founderandtrustee">
          <li
            className={`a-ele ${acc16 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(true);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Founder & Trustee
          </li>
        </Link>

        <Link to="/a-aboutus">
          <li
            className={`a-ele ${acc15 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(true);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            About us
          </li>
        </Link>

        <Link to="#">
          <li
            className={`a-ele ${acc17 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(true);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setele1(!ele1);
            }}
          >
            Fundraiser
          </li>
        </Link>
        {ele1 ? (
          <>
            <Link to="/a-addCause">
              <li
                style={{ margin: "10px 40px" }}
                className={`a-ele ${acc18 ? "active-0" : "null"}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  setacc9(false);
                  setacc10(false);
                  setacc11(false);
                  setacc12(false);
                  setacc13(false);
                  setacc14(false);
                  setacc15(false);
                  setacc16(false);
                  setacc17(false);
                  setacc18(true);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                Add Cause
              </li>
            </Link>
            <Link to="/a-fundreq">
              <li
                style={{ margin: "10px 40px" }}
                className={`a-ele ${acc19 ? "active-0" : "null"}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  setacc9(false);
                  setacc10(false);
                  setacc11(false);
                  setacc12(false);
                  setacc13(false);
                  setacc14(false);
                  setacc15(false);
                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(true);
                  setacc20(false);
                }}
              >
                Fund requested
              </li>
            </Link>
            <Link to="/a-fundapproved">
              <li
                style={{ margin: "10px 40px" }}
                className={`a-ele ${acc20 ? "active-0" : "null"}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  setacc9(false);
                  setacc10(false);
                  setacc11(false);
                  setacc12(false);
                  setacc13(false);
                  setacc14(false);
                  setacc15(false);
                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(true);
                }}
              >
                Fund approved
              </li>
            </Link>
          </>
        ) : (
          <></>
        )}

        <Link to="/a-service">
          <li
            className={`a-ele ${acc13 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);

              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(true);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Services
          </li>
        </Link>
        <Link to="/gallery1">
          <li
            className={`a-ele ${acc11 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);

              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(true);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Gallery
          </li>
        </Link>

        <Link to="/blog1">
          <li
            className={`a-ele ${acc14 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);

              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(true);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Blogs
          </li>
        </Link>

        <Link to="/contact-us">
          <li
            className={`a-ele ${acc12 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);

              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(true);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Contact Us
          </li>
        </Link>

        <Link to="/doner-list">
          <li
            className={`a-ele ${acc1 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(true);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);

              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Donars List
          </li>
        </Link>
        {/*
        <Link to="/profile">
          <li
            className={`a-ele ${acc2 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(true);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Profile
          </li>
        </Link>
        <Link to="/bank-details">
          <li
            className={`a-ele ${acc3 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(true);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Bank Details
          </li>
        </Link>
        <Link to="/expense">
          {" "}
          <li
            className={`a-ele ${acc4 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(true);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Expense
          </li>
        </Link>
        <Link to="/id-card">
          {" "}
          <li
            className={`a-ele ${acc5 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(true);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            ID Card
          </li>
        </Link>
        <Link to="/change-password">
          {" "}
          <li
            className={`a-ele ${acc6 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(true);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
            }}
          >
            Change Passwords
          </li>
        </Link> */}
      </ul>
    </div>
  );
}

export default SideBar;
