import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import Form from "react-bootstrap/Form";

function Contactus1() {
  /* ======================================================================================================== */
  //   add contact details modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [bannerimg, setbannerimg] = useState("");
  const [email, setemail] = useState("");
  const [phno, setphno] = useState();
  const [fulladdress, setfulladdress] = useState("");
  const [ContactDetailss, setContactDetailss] = useState([]);
  const [Details, setDetails] = useState({});

  /* ======================================================================================================== */

  //   edit contact details modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  /* ======================================================================================================== */

  //   delete contact details modal
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  /* ======================================================================================================== */
  const obj = {
    bannerimg: bannerimg,
    email: email,
    phno: phno,
    fulladdress: fulladdress,
  };

  const addContactDetails = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/postContactDetails`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          setbannerimg("");
          setemail("");
          setphno();
          setfulladdress("");
          getContactusDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  //   /* ======================================================================================================== */

  const getContactusDetails = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getContactDetails")
        .then((res) => {
          if (res.status === 200) {
            setContactDetailss(res.data.contactusList);
          }
        });
    } catch (error) {
      console.log(error);
      setContactDetailss([]);
    }
  };

  //   /* ======================================================================================================== */

  const obj1 = {
    bannerimg: bannerimg,
    email: email,
    phno: phno,
    fulladdress: fulladdress,
  };

  const editContactDetails = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/editContactDetails/${Details?._id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setbannerimg("");
          setemail("");
          setphno();
          setfulladdress("");
          getContactusDetails();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  //   /* ======================================================================================================== */

  const deleteContactDetails = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deleteContactDetails/${Details?._id}`,
        method: "delete",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getContactusDetails();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */

  const [requsers, setrequsers] = useState([]);
  const getReqUsers = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/user/reqUsers")
        .then((res) => {
          if (res.status === 200) {
            setrequsers(res.data.reqUsers);
          }
        });
    } catch (error) {
      console.log(error);
      setContactDetailss([]);
    }
  };

  //   /* ======================================================================================================== */
  useEffect(() => {
    const adminToken = localStorage.getItem("adminToken");
    if (!adminToken) {
      alert("Please login first");
      window.location.assign("/admin");
    }
    getContactusDetails();
    getReqUsers();
  }, []);

  return (
    <div>
      <div
        className="main-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>Contact Us</h4>
        <Button onClick={handleShow}>Add+</Button>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Banner</th>
              <th>Email</th>
              <th>Contact No.</th>
              <th>Full - Address</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {ContactDetailss?.map((item, i) => {
              return (
                <tr>
                  <td>{++i}. </td>
                  <td>
                    <img
                      src={`https://srivinayaka.info/Contactus/${item?.bannerimg}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "100px",
                      }}
                      alt=""
                    />
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.phno}</td>
                  <td>{item?.fulladdress}</td>
                  <td>
                    <BiEdit
                      style={{
                        color: "blue",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setDetails(item);
                        handleShow1();
                      }}
                    />
                    /
                    <AiOutlineDelete
                      style={{ color: "red", marginLeft: "10px" }}
                      onClick={() => {
                        setDetails(item);
                        handleShow2();
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div className="secondary-container">
        <h4>Requested Users</h4>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Email</th>
              <th>Contact No.</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {requsers?.map((item, i) => {
              return (
                <tr>
                  <td>{++i}. </td>
                  <td>{item?.fullName}</td>
                  <td>{item?.email}</td>
                  <td>{item?.phno}</td>
                  <td>{item?.msg}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {/* add contact us details modal - starts */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Banner:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                required
                onChange={(e) => setbannerimg(e.target.files[0])}
              />
            </Form.Group>
            <Form.Group controlId="">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                value={email}
                placeholder="Enter your email"
                required
                onChange={(e) => setemail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="">
              <Form.Label>Contact Number:</Form.Label>
              <Form.Control
                type="number"
                value={phno}
                placeholder="Don't add 0 or +91 in front of the number"
                required
                onChange={(e) => setphno(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="">
              <Form.Label>Full-Address:</Form.Label>
              <Form.Control
                type="textarea"
                value={fulladdress}
                required
                onChange={(e) => setfulladdress(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => addContactDetails(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add image modal - ends */}

      {/* edit image modal - starts */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Banner:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                onChange={(e) => setbannerimg(e.target.files[0])}
              />
            </Form.Group>
            <Form.Group controlId="">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                value={email}
                placeholder={Details?.email}
                onChange={(e) => setemail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="">
              <Form.Label>Contact Number:</Form.Label>
              <Form.Control
                type="number"
                value={phno}
                placeholder={Details?.phno}
                onChange={(e) => setphno(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="">
              <Form.Label>Full-Address:</Form.Label>
              <Form.Control
                type="textarea"
                value={fulladdress}
                placeholder={Details?.fulladdress}
                onChange={(e) => setfulladdress(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => editContactDetails(e)}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit image modal - ends */}

      {/* delete image modal - starts */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the Details?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => deleteContactDetails(e)}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete image modal - ends */}
    </div>
  );
}

export default Contactus1;
