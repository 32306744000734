import React, { useEffect, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
const Sidetab = () => {
  const navigate = useNavigate();
  function nextPage(val) {
    navigate("/post", {
      state: val,
    });
  }
  /* ============================================================================================================== */

  const [popularblogs, setpopularblogs] = useState([]);
  const allPopularBlogs = async () => {
    try {
      const res = await axios.get(
        "https://srivinayaka.info/api/admin/popularBlogs"
      );
      if (res.status === 200) {
        setpopularblogs(res.data.BlogList);
      }
    } catch (error) {
      console.log(error);
      setpopularblogs([]);
    }
  };

  /* ============================================================================================================== */

  useEffect(() => {
    allPopularBlogs();
  }, []);
  return (
    <div style={{ marginTop: "20px" }}>
      <div className="sidetab">
        <div
          style={{
            padding: "40px 40px 10px 40px",
          }}
        >
          <p className="text-sidetab">Follow us</p>
        </div>

        <div className="icons" style={{ padding: "10px  35px  10px 25px" }}>
          <FacebookIcon
            fontSize="large"
            style={{ margin: "10px", border: "" }}
          />
          <TwitterIcon style={{ margin: "10px" }} fontSize="large" />
          <YouTubeIcon style={{ margin: "10px" }} fontSize="large" />
          <GitHubIcon style={{ margin: "10px" }} fontSize="large" />
          <EmailIcon style={{ margin: "10px" }} fontSize="large" />
        </div>

        <div className="popular-post"></div>
        <div
          style={{
            margin: "40px",
          }}
        >
          <p className="text-sidetab">POPULAR POSTS</p>
          {popularblogs?.map((item) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ margin: "20px" }}>
                  <img
                    src={`https://srivinayaka.info/Blogs/${item?.img}`}
                    style={{ height: "50px", width: "50px" }}
                  ></img>
                </div>
                <div style={{ margin: "20px" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      width: "180px",
                      color: "#DA4453",
                    }}
                  >
                    <span className="post-text" onClick={() => nextPage(item)}>
                      {" "}
                      {item?.topic}
                    </span>
                  </p>
                  <p style={{ color: "#6B6b6b", fontSize: "12px" }}>
                    {moment(item?.createdAt).format("DD MMMM YYYY")}
                  </p>
                </div>
              </div>
            );
          })}
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ margin: "20px" }}>
              <img
                src="./slid1.png"
                style={{ height: "50px", width: "50px" }}
              ></img>
            </div>
            <div style={{ margin: "20px" }}>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  width: "180px",
                  color: "#DA4453",
                }}
              >
                {" "}
                <Link className="post-text">
                  {" "}
                  How to Use WordPress Pingbacks And Trackbacks
                </Link>
              </p>
              <p style={{ color: "#6B6b6b", fontSize: "12px" }}>
                April 7, 2015
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ margin: "20px" }}>
              <img
                src="./slid1.png"
                style={{ height: "50px", width: "50px" }}
              ></img>
            </div>
            <div style={{ margin: "20px" }}>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  width: "180px",
                  color: "#DA4453",
                }}
              >
                {" "}
                <Link className="post-text">
                  {" "}
                  How to Use WordPress Pingbacks And Trackbacks
                </Link>
              </p>
              <p style={{ color: "#6B6b6b", fontSize: "12px" }}>
                April 7, 2015
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ margin: "20px" }}>
              <img
                src="./slid1.png"
                style={{ height: "50px", width: "50px" }}
              ></img>
            </div>
            <div style={{ margin: "20px" }}>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  width: "180px",
                  color: "#DA4453",
                }}
              >
                {" "}
                <Link className="post-text">
                  {" "}
                  How to Use WordPress Pingbacks And Trackbacks
                </Link>
              </p>
              <p style={{ color: "#6B6b6b", fontSize: "12px" }}>
                April 7, 2015
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ margin: "20px" }}>
              <img
                src="./slid1.png"
                style={{ height: "50px", width: "50px" }}
              ></img>
            </div>
            <div style={{ margin: "20px" }}>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  width: "180px",
                  color: "#DA4453",
                }}
              >
                {" "}
                <Link className="post-text">
                  {" "}
                  How to Use WordPress Pingbacks And Trackbacks
                </Link>
              </p>
              <p style={{ color: "#6B6b6b", fontSize: "12px" }}>
                April 7, 2015
              </p>
            </div>
          </div> */}
        </div>
        <div
          style={{
            padding: "40px 40px 10px 40px",
          }}
        >
          {/* <p className="text-sidetab">CATEGORIES </p>

          <div className="category">
            <ul>
              <li>
                <div>lorem ipsum</div> <div>17</div>
              </li>
            </ul>
          </div>

          <div className="category">
            <ul>
              <li>
                <div>lorem ipsum</div> <div>17</div>
              </li>
            </ul>
          </div>
          <div className="category">
            <ul>
              <li>
                <div>lorem ipsum</div> <div>54</div>
              </li>
            </ul>
          </div>
          <div className="category">
            <ul>
              <li>
                <div>lorem ipsum</div> <div>19</div>
              </li>
            </ul>
          </div>
          <div className="category">
            <ul>
              <li>
                <div>lorem ipsum</div> <div>45</div>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Sidetab;
