import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";

function Blog1() {
  /* ======================================================================================================== */
  // post banner
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //   edit Banner modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  //   delete Banner modal
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  //   /* ======================================================================================================== */

  const [bannerimg, setbannerimg] = useState("");
  const [Banners, setBanners] = useState([]);
  const [Details, setDetails] = useState({});

  const obj = {
    bannerimg: bannerimg,
  };

  const postBanner = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/postBlogBanner`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          getBanner();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getBanner = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getBlogBanner")
        .then((res) => {
          if (res.status === 200) {
            setBanners(res.data.blogBannerList);
          }
        });
    } catch (error) {
      console.log(error);
      setBanners([]);
    }
  };

  const obj1 = {
    bannerimg: bannerimg,
  };

  const editBanner = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/editBlogBanner/${Details?._id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setbannerimg("");
          getBanner();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const deleteBanner = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deleteBlogBanner/${Details?._id}`,
        method: "delete",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getBanner();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */
  //   add Blogs modal
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //   edit Blogs modal
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // delete Blogs modal
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // add Blogs
  const [Blogimg, setBlogimg] = useState("");
  const [Topic, setTopic] = useState("");
  const [Body, setBody] = useState("");
  const [Blogs, setBlogs] = useState([]);
  const [BlogsDetails, setBlogsDetails] = useState({});

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setBody(data);
  };
  const BlogObj = {
    img: Blogimg,
    topic: Topic,
    content: Body,
  };
  const postBlog = async (e) => {
    e.preventDefault();
    try {
      try {
        const config = {
          url: `/admin/postBlog`,
          method: "post",
          baseURL: "https://srivinayaka.info/api",
          headers: { "content-type": "multipart/form-data" },
          data: BlogObj,
        };
        await axios(config).then((res) => {
          if (res.status === 201) {
            alert(res.data.success);
            setBlogimg("");
            setTopic("");
            setBody("");
            getBlogs();
            handleClose3();
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBlogs = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getBlogs")
        .then((res) => {
          if (res.status === 200) {
            setBlogs(res.data.BlogList);
          }
        });
    } catch (error) {
      console.log(error);
      setBlogs([]);
    }
  };

  const Blogobj1 = {
    img: Blogimg,
    topic: Topic,
    content: Body,
  };

  const editBlogs = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/editBlogs/${BlogsDetails?._id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: Blogobj1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setBlogimg("");
          setTopic("");
          setBody("");
          getBlogs();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const deleteBlog = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deleteBlogs/${BlogsDetails?._id}`,
        method: "delete",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getBlogs();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */
  useEffect(() => {
    const adminToken = localStorage.getItem("adminToken");
    if (!adminToken) {
      alert("Please login first");
      window.location.assign("/admin");
    }
    getBanner();
    getBlogs();
  }, []);

  return (
    <div>
      <div
        className="main-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>Blog Banners</h4>
        <Button onClick={handleShow}>Add+</Button>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Banner</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Banners?.map((item, i) => {
              return (
                <tr>
                  <td>{++i}. </td>
                  <td>
                    <img
                      src={`https://srivinayaka.info/Blogs/${item?.bannerimg}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "100px",
                      }}
                      alt=""
                    />
                  </td>
                  <td>
                    <BiEdit
                      style={{
                        color: "blue",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setDetails(item);
                        handleShow1();
                      }}
                    />
                    /
                    <AiOutlineDelete
                      style={{ color: "red", marginLeft: "10px" }}
                      onClick={() => {
                        setDetails(item);
                        handleShow2();
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div
        className="secondary-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>Blogs</h4>
        <Button onClick={handleShow3}>Add+</Button>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Image</th>
              <th>Topic</th>
              <th>Body</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Blogs?.map((item, i) => {
              return (
                <tr>
                  <td>{++i}. </td>
                  <td>
                    <img
                      src={`https://srivinayaka.info/Blogs/${item?.img}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "100px",
                      }}
                      alt=""
                    />
                  </td>
                  <td>{item?.topic}</td>
                  <td>{parse(item?.content)}</td>
                  <td>
                    <div className="d-flex">
                      <BiEdit
                        style={{
                          color: "blue",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          setBlogsDetails(item);
                          handleShow4();
                        }}
                      />
                      /
                      <AiOutlineDelete
                        style={{ color: "red", marginLeft: "10px" }}
                        onClick={() => {
                          setBlogsDetails(item);
                          handleShow5();
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {/* add banner modal - starts */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Banner:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                required
                onChange={(e) => setbannerimg(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => postBanner(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add banner modal - ends */}

      {/* edit banner modal - starts */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Banner:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                onChange={(e) => setbannerimg(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => editBanner(e)}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit banner modal - ends */}

      {/* delete banner modal - starts */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the Details?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => deleteBanner(e)}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete banner modal - ends */}

      {/* add blogs modal - starts */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Add Blogs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Image:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                required
                onChange={(e) => setBlogimg(e.target.files[0])}
              />
            </Form.Group>
            <Form.Group controlId="">
              <Form.Label>Topic:</Form.Label>
              <Form.Control
                type="text"
                value={Topic}
                required
                onChange={(e) => setTopic(e.target.value)}
              />
            </Form.Group>
            {/* <Form.Group controlId="">
              <Form.Label>Body:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={Body}
                required
                onChange={(e) => setBody(e.target.value)}
              />
            </Form.Group> */}
          </Form>
          <label>Body: </label>
          <CKEditor
            editor={ClassicEditor}
            data={Body}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => postBlog(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add blogs modal - ends */}

      {/* edit blogs modal - starts */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Blogs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="">
              <Form.Label>Select Image:</Form.Label>
              <Form.Control
                type="file"
                id="file"
                name="file"
                required
                onChange={(e) => setBlogimg(e.target.files[0])}
              />
            </Form.Group>
            <Form.Group controlId="">
              <Form.Label>Topic:</Form.Label>
              <Form.Control
                type="text"
                value={Topic}
                placeholder={BlogsDetails?.topic}
                required
                onChange={(e) => setTopic(e.target.value)}
              />
            </Form.Group>
            {/* <Form.Group controlId="">
              <Form.Label>Body:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={Body}
                placeholder={ServiceDetails?.content}
                required
                onChange={(e) => setBody(e.target.value)}
              />
            </Form.Group> */}
          </Form>
          <label>Body: </label>
          <CKEditor
            editor={ClassicEditor}
            data={BlogsDetails?.content}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => editBlogs(e)}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit blogs modal - ends */}

      {/* delete blogs modal - starts */}
      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Blogs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the Blog details?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => deleteBlog(e)}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete Blog modal - ends */}
    </div>
  );
}

export default Blog1;
