import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
function Footer() {
  return (
    <>
      <div className="footer footer-section " style={{}}>
        <div >
          <p className="contactus">Contact Us</p>
          <div>
            <p id="address">Address</p>

            <div>
              <p className="links">Sri Vinayaka Charitable Trust (R),</p>
              <p className="links">#11/22,3rd Cross,80Feet Road,</p>
              <p className="links"> ,Nagadevanahalli,Jnanbharthi Post,</p>
              <p className="links">Bengaluru -560056,Karnataka.</p>
            </div>
          </div>
        </div>

        <div className="" style={{marginTop:'20px'}}>
          <p  style={{fontSize:'17px'}}>Email</p>
         
            <p id=""

            ><Link  className="a">srivinayakacharitabletrust@gmail.com</Link></p>

            <div>
              <p style={{fontSize:'15px'}}><Link className="a">Contact Number</Link></p>
              <p style={{fontSize:'15px'}}><Link className="a">08095719080</Link></p>
              <p style={{fontSize:'15px'}}> <Link className="a">WhatsApp number</Link></p>
              <p style={{fontSize:'15px'}}><Link className="a">+91-8884343999</Link></p>
          
            </div>
        </div>

        <div className="" >
          <p style={{fontSize:'20px'}}>Quick Links</p>
          <div style={{marginTop:'10px'}}>
           
            <p className="links"><Link  className="a" to='/'>Home</Link></p>
            <p className="links"><Link className="a"to='/aboutus'>About Us</Link> </p>
            <p className="links"><Link className="a"to='/services'>Services</Link></p>
            <p className="links"><Link className="a"to='/gallery'>Gallery</Link></p>
            <p className="links"><Link className="a"to='/blog'>Blog</Link></p>
            <p className="links"><Link className="a"to='/becomeavolunteer'>Become a Volunteer</Link> </p>
            <p className="links"><Link className="a"to='/contactus'>Contact Us</Link></p>
            <p className="links"><Link className="a"to='/terms&condition'>Terms & Condition</Link></p>
            <p className="links"><Link className="a"to='/privacy-policy'>Privacy Policy</Link></p>
            <p className="links"><Link className="a"to='/disclaimer'>Disclaimer</Link></p>
            
          </div>
        </div>

        <div className="col-lg-3 col-md-3 ">
          <div className="map-contact">
            <div className="map-contact-0" style={{display:'flex',justifyContent:'center',alignItems:'center',padding:'0px'}}>
              <iframe
                style={{
                  borderTopLeftRadius: " 88px",
                  borderBottomRightRadius: "76px",
                }}
                src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d4019.9719891960317!2d77.49382196865601!3d12.937102733484611!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDU2JzA5LjYiTiA3N8KwMjknMzMuOSJF!5e0!3m2!1sen!2sin!4v1683285872291!5m2!1sen!2sin"
                width="250"
                height="250"
                borderRadius="50%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.4501600007216!2d77.64169129999999!3d13.070632099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19728f5f4a85%3A0x1bd5917b32b02838!2sSpectre%20Engineering%20Private%20Limited!5e0!3m2!1sen!2sin!4v1677231611623!5m2!1sen!2sin" 
               style={{width:"100%",height:"256px",}}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
