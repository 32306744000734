import React from "react";
import "./signup.css";
import { Button } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
const Signup = () => {
  return (
    <>
      <div
        className="back-image"
        style={{
          marginTop: "-20px",
        }}
      >
        <div
          style={{
            border: "solid 2px #F0F8FF",
            width: "750px",
            margin: "50px auto",
            paddingBottom: "10px",
            background: "#F0F8FF",
            marginTop:'50px',


           
          }}
        >
          {/* <Link to="/">   <Button style={{width:'150px',marginTop:'10px',borderRadius:'50px',marginLeft:'0px',fontSize:'15px',fontWeight:'bold' ,color:'white',backgroundColor:'#FF0000',borderColor:'#FF0000'}}><ArrowBackIcon fontSize='large'/>Back</Button> </Link>  */}

          <div
            class=""
            style={{
               
              textAlign: "center",
              fontSize: "24px",padding:'30px',
                           fontWeight: "bold",
            }}
          >
            SIGN UP
          </div>

          <div style={{ display: "flex", marginTop: "50px" }}>
            <form style={{ marginLeft: "50px", marginTop: "-30px" }}>
              <div
                class="mb-3"
                style={{
                  width: "50%",
                  marginTop: "0px",
                  width: "300px",
                  marginBottom: "0px",
                }}
              >
                <label
                  for="exampleInputEmail1"
                  class="form-label"
                  style={{
                    fontWeight: "bold",
                    display: "grid",
                    placeItems: "flex-start",
                  }}
                >
                  Full name*:-
                </label>
                <input
                  type="email or tel"
                  class="form-control"
                  id="exampleInputEmail1"
                  style={{
                    marginLeft: "-5px",
                    marginTop: "0px",
                    height: "40px",
                  }}
                  placeholder="Full Name"
                  aria-describedby="emailHelp"
                />
              </div>

              <div
                class="mb-3"
                style={{
                  width: "50%",
                  marginTop: "0px",
                  width: "300px",
                  marginBottom: "0px",
                }}
              >
                <label
                  for="exampleInputEmail1"
                  class="form-label"
                  style={{
                    fontWeight: "bold",
                    display: "grid",
                    placeItems: "flex-start",
                  }}
                >
                  Taluk/village*:-
                </label>
                <input
                  type="email or tel"
                  class="form-control"
                  id="exampleInputEmail1"
                  style={{
                    marginLeft: "-5px",
                    marginTop: "0px",
                    height: "40px",
                  }}
                  placeholder="Taluk/village"
                  aria-describedby="emailHelp"
                />
              </div>

              <div
                class="mb-3"
                style={{
                  width: "50%",
                  marginTop: "0px",
                  width: "300px",
                  marginBottom: "0px",
                }}
              >
                <label
                  for="exampleInputEmail1"
                  class="form-label"
                  style={{
                    fontWeight: "bold",
                    display: "grid",
                    placeItems: "flex-start",
                  }}
                >
                  District*:-
                </label>
                <input
                  type="email or tel"
                  class="form-control"
                  id="exampleInputEmail1"
                  style={{
                    marginLeft: "-5px",
                    marginTop: "0px",
                    height: "40px",
                  }}
                  placeholder="District"
                  aria-describedby="emailHelp"
                />
              </div>

              <div
                class="mb-3"
                style={{
                  width: "50%",
                  marginTop: "0px",
                  width: "300px",
                  marginTop: "-5px",
                }}
              >
                <label
                  for=""
                  class="form-label"
                  style={{
                    fontWeight: "bold",
                    display: "grid",
                    placeItems: "flex-start",
                  }}
                >
                  Email adress*:-
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputPassword1"
                  style={{
                    marginLeft: "-5px",
                    marginTop: "0px",
                    height: "40px",
                  }}
                  placeholder="Email"
                />
              </div>

              <div
                class="mb-3"
                style={{
                  width: "50%",
                  marginTop: "0px",
                  width: "300px",
                  marginTop: "-5px",
                }}
              >
                <label
                  for=""
                  class="form-label"
                  style={{
                    fontWeight: "bold",
                    display: "grid",
                    placeItems: "flex-start",
                  }}
                >
                  Phone no*:-
                </label>
                <input
                  type="tel"
                  class="form-control"
                  id="exampleInputPassword1"
                  style={{
                    marginLeft: "-5px",
                    marginTop: "0px",
                    height: "40px",
                  }}
                  placeholder="Phone Number"
                />
              </div>

              <div
                class="input-group"
                placeholder="User"
                style={{
                  width: "50%",
                  marginTop: "0px",
                  width: "300px",
                  marginTop: "10px",
                }}
              >
                <label
                  for="exampleInputPassword1"
                  class="form-label"
                  style={{
                    fontWeight: "bold",
                    display: "grid",
                    placeItems: "flex-start",
                  }}
                >
                  Cause*:-
                </label>
                <div
                  className="oneafter"
                  style={{ width: "50%", width: "300px", marginTop: "0px" }}
                >
                  <select
                    class="form-select"
                    id="inputGroupSelect04"
                    aria-label="Example select with button addon"
                  >
                    <option value="1">Cause</option>
                    <option value="2">Transpalant</option>
                    <option value="3">Heart Disease</option>
                    <option value="4">Brain Surgery</option>
                    <option value="5">Cancer</option>
                    <option value="6">Rare Disease</option>
                  </select>
                </div>
              </div>
            </form>

            <div style={{ marginLeft: "50px", marginTop: "-25px" }}>
              <form>
                <div
                  class="mb-3"
                  style={{
                    width: "50%",
                    marginTop: "0px",
                    width: "300px",
                    marginTop: "-5px",
                  }}
                >
                  <label
                    for="exampleInputPassword1"
                    class="form-label"
                    style={{
                      fontWeight: "bold",
                      display: "grid",
                      placeItems: "flex-start",
                    }}
                  >
                    Password*:-
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="exampleInputPassword1"
                    style={{
                      marginLeft: "-5px",
                      marginTop: "0px",
                      height: "40px",
                    }}
                    placeholder="Password"
                  />
                </div>

                <div
                  class="mb-3"
                  style={{
                    width: "50%",
                    marginTop: "0px",
                    width: "300px",
                    marginTop: "-5px",
                  }}
                >
                  <label
                    for="exampleInputPassword1"
                    class="form-label"
                    style={{
                      fontWeight: "bold",
                      display: "grid",
                      placeItems: "flex-start",
                    }}
                  >
                    Confirm Password*:-
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="exampleInputPassword1"
                    style={{
                      marginLeft: "-5px",
                      marginTop: "0px",
                      height: "40px",
                    }}
                    placeholder="Confirm Password"
                  />
                </div>

                <div
                  class="input-group"
                  placeholder="User"
                  style={{
                    width: "50%",
                    marginTop: "0px",
                    width: "300px",
                    marginTop: "10px",
                  }}
                >
                  <label
                    for="exampleInputPassword1"
                    class="form-label"
                    style={{
                      fontWeight: "bold",
                      display: "grid",
                      placeItems: "flex-start",
                    }}
                  >
                    Select User Type*:-
                  </label>
                  <div
                    className="oneafter"
                    style={{ width: "50%", width: "300px", marginTop: "0px" }}
                  >
                    <select 
                   
                      class="form-select"
                      id="inputGroupSelect04"
                      aria-label="Example select with button addon"
                    >
                      <option value="1">User</option>
                      <option value="2">Donor</option>
                      <option value="3">Volunteer</option>
                      <option value="3">Company</option>
                    </select>
                  </div>
                </div>

                <div
                  class="mb-3"
                  style={{ width: "50%", marginTop: "20px", width: "300px" }}
                >
                  <label
                    for="exampleInputPassword1"
                    class="form-label"
                    style={{
                      fontWeight: "bold",
                      display: "grid",
                      placeItems: "flex-start",
                    }}
                  >
                    PAN No*:-
                  </label>
                  <input
                    type="tel"
                    class="form-control"
                    id="exampleInputPassword1"
                    style={{
                      marginLeft: "-5px",
                      marginTop: "0px",
                      height: "40px",
                    }}
                    placeholder="PAN No"
                  />
                </div>

                <div
                  class="mb-3"
                  style={{
                    width: "50%",
                    marginTop: "0px",
                    width: "300px",
                    marginTop: "-10px",
                  }}
                >
                  <label
                    for="exampleInputPassword1"
                    class="form-label"
                    style={{
                      fontWeight: "bold",
                      display: "grid",
                      placeItems: "flex-start",
                    }}
                  >
                    Adhar No*:-
                  </label>
                  <input
                    type="tel"
                    class="form-control"
                    id="exampleInputPassword1"
                    style={{
                      marginLeft: "-5px",
                      marginTop: "0px",
                      height: "40px",
                    }}
                    placeholder="Adhar No"
                  />
                </div>

                <label
                  for="img"
                  style={{ fontWeight: "bold", marginTop: "15px" }}
                >
                  Upload image:-
                </label>
                <input
                  type="file"
                  id="img"
                  name="img"
                  accept="image/*"
                  style={{
                    border: "none",
                    padding: "0px",
                    background: "none",
                  }}
                />
              </form>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <div
              className=""
              style={{
                color: "black !important",
                display: "flex",
                marginTop: "0px",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <p style={{ fontSize: "14px" }}>
                {" "}
                Do you already have an account please
              </p>
              <a
                style={{ marginLeft: "10px", marginTop: "-3px" }}
                href="https://www.crowdfunding.com"
              >
                signin ?
              </a>
            </div>

            <button
              type="submit"
              class="btn btn-primary"
              style={{
                marginTop: "15px",
                marginBottom: "15px",
                width: "140px",
                marginLeft: "0px",
               
                backgroundColor: "yellow",
                color: "black",
                borderColor: "yellow",
              }}
            >
              <b>Submit</b>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
