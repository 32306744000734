import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";

function Banner1() {
  /* ======================================================================================================== */
  //   add image modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [bannerimg, setbannerimg] = useState("");
  const [Banners, setBanners] = useState([]);
  const [Bannerid, setBannerid] = useState("");

  /* ======================================================================================================== */

  //   edit image modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  /* ======================================================================================================== */

  //   delete image modal
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  /* ======================================================================================================== */
  const obj = {
    bannerimg: bannerimg,
  };

  const addImage = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/postbanner`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          setbannerimg("");
          getImages();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */

  const getImages = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getbanner")
        .then((res) => {
          if (res.status === 200) {
            setBanners(res.data.bannerList);
          }
        });
    } catch (error) {
      console.log(error);
      setBanners([]);
    }
  };

  /* ======================================================================================================== */

  const obj1 = {
    bannerimg: bannerimg,
  };

  const editImage = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/editbanner/${Bannerid}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setbannerimg("");
          getImages();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */

  const deleteImage = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deletebanner/${Bannerid}`,
        method: "delete",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getImages();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  /* ======================================================================================================== */
  useEffect(() => {
    getImages();
  }, []);

  return (
    <div>
      <div
        className="main-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>Banner list</h4>
        <Button onClick={handleShow}>Add+</Button>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Banner</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Banners.map((item, i) => {
              return (
                <tr>
                  <td>{++i}.</td>
                  <td>
                    <img
                      src={`https://srivinayaka.info/Banner/${item?.bannerimg}`}
                      style={{
                        maxWidth: "170px",
                        maxHeight: "1200px",
                      }}
                      alt=""
                    />
                  </td>
                  <td>
                    <BiEdit
                      style={{
                        color: "blue",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setBannerid(item?._id);
                        handleShow1();
                      }}
                    />
                    /
                    <AiOutlineDelete
                      style={{ color: "red", marginLeft: "10px" }}
                      onClick={() => {
                        setBannerid(item?._id);
                        handleShow2();
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {/* add image modal - starts */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Select Image: </label>
          <input
            type="file"
            id="file"
            name="file"
            required
            onChange={(e) => setbannerimg(e.target.files[0])}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => addImage(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add image modal - ends */}

      {/* edit image modal - starts */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Select Image: </label>
          <input
            type="file"
            id="file"
            name="file"
            required
            onChange={(e) => setbannerimg(e.target.files[0])}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => editImage(e)}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit image modal - ends */}

      {/* delete image modal - starts */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the banner?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => deleteImage(e)}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete image modal - ends */}
    </div>
  );
}

export default Banner1;
