import axios from "axios";
import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function BlogSlider() {
  const [blogImgs, setblogImgs] = useState([]);
  const allBlogSliders = async () => {
    try {
      const res = await axios.get(
        "https://srivinayaka.info/api/admin/getBlogBanner"
      );
      if (res.status === 200) {
        setblogImgs(res.data.blogBannerList);
      }
    } catch (error) {
      console.log(error);
      setblogImgs([]);
    }
  };

  useEffect(() => {
    allBlogSliders();
  }, []);
  return (
    <Carousel slide={false}>
      {blogImgs?.map((item) => {
        return (
          <Carousel.Item>
            <img
              style={{
                height: "450px",
              }}
              className="d-block w-100"
              src={`https://srivinayaka.info/Blogs/${item?.bannerimg}`}
              alt="First slide"
            />
            {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default BlogSlider;
