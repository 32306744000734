import React, { useEffect, useState } from "react";
import Header from "../Header/Header";

import Gallery from "./Gallery";
import Stack from "react-bootstrap/Stack";
import Footer from "../Footer/Footer";
import { Container, Row, Col, Image } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Navigation from "../../Navigation";
const Gallerymain = () => {
  /* ======================================================================================================== */
  const [galleries, setgalleries] = useState([]);
  const getImages = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getgallery")
        .then((res) => {
          if (res.status === 200) {
            setgalleries(res.data.galleryList);
          }
        });
    } catch (error) {
      console.log(error);
      setgalleries([]);
    }
  };
  /* ======================================================================================================== */
  useEffect(() => {
    getImages();
  }, []);

  return (
    <div>
      <Header />
      <Navigation />

      {/* <div class="tm-hero d-flex justify-content-center align-items-center" data-parallax="scroll"  
    data-image-src="./slid.jpg"
    
    >
      <img src="./slid.jpg"></img>
     
    </div> */}

      <div class="container-fluid tm-container-content tm-mt-60">
        <div class="row mb-4" style={{ textAlign: "center" }}>
          <h2 class="col-12 tm-text-primary">Gallery</h2>
        </div>
        <div class="row tm-mb-90 tm-gallery">
          {galleries.map((item1) => {
            return (
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                <figure class="effect-ming tm-video-item">
                  <img
                    src={`https://srivinayaka.info/Gallery/${item1?.galleryimg}`}
                    alt="Image"
                    class="img-fluid"
                    style={{height:"200px"}}
                  />
                </figure>
                <div class="d-flex justify-content-between tm-text-gray">
                  <span class="tm-text-gray-light">
                    {moment(item1?.createdAt).format("DD MMMM YYYY")}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Gallerymain;
