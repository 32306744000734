import React, { useEffect, useState } from "react";
import Slider from "../Slider";
import Aboutus from "../Aboutus/Aboutus";
import Services from "../Services/Services";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Image from "react-bootstrap/Image";
import SavingsIcon from "@mui/icons-material/Savings";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import Footer from "../Footer/Footer";
import { Button, Col, Container, Row } from "react-bootstrap";

import Activefundraiser from "../ActiveFundraiser/Activefundraiser";
// import Fundraiser from '../Startafundraiser/Fundraiser'
import Gallery from "../Gallery/Gallery";
import News from "../News/News";
import Navigation from "../../Navigation";
import "./body.css";
import axios from "axios";
const Body = () => {

  // const [info, setInfo] = useState({});
  // const getUser = async (id)=>{
  //   try{
  //     const res = await axios.get(`https://srivinayaka.info/api/user/getuserByid/${id}`);
  //     if(res.status === 200){
  //       setInfo(res.data.dHistory);
  //     }
  //   }catch(error){
  //     console.log(error);
  //   }
  // }

  // useEffect(()=>{
  //   const userdetails = JSON.parse(localStorage.getItem('encodeUserInfo'));
  //   if(userdetails){
  //     getUser(userdetails?.email);
  //   }
  // })
  return (
    <div>
      <Header></Header>
      <Navigation></Navigation>
      <Slider></Slider>
      <Aboutus></Aboutus>
      <Services></Services>
      <Activefundraiser></Activefundraiser>
      <Container fluid style={{ margin: "0px", padding: "0px" }}>
        <br></br>
        <div
          className
          style={{
            fontSize: "28px",
            color: "#DA4453",
            fontWeight: "500",
            textAlign: "center",
            paddingTop: "30px",
            paddingBottom: "-30px",
          }}
        >
          Why are people choosing medical crowdfunding?
        </div>
        <br></br>

        <Row style={{ padding: "0px", margin: "0px" }}>
          <Col
            md={{ span: 5 }}
            style={{ padding: "0px", margin: "auto", textAlign: "center" }}
          >
            <div style={{ padding: "0px" }}>
              {" "}
              <SavingsIcon fontSize="large" />
              <div
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  color: "#444444",
                  fontSize: "22px",
                  fontWeight: "550",
                }}
              >
                {" "}
                High Cost Of Treatment
              </div>
              <div
                style={{
                  textAlign: "center",
                  lineHeight: "32px",

                  color: "#6B6b6b",
                  textAlign: "justify",
                }}
              >
                Medical emergencies come without a warning. At times, health
                insurance and savings are not enough to cover the expensive
                bill. Crowdfunding helps you reach your goal by getting small
                donations from a large group of people.
              </div>
            </div>
          </Col>

          <Col
            md={{ span: 5 }}
            style={{ textAlign: "center", padding: "0px", margin: "auto" }}
          >
            <br></br>
            <div>
              {" "}
              <MoreTimeIcon fontSize="large" />
              <div
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  color: "#444444",
                  fontSize: "22px",
                  fontWeight: "550",
                }}
              >
                {" "}
                Emergencies Demand A Prompt Response
              </div>
              <div
                style={{
                  textAlign: "center",

                  color: "#6B6b6b",
                  lineHeight: "32px",
                  textAlign: "justify",
                }}
              >
                When your loved ones are diagnosed with life threatening
                diseases, it’s overwhelming emotionally as well as financially.
                Most medical emergencies require the treatment to start at the
                earliest. By giving you a platform to share your emergency,
                online crowdfunding helps you gather funds quickly.
              </div>
            </div>
          </Col>
        </Row>

        <br></br>
      </Container>
      {/* <Fundraiser></Fundraiser> */}
      <br></br> <br></br> <br></br>
      <div
        className
        style={{
          fontSize: "28px",
          color: "#DA4453",
          fontWeight: "500",
          textAlign: "center",
          paddingTop: "30px",
          paddingBottom: "-30px",
        }}
      >
        Gallery
      </div>
      <Gallery></Gallery>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        {" "}
        <Link to="/gallery" style={{ textDecoration: "none" }}>
          {" "}
          <Button variant="outline-danger">
            MORE{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              margin="0"
              height="20"
              fill="currentColor"
              class="bi bi-arrow-right-short"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </Button>
        </Link>
      </div>
      {/* <News></News> */}
      <Footer />
    </div>
  );
};

export default Body;
