import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import "./gallery.css";
import axios from "axios";
const Gallery = () => {
  /* ======================================================================================================== */
  const [galleries, setgalleries] = useState([]);
  const getImages = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getgallery")
        .then((res) => {
          if (res.status === 200) {
            setgalleries(res.data.galleryList);
          }
        });
    } catch (error) {
      console.log(error);
      setgalleries([]);
    }
  };
  /* ======================================================================================================== */
  useEffect(() => {
    getImages();
  }, []);

  return (
    <>
      <div>
        <Container>
          <Row>
            {galleries.map((item1) => {
              return (
                <Col xs={6} md={4} className="mb-3">
                  <Image
                    src={`https://srivinayaka.info/Gallery/${item1?.galleryimg}`}
                    style={{ width: "100%", height: "251px" }}
                    rounded
                  />
                </Col>
              );
            })}
          </Row>

          <br></br>

          
        </Container>
      </div>
    </>
  );
};

export default Gallery;
