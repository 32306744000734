import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import "./Donationhistory.css";
import axios from "axios";
import moment from "moment";
const Donationhistory = () => {
  const [info, setInfo] = useState([]);
  const getUser = async (id) => {
    try {
      const res = await axios.get(
        `https://srivinayaka.info/api/user/getuserByid/${id}`
      );
      if (res.status === 200) {
        setInfo(res.data.dHistory);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const userdetails = JSON.parse(localStorage.getItem("encodeUserInfo"));
    if (userdetails) {
      getUser(userdetails?.email);
    } else {
      window.location.assign("/");
    }
  });

  return (
    <div>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"></h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="col">
                <label>Remarks</label>
                <textarea className="form-control"></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <Navigation />

      <div class="container-fluid">
        <div class="row">
          <div class="col-12 mb-3 mb-lg-5">
            <div
              class="position-relative 
            table-nowrap 
            "
            >
              <div class=" align-items-center">
                <div className="row align-items-center  mt-5 mb-3">
                  <div className="col">
                    {" "}
                    <h5 class="">Donation History</h5>
                  </div>
                  <div className="col text-end">
                    <a
                      href="#!"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      Send request
                    </a>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="small text-uppercase bg-body text-muted">
                    <tr>
                      <th>S.no.</th>
                      <th>Donation type</th>
                      <th>Start Date</th>
                      <th> End Date</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {info?.map((item, i) => {
                      return (
                        <tr class=" list-donate">
                          <td>{++i}. </td>

                          <td>{item?.donateType}</td>
                          <td>
                            {item?.expDate ? (
                              <>
                                {moment(item?.createdAt).format("DD/MM/YYYY")}
                              </>
                            ) : (
                              <>---,---</>
                            )}
                          </td>
                          <td>
                            {item?.expDate ? (
                              <>{moment(item?.expDate).format("DD/MM/YYYY")}</>
                            ) : (
                              <>---,---</>
                            )}
                          </td>
                          <td>{item?.amt}</td>
                          <td>
                            <a href="#" class=" btn-h">
                              <i class="fa fa-folder"></i> View{" "}
                            </a>
                            <a href="#" class=" btn-h  ">
                              <i class="fa fa-pencil"></i> Print{" "}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donationhistory;
