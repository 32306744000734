import React from "react";
import Header from "./Header/Header";
import Navigation from "../Navigation";
import Footer from "./Footer/Footer";

function TermsCondition() {
  return (
    <>
      <Header />
      <Navigation />
      <div className="container">
        <h4 style={{fontWeight:"700"}}> Terms & Conditions</h4>
        <p>&#10174; 
          We SRI VINAYAKA CHARITABLE TRUST consider your privacy seriously,
          treats all financial information about any transaction, you have with
          SRI VINAYAKA CHARITABLE TRUST and its associated organizations as
          highly confidential. In additionally, SRI VINAYAKA CHARITABLE TRUST
          and/or its associated organizations do not share or phone numbers
          e-mail addresses or any other details of any of our donors or
          constituents/institutions.
        </p>
        <p>&#10174; 
          We SRI VINAYAKA CHARITABLE TRUST seriously value all the contributions
          to sustain our visions and missions. To protect the privacy of our
          donors and their special relationship with SRI VINAYAKA CHARITABLE
          TRUST, We maintain the following policies.
        </p>
        <p>&#10174; 
          We may take personal information online, such as name, address, phone
          number, email address, UPI details and credit card number only for the
          purposes of accepting donations to SRI VINAYAKA CHARITABLE TRUST and
          or associated organizations.
        </p>
        <p>&#10174; 
          We will not trade or sell your personal information with other
          organizations.
        </p>
        <p>&#10174; 
          We will not release or use your information for any other purpose
          unless we have your permission.
        </p>
        We offer donors the option to donate unknowingly.
        <p>&#10174; 
          We will not collect, track, or share personal information entered by
          those who visit our website.
        </p>
        <p>&#10174; Our Donors may request, at any time, to not receive our urgency.</p>
        <p>&#10174; 
          Our Donors may ask us to not receive certain communication from us.
        </p>
        <p>&#10174; 
          Personal data collected through our website is encrypted with Advanced
          Encryption Standard technology.
        </p>
        <p>&#10174; 
          {" "}
          Donor Personal information captured in SRI VINAYAKA CHARITABLE TRUST
          and/or its associated organizations database is protected with a
          secured login with authentication, assignment of a unique ID to each
          person with regular pass code changes computer access, and user IDs
          are deactivated or terminated as required.
        </p>
        <p>In this aspect, we assure the following:</p>
        <p>&#10174; 
          To be informed to SRI VINAYAKA CHARITABLE TRUST visions and missions,
          of the way SRI VINAYAKA CHARITABLE TRUST can be used donated
          resources, and of its capacity to use donations effectively for their
          required purposes.
        </p>
        <p>&#10174; 
          Donor’s Donations contributed will be used to meet the cost of relief
          requirements of the organization. In case of any excess funds at the
          end of a relief, the funds are directed towards our program.
        </p>
        <p>&#10174; 
          To be informed of the identity of those serving on SRI VINAYAKA
          CHARITABLE TRUST registered board, and to expect the board to exercise
          members judgment in their responsibilities.
        </p>
        <p>&#10174; 
          To have access to SRI VINAYAKA CHARITABLE TRUST most recent financial
          statements.
        </p>
        <p>&#10174; 
          To be assured that information about their donations is handled with
          respect and with confidentiality to the extent provided by law.
        </p>
        <p>&#10174; 
          To expect that all relationships with individuals representing SRI
          VINAYAKA CHARITABLE TRUST to the donor will be professional.
        </p>
        <p>&#10174; 
          To be informed whether those seeking donations are volunteers,
          employees of the organization or hired solicitors.
        </p>
        <p>&#10174; 
          To have the opportunity for their names to be deleted from mailing
          lists that IMPACT GURU FOUNDATION may intend to share.
        </p>
        <p>&#10174; Distributing receipts and thanking donors for donations purpose.</p>
        <p>&#10174; 
          Informing donors about upcoming fundraising and other activities of
          SRI VINAYAKA CHARITABLE TRUST and or associated our organizations
        </p>
        <p>&#10174; Record and Book keeping</p>
        <p>&#10174; Reporting to applicable government agencies as required by law</p>
        <p>&#10174; Other purposes related to the fundraising operations</p>
        <p>&#10174; 
          Any Unkown donor information may be used for promotional and
          fundraising activities. Comments that are provided by donors may be
          publicly published and may be used in promotional materials. We may
          use available information to supplement the donor data to improve the
          information will be used to drive our fund raising efforts. We may
          allow donors the option to have their name publicly associated with
          their donation unless otherwise requested as part of the online
          donation process.
        </p>
        <p>&#10174;
          We use data collected for payment process and other service providers
          only for the purposes described in this policy.
        </p>
        <p>Donation Cancellations & Refunds</p>
        <p> &#10174;
          Donations are non-refundable once the 80G certificate is issued.
          {/* (refer: Cancellation Policy).{" "} */}
        </p>
        <p>&#10174;
          For any technical issue please reach out
          srivinayakacharitabletrust@gmail.com{" "}
        </p>
        <h4 style={{fontWeight:"700"}}>
          Contact Us
        </h4>
        <p><b>&#10174; If you have questions about this Terms and Conditions or requests about the status and correctness of your donor data, please contact:</b></p>

        <p> <b>&#10174; SRI VINAYAKA CHARITABLE TRUST</b></p>
       <p> <b>&#10174; Registered Office: Building:<br/> 80feet Outer Ring road No.11/22, 3rd cross,<br/> Gnanabharathi post , Nagadevanahali Bangalore -560056</b></p>

      </div>
      <Footer />
    </>
  );
}

export default TermsCondition;
