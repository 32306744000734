import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { AiFillEdit } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

function AdimnBankDetails() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4">
            <button onClick={handleShow}>Add Bank Details</button>
          </div>
        </div>
        {/* <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Title</th>
                <th>Text</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <img src="" alt="graphic" style={{ width: "25%" }} />
                </td>
                <td></td>
                <td></td>
                <td>
                  <AiFillEdit
                    style={{ fontSize: "20px" }}
                    onClick={handleShow1}
                  />
                </td>
                <td>
                  <MdDeleteForever style={{ fontSize: "20px" }} />
                </td>
              </tr>
            </tbody>
          </Table>
        </div> */}
      </div>

      {/* Add Model */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="f-g">
          <div className="do-sear mt-2">
            <label>A/C Holder Name</label>
            <input
              type="text"
              placeholder="Enter Holder Name"
              className="vi_0"
            />
          </div>
          <div className="do-sear mt-2">
            <label>Bank Name</label>
            <input
              type="text"
              placeholder="Enter Bank Name"
              className="vi_0"
            />
          </div>
          <div className="do-sear mt-2">
            <label>Branch Name</label>
            <input
              type="text"
              placeholder="Enter Branch Name"
              className="vi_0"
            />
          </div>
          <div className="do-sear mt-2">
            <label>A/C Number</label>
            <input
              type="number"
              placeholder="Enter A/C Number"
              className="vi_0"
            />
          </div>
          <div className="do-sear mt-2">
            <label>IFSC Code</label>
            <input
              type="number"
              placeholder="Enter IFSC Code"
              className="vi_0"
            />
          </div>
          <div className="do-sear mt-2">
            <label>Google Pay Number</label>
            <input
              type="number"
              placeholder="Enter Google Pay Number"
              className="vi_0"
            />
          </div>
          <div className="do-sear mt-2">
            <label>Phone Pay Number</label>
            <input
              type="number"
              placeholder="Enter Phone Pay Number"
              className="vi_0"
            />
          </div>
          </div>
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Submit</Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Model */}
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Segements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="f-g">
            <label>Images</label> <br />
            <input type="file" />
          </div>
          <div className="f-g">
            <label>Title</label> <br />
            <input
              type="text"
              style={{
                border: " 1px solid black",
                borderRadius: " 4px",
                padding: "8px",
                width: " 100%",
              }}
            />
          </div>
          <div className="f-g">
            <label>Text</label> <br />
            <input
              type="text"
              style={{
                border: " 1px solid black",
                borderRadius: " 4px",
                padding: "8px",
                width: " 100%",
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary">Update</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdimnBankDetails;
