import React from "react";
import ShareIcon from "@mui/icons-material/Share";
import "./Fundraiser.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import parse from "html-react-parser";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation } from "react-router-dom";
import Sidefund from "./Sidefund";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import Navigation from "../../Navigation";
// import Contributenow from "./Contributenow";
const Fundraiser = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { state } = useLocation();
  // let arr = state?.content?.split("</p>");
  return (
    <div>
      <Header />
      <Navigation />

      <div
        style={{
          display: "flex",
          alignItems: "",
          justifyContent: "center",
        }}
      >
        <div className="fundraiser">
          <div className="container fund-image">
            <div style={{}}>
              <img
                src={`https://srivinayaka.info/Fundraise/${state?.bannerimg}`}
                style={{ height: "418px", width: "742px" }}
              ></img>
            </div>

            <div
              style={{
                marginTop: "30px",
                textAlign: "right",
                marginRight: "0px",
                width: "750px",
                height: "85px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                }}
              >
                <Link className="share" to="/">
                  {" "}
                  <ShareIcon />
                  <span
                    style={{
                      padding: "20px",
                    }}
                  >
                    Share this Fundraiser
                  </span>
                </Link>
              </div>
            </div>

            <div
              style={{
                textAlign: "center",
              }}
            >
              <p className=" fund-h1">About the Fundraiser</p>
            </div>

            <div
              style={{
                padding: "30px  70px 30px 70px",
              }}
            >
              <img
                src={`https://srivinayaka.info/Fundraise/${state?.img1}`}
                style={{
                  textAlign: "center",

                  width: "590px",
                  height: "350px",
                }}
              ></img>
            </div>
            <div
              style={{
                padding: "30px  70px 30px 70px",
              }}
            >
              <img
                src={`https://srivinayaka.info/Fundraise/${state?.img2}`}
                style={{
                  textAlign: "center",

                  width: "590px",
                  height: "350px",
                }}
              ></img>
            </div>

            <div
              style={{
                padding: "30px  70px 30px 70px",
              }}
            >
              <img
                src={`https://srivinayaka.info/Fundraise/${state?.img3}`}
                style={{
                  textAlign: "center",

                  width: "590px",
                  height: "350px",
                }}
              ></img>
            </div>

            <div
              style={{
                padding: "20px",
                textAlign: "justify",
              }}
            >
              <p>{parse(`<div>${state?.content}</div>`)}</p>
            </div>

            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
                marginRight: "0px",
                width: "750px",
                height: "85px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                }}
              >
                <Link className="share" to="/">
                  <span
                    style={{
                      padding: "20px",
                    }}
                  >
                    Read Latest Updates
                  </span>
                </Link>
              </div>
            </div>

            <div
              style={{
                marginTop: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "0px",
                width: "750px",
                height: "85px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                }}
              >
                <Link className="share" to="/">
                  <WhatsAppIcon />
                  <span
                    style={{
                      padding: "20px",
                    }}
                  >
                    Share
                  </span>
                </Link>
              </div>
              <div
                style={{
                  padding: "20px",
                }}
              >
                <Link className="share" to="/">
                  <FacebookIcon />
                  <span
                    style={{
                      padding: "20px",
                    }}
                  >
                    Share
                  </span>
                </Link>
              </div>
              <div
                style={{
                  padding: "20px",
                }}
              >
                <Button
                  className="share"
                  variant="primary"
                  onClick={handleShow}
                >
                  Contribute Now
                </Button>
              </div>
            </div>

            <div
              className="doation-methods"
              style={{
                marginTop: "50px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                }}
              >
                <p
                  style={{
                    borderBottom: "solid 2px #6b6b6b",
                    fontSize: "24px ",
                    fontWeight: "550px",
                  }}
                >
                  Other Donation Methods
                </p>
              </div>
              <div
                style={{
                  padding: "0px 40px 20px 40px",
                }}
              >
                <p style={{ fontSize: "17px ", fontWeight: "600px" }}>
                  {" "}
                  Transfer directly to bank account of Fundraiser
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  {" "}
                  <AccountBalanceIcon style={{ fontSize: "200px" }} />
                </div>

                <div style={{ width: "400px" }}>
                  <p style={{ width: "400px" }}>
                    <span style={{ padding: "0px" }}>Virtual A/C No</span>{" "}
                    <span style={{ padding: "0px" }}>:</span>
                    <span style={{ padding: "0px", textAlign: "center" }}>
                      -------------------
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span style={{ padding: "px", width: "250px" }}>
                      Virtual A/C Name
                    </span>{" "}
                    <span style={{ padding: "px" }}>:</span>
                    <span style={{ padding: "px" }}>-------------------</span>
                  </p>

                  <p>
                    {" "}
                    <span style={{ padding: "0px" }}>A/C Type</span>{" "}
                    <span style={{ padding: "0px" }}>:</span>
                    <span style={{ padding: "0px" }}>-------------------</span>
                  </p>
                  <p>
                    {" "}
                    <span style={{ padding: "0px" }}> IFSC </span>{" "}
                    <span style={{ padding: "0px" }}>:</span>
                    <span style={{ padding: "0px" }}>-------------------</span>
                  </p>
                </div>
              </div>
            </div>

            <Link
              className=""
              to="/"
              style={{
                textDecoration: "none",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: "10px",
                  background: "#111",
                  color: "#fff",
                  width: "750px",
                }}
              >
                Claim your donation acknowledgement now! For payment done via
                Bank Transfer
              </div>
            </Link>

            <div
              style={{
                padding: "25px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                }}
              >
                <p
                  style={{
                    borderBottom: "solid 2px #6b6b6b",
                    fontSize: "24px ",
                    fontWeight: "550px",
                  }}
                >
                  Comments
                </p>
              </div>
              <Form style={{ padding: "10px" }}>
                <FloatingLabel controlId="floatingTextarea2" label="Comments">
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>
                <br></br>

                <div className="submit">
                  <Button
                    variant="primary"
                    type="submit"
                    className="submit mt-1 "
                  >
                    Submit
                  </Button>
                </div>
              </Form>{" "}
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Donate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  padding: "20px auto",
                }}
              >
                <Form.Floating className="mb-3">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",

                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                      >
                        {" "}
                        ₹25
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",
                          marginLeft: "30px",
                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                      >
                        ₹50
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",
                          marginLeft: "30px",
                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                      >
                        {" "}
                        ₹70
                      </Button>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      marginLeft: "-30px",
                    }}
                  >
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",
                          marginLeft: "30px",
                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                      >
                        {" "}
                        ₹100
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",
                          marginLeft: "30px",
                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                      >
                        ₹200
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outline-success"
                        style={{
                          fontSize: "24px",
                          marginLeft: "30px",
                          marginTop: "20px",
                          borderColor: "",
                          fontWeight: "bold",

                          width: "120px",
                          height: "50px",
                        }}
                      >
                        {" "}
                        ₹500
                      </Button>
                    </div>
                  </div>
                  <br></br>

                  <div style={{ padding: "5px 10px 5px 10px " }}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="inputGroup-sizing-default">
                        Amount
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                      />
                    </InputGroup>
                  </div>

                  <Row>
                    <Col>
                      <Form.Label style={{ marginLeft: "10px" }}>
                        Donation Freequency
                      </Form.Label>

                      <div
                        style={{
                          padding: "0px 10px 5px 10px ",
                          marginTop: "-2px",
                        }}
                      >
                        <Form.Select
                          aria-label="Default select example"
                          style={{ height: "37px", padding: "4px" }}
                        >
                          <option value="1">One-time</option>
                          <option value="2">Monthly</option>
                          <option value="3">Quaterly</option>
                          <option value="4">Annually</option>
                        </Form.Select>
                      </div>
                    </Col>

                    <Col>
                      <Form.Label style={{ marginLeft: "0px" }}>
                        End Date
                      </Form.Label>
                      <Form.Control
                        style={{ marginLeft: "0px", width: "" }}
                        type="date"
                      />
                    </Col>
                  </Row>

                  <div
                    style={{
                      padding: "5px 10px 5px 10px ",
                      fontSize: "16px",
                      color: "#111",
                    }}
                  >
                    Message
                  </div>

                  <div style={{ padding: "5px 10px 5px 10px " }}>
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Message"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: "100px" }}
                      />
                    </FloatingLabel>
                  </div>

                  <div style={{ padding: "5px 10px 5px 10px " }}>
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          style={{
                            marginTop: "10px",
                          }}
                          label="Post my donation amount"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                        />
                        <Form.Check
                          inline
                          label="Post my name"
                          name="group1"
                          type={type}
                          id={`inline-${type}-2`}
                        />
                      </div>
                    ))}
                  </div>

                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Button variant="outline-success" size="lg" style={{}}>
                      {" "}
                      Donate
                    </Button>
                  </div>
                </Form.Floating>
              </div>
            </Modal.Body>
            {/* <Modal.Footer> */}
            {/* <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button> */}
            {/* <Button variant="primary">Understood</Button> */}
            {/* </Modal.Footer> */}
          </Modal>
        </div>
        {/* ============================================= */}

        <Sidefund />
      </div>
    </div>
  );
};

export default Fundraiser;
