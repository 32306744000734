import React, { useEffect } from "react";
import "./blogpost.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Sidetab from "./Sidetab";
import { Button } from "react-bootstrap";
import BlgFooter from "./BlgFooter";
import parse from "html-react-parser";
import moment from "moment";
import axios from "axios";
const Blogpost = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const updateViews = async (val) => {
    try {
      const config = {
        url: `/admin/countViews/${state?._id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: {
          userid: val,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
        }
      });
    } catch (error) {
      console.log(error);
      // alert(error.response.data.error);
    }
  };

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userToken) {
      updateViews(userDetails?.id);
    } else {
      updateViews("");
    }
  }, []);

  return (
    <>
      <div className="blog-post">
        <div>
          <div className="blog-post-read">
            <img
              src={`https://srivinayaka.info/Blogs/${state?.img}`}
              style={{ width: "750px", height: "400px" }}
            ></img>

            <div style={{ padding: "30px" }}>
              <p
                style={{
                  fontSize: "28px",
                  color: "#444444",
                  fontWeight: "600",
                }}
              >
                {" "}
                {state?.topic}
              </p>

              <div style={{ padding: "20px 0px 20px 0px", textAlign: "left" }}>
                <p className="" style={{ fontSize: "14px", color: "#6B6b6b" }}>
                  {" "}
                  {moment(state?.createdAt).format(`DD MMMM YYYY`)}
                </p>
              </div>

              <p
                className=""
                style={{
                  fontSize: "16px",
                  color: "#6b6b6b",
                  textAlign: "justify",
                }}
              >
                {parse(state?.content)}
              </p>

              {/* <div style={{ marginTop: "10px" }}>
                {" "}
                <p
                  className=""
                  style={{
                    fontSize: "16px",
                    color: "#6b6b6b",
                    textAlign: "justify",
                  }}
                >
                  Donec sed sem at enim bibendum molestie eu ut nisl.
                  Suspendisse dignissim eu leo a malesuada. Curabitur porta orci
                  quam, hendrerit rutrum turpis malesuada sit amet. Praesent
                  facilisis eu arcu a placerat. Vestibulum ante ipsum primis in
                  faucibus orci luctus et ultrices posuere cubilia Curae; Etiam
                  convallis neque in odio hendrerit, eu accumsan arcu blandit.
                </p>
              </div> */}
            </div>

            <div
              style={{
                padding: "25px",
              }}
            >
              <Form style={{ padding: "10px" }}>
                <FloatingLabel controlId="floatingTextarea2" label="Comments">
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>
                <br></br>

                <div className="submit">
                  <Button
                    variant="primary"
                    type="submit"
                    className="submit mt-1 "
                  >
                    Submit
                  </Button>
                </div>
              </Form>{" "}
            </div>
          </div>
          <br></br> <br></br>
          <div className="olderpost-btn" onClick={() => navigate(`/blog`)}>
            {" "}
            <span className="a-btn">BACK</span>{" "}
          </div>{" "}
          <br></br> <br></br>
        </div>

        <div
          style={{
            marginTop: "-20px",
          }}
        >
          {" "}
          <Sidetab></Sidetab>
        </div>
      </div>

      <BlgFooter />
    </>
  );
};

export default Blogpost;
