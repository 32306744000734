import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

function VolunteerProfile() {
  {
    /* ======================================================================================================= */
  }
  //   const formdata = new FormData();
  const volToken = localStorage.getItem("voluteerToken");
  const [VfName, setVfName] = useState("");
  const [vLName, setvLName] = useState("");
  const [Address, setAddress] = useState("");
  const [email, setemail] = useState("");
  const [phno, setphno] = useState("");
  const [aadhar, setaadhar] = useState("");
  const [pan, setpan] = useState("");
  const updateVol = async (e) => {
    e.preventDefault();
    // formdata.set("vfName", VfName);
    // formdata.set("vlName", vLName);
    // formdata.set("address", Address);
    // formdata.set("email", email);
    // formdata.set("phno", phno);
    // formdata.set("aadhar", aadhar);
    // formdata.set("pan", pan);volToken
    try {
      const config = {
        url: `/volunteer/editVolunteer/${Vol?._id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: {
          vfName: VfName,
          vlName: vLName,
          address: Address,
          email: email,
          phno: phno,
          aadhar: aadhar,
          pan: pan,
          volToken: volToken,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          window.location.reload(true);
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  {
    /* ======================================================================================================= */
  }

  const [Vol, setVol] = useState({});
  const getVolunteerById = async (id) => {
    try {
      const res = await axios.get(
        `https://srivinayaka.info/api/volunteer/getVolunteerByid/${id}`
      );
      if (res.status === 200) {
        setVol(res.data.volunteerDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  {
    /* ======================================================================================================= */
  }

  useEffect(() => {
    const volDetails = JSON.parse(localStorage.getItem("encodeVolInfo"));
    getVolunteerById(volDetails?.id);
  }, []);
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-3">
            {/* <button >Profile</button> */}
          </div>
          <div className="main-body">
            <div className="row">
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      {Vol?.vPic ? (
                        <img
                          src={`https://srivinayaka.info/Volunteer/${Vol?.vPic}`}
                          alt="Admin"
                          className="rounded-circle"
                          width="150"
                          height="150px"
                        />
                      ) : (
                        <img
                          src="./man.png"
                          alt="Admin"
                          className="rounded-circle"
                          width="150"
                          height="150px"
                        />
                      )}
                      <div className="mt-3">
                        <h4></h4>
                        <p className="text-secondary mb-1">
                          {Vol?.vfName} {Vol?.vlName}
                        </p>
                        <p className="text-muted font-size-sm">
                          {Vol?.dist} , {Vol?.city}.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <div className="do-sear mb-2">
                          <label className="mb-0">First Name</label>
                          <input
                            type="text"
                            placeholder={Vol?.vfName}
                            className="vi_0"
                            onChange={(e) => setVfName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Last Name</label>
                          <input
                            type="text"
                            placeholder={Vol?.vlName}
                            className="vi_0"
                            onChange={(e) => setvLName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Email</label>
                          <input
                            type="email"
                            placeholder={Vol?.email}
                            className="vi_0"
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Mobile Number</label>
                          <input
                            type="number"
                            placeholder={Vol?.phno}
                            className="vi_0"
                            onChange={(e) => setphno(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Address</label>
                          <input
                            type="text"
                            placeholder={
                              Vol?.address
                                ? Vol?.address
                                : "Please Enter your address here..."
                            }
                            className="vi_0"
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Pan Card Number</label>
                          <input
                            type="text"
                            placeholder={
                              Vol?.pan ? Vol?.pan : "Enter Pan Card Number"
                            }
                            className="vi_0"
                            onChange={(e) => setpan(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Aadhar Number</label>
                          <input
                            type="email"
                            placeholder={
                              Vol?.aadhar ? Vol?.aadhar : "Enter Aadhar Number"
                            }
                            className="vi_0"
                            onChange={(e) => setaadhar(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <Button
                            onClick={(e) => {
                              updateVol(e);
                            }}
                          >
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VolunteerProfile;
