import React, { useEffect, useRef, useState } from "react";
import "./Profile.css";
import Navigation from "./Navigation";
import Header from "./Components/Header/Header";
import axios from "axios";
const Profile = () => {
  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };

  const [info, setInfo] = useState([]);
  const getUser = async (id) => {
    try {
      const res = await axios.get(
        `https://srivinayaka.info/api/user/getuserByid/${id}`
      );
      if (res.status === 200) {
        setInfo(res.data.dHistory);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const userdetails = JSON.parse(localStorage.getItem("encodeUserInfo"));
    if (userdetails) {
      getUser(userdetails?.email);
    } else {
      window.location.assign("/");
    }
  });
  return (
    <div>
      <Navigation />

      <div class="container-xl px-4 mt-3">
        {/* <hr class="mt-0 mb-4"/> */}
        <div class="row">
          <div class="col-xl-4">
            <div class="card mb-4 mb-xl-0">
              <div class="card-header">Profile Picture</div>
              <div class="card-body text-center">
                <img
                  class="img-account-profile rounded-circle mb-2"
                  src="http://bootdey.com/img/Content/avatar/avatar1.png"
                  alt=""
                />

                <div class="small font-italic text-muted mb-4">
                  {info[0]?.fullName}
                </div>

                <button
                  onClick={onButtonClick}
                  class="btn btn-primary"
                  type="button"
                >
                  Upload new image
                </button>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          <div class="col-xl-8">
            <div class=" mb-3 ">
              <div class="">Account Details</div>
              <div class="">
                <form>
                  <div class="row gx-3 mb-2"></div>

                  <div class="row gx-3 mb-2">
                    <div class="col-md-6">
                      <label class="small mb-1" for="inputFirstName">
                        {" "}
                        Full Name
                      </label>
                      <input
                        class="form-control"
                        id="inputFirstName"
                        type="text"
                        placeholder={info[0]?.fullName}
                        value=""
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="small mb-1" for="inputLastName">
                        Email
                      </label>
                      <input
                        class="form-control"
                        id="inputLastName"
                        type="email"
                        placeholder={info[0]?.email}
                        value=""
                      />
                    </div>
                  </div>

                  <div class="row gx-3 mb-2">
                    {info[0]?.address ? (
                      <div class="col-md-6 ">
                        <label class="small mb-1" for="inputLocation">
                          Address
                        </label>
                        <textarea className="form-control "></textarea>
                      </div>
                    ) : (
                      <div class="col-md-6 ">
                        <label class="small mb-1" for="inputLocation">
                          Address
                        </label>
                        <textarea
                          className="form-control "
                          placeholder={info[0]?.address}
                        ></textarea>
                      </div>
                    )}
                    <div class="col-md-6">
                      <label class="small mb-1" for="inputPhone">
                        Phone number
                      </label>
                      <input
                        class="form-control"
                        id="inputPhone"
                        type="tel"
                        placeholder={info[0]?.phno}
                        value=""
                      />
                    </div>
                  </div>

                  <div class="row gx-3 mb-2">
                    <div class="col-md-6">
                      <label class="small mb-1" for="inputLastName">
                        Old Password
                      </label>
                      <input
                        class="form-control"
                        id="inputLastName"
                        type="password"
                        placeholder="Enter your password"
                        value=""
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="small mb-1" for="inputLastName">
                        N Password
                      </label>
                      <input
                        class="form-control"
                        id="inputLastName"
                        type="password"
                        placeholder="Enter your password"
                        value=""
                      />
                    </div>
                  </div>
                  <button class="btn btn-primary" type="button">
                    Save changes
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
