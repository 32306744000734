import React from "react";
import SideBar from "./SideBar";
import "./admin.css"

import AdminHeader from "./AdminHeader.";

function Dashboard(props) {
  return (
    <div className="dash">
      <div className="row me-0">
        <div className="col-md-2 p-0">
          <div className="left-side">
            <SideBar />
          </div>
        </div>

        <div className="col-md-10 p-0 right-h ">
          <AdminHeader />
          <div style={{marginLeft:"10px"}}>
          {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
