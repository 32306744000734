import React from "react";
import "./Register.css";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <div>
      <form className="border form-rei">
        <p className="h4 fw-medium text-center p-2">Registration Form</p>

        <p className="fs-6 text-center">
          {" "}
          Are you representing an Individual or Organization?
        </p>
        <div className="d-flex align-items-center justify-content-evenly mt-2">
          <div class="form-check text-center ">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
            />

            <label class="form-label" for="flexRadioDefault2">
              Individual
            </label>
          </div>

          <div class="form-check  text-center">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked
            />
            <label class="form-label" for="flexRadioDefault2">
              Organization
            </label>
          </div>
        </div>
        <hr></hr>

        <div className="">
          <form className="pl-5 pr-5">
            <div className="row">
              <div class="mb-2 col-md-6">
                <label for="exampleFormControlInput1" class="form-label">
                  Full Name
                </label>

                <input
                  type="text"
                  class="form-c border"
                  id="exampleFormControlInput1"
                  placeholder=" Full Name"
                />
              </div>
              <div class="mb-2 col-md-6">
                <label for="exampleFormControlInput1" class="form-label">
                  Mobile no
                </label>
                <input
                  type="tel"
                  class="form-c border"
                  placeholder="Mobile no"
                />
              </div>
            </div>

            <div className="row">
              <div class="mb-2 col-md-12">
                <label for="exampleFormControlInput1" class="form-label">
                  Email
                </label>

                <input
                  type="email"
                  class="form-c border"
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                />
              </div>
            </div>

            <div className="row">
              <div class="mb-2 col-md-12">
                <label for="exampleFormControlInput1" class="form-label">
                  Address
                </label>
                <textarea className="form-c border " />
              </div>
            </div>
            <div className="row">
              <div class="mb-2 col-md-6">
                <label for="exampleFormControlInput1" class="form-label">
                  Password
                </label>
                <input
                  type="password"
                  class="form-c border"
                  placeholder="Password"
                />
              </div>
              <div class="mb-2 col-md-6">
                <label for="exampleFormControlInput1" class="form-label">
                  Repeat Password
                </label>
                <input
                  type="password"
                  class="form-c border"
                  placeholder="Repeat password"
                />
              </div>
            </div>
            <div className="mb-3 mt-2 text-center ">
              <Link to="#">
                {" "}
                <button className=" btn btn-submit border ">Register</button>
              </Link>
            </div>
          </form>
        </div>
      </form>
    </div>
  );
};

export default Register;
