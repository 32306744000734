import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { AiFillEdit, AiOutlineEye } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { Button, Card, Form } from "react-bootstrap";
import axios from "axios";
import moment from "moment";

function AdminDonerlist() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [DonarList, setDonarList] = useState([]);
  const [SearchContent, setSearchContent] = useState("");
  const donarList = async (e, id) => {
    e.preventDefault();
    try {
      const res = await axios.get(
        `https://srivinayaka.info/api/volunteer/getDonarList/${id}`
      );
      if (res.status === 200) {
        setDonarList(res.data.donarList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Vols, setVols] = useState([]);
  const volList = async () => {
    try {
      const res = await axios.get(
        "https://srivinayaka.info/api/volunteer/getAllVolunteer"
      );
      if (res.status === 200) {
        setVols(res.data.allVolunteer);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    volList();
  }, []);

  return (
    <>
      <div className="add-gr">
        {/* <div className="container">
          <div className="ad-b mt-4">
            <button onClick={handleShow}>Add Doners List</button>
          </div>
        </div> */}
        {/* <div className="do-n">
          <div className="do-sear mt-4">
            <input type="text" placeholder="Search...." className="vi_0" />
          </div>
          <div className="do-sear">
            <label>From</label>
            <br />
            <input type="date" className="vi_0" />
          </div>
          <div className="do-sear ">
            <label>To</label>
            <br />
            <input type="date" className="vi_0" />
          </div>
          <div className="do-sear mt-4">
            <Button>Submit</Button>
          </div>
        </div> */}
        <div className="do-sear mt-4">
          <input
            type="text"
            placeholder="Search...."
            className="vi_0"
            onChange={(e) => setSearchContent(e.target.value)}
          />
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>volunteer id</th>
                  <th>volunteer Name</th>
                  <th>Contact No</th>
                  <th>Email Id</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Vols?.filter((item) => {
                  if (item?._id.includes(SearchContent)) {
                    return true;
                  }
                  if (
                    item?.vfName
                      ?.toLowerCase()
                      .includes(SearchContent?.toLowerCase())
                  ) {
                    return true;
                  }
                  if (
                    item?.vlName
                      ?.toLowerCase()
                      .includes(SearchContent?.toLowerCase())
                  ) {
                    return true;
                  }
                  if (item?.phno.includes(SearchContent)) {
                    return true;
                  }
                  if (
                    item?.email
                      ?.toLowerCase()
                      .includes(SearchContent?.toLowerCase())
                  ) {
                    return true;
                  }

                  if (SearchContent === "") {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}. </td>
                      <td>{item?._id}</td>
                      <td>{`${item?.vfName} ${item?.vlName}`}</td>
                      <td>{item?.phno}</td>
                      <td>{item?.email}</td>
                      <td>
                        <AiOutlineEye
                          onClick={(e) => {
                            setDonarList([]);
                            donarList(e, item?._id);
                            handleShow();
                          }}
                        />
                      </td>

                      {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
          <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* show Model */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Show Donars</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Name</th>
                <th>Contact No</th>
                <th>Email Id</th>
                <th>Donate Type</th>
                <th>Expiration Date</th>
                <th>Amount</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {DonarList.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}</td>
                    <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                    <td>{item?.fullName}</td>
                    <td>{item?.phno}</td>
                    <td>{item?.email}</td>
                    <td>{item?.donateType}</td>
                    <td>
                      {item?.expDate ? (
                        <>{moment(item?.expDate).format("DD/MM/YYYY")}</>
                      ) : (
                        <>--,--</>
                      )}
                    </td>
                    <td>{item?.amt}</td>
                    <td>{item?.remark ? <>{item?.remark}</> : <>--,--</>}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary">Submit</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminDonerlist;
