import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { AiFillEdit } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import useRazorpay from "react-razorpay";
import moment from "moment";

function VolunteerDonerlist() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const volToken = localStorage.getItem("voluteerToken");
  let volDetails = JSON.parse(localStorage.getItem("encodeVolInfo"));

  const Razorpay = useRazorpay();
  {
    /* ============================================================================================================== */
  }
  const [fullName, setfullName] = useState("");
  const [phno, setphno] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [donatetype, setDonatetype] = useState("");
  const [amt, setamt] = useState();
  const [remark, setremark] = useState("");
  const [payid, setpayid] = useState("");
  const [expDate, setexpDate] = useState(new Date());
  const [data, setdata] = useState({});
  const [SearchContent, setSearchContent] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [submit, setSubmit] = useState(false);

  const donarValidation = async () => {
    try {
      const config = {
        url: `/volunteer/addDonarValidation/${volDetails?.id}`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: {
          fullName: fullName,
          phno: phno,
          email: email,
          password: password,
          donateType: donatetype,
          amt: Number(amt),
          remark: remark,
          expDate: expDate,
          volToken: volToken,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          setdata(res.data.data);
          posttransaction();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const addDonar = async () => {
    data.payid = payid;
    data.volToken = volToken;
    try {
      const config = {
        url: `/volunteer/addDonar/${volDetails?.id}`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          donarList();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  {
    /* ============================================================================================================== */
  }

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const posttransaction = async () => {
    if (!fullName || !phno || !email || !donatetype || !amt) {
      return alert("please fill all the neccessary field");
    }
    try {
      const config = {
        data: {
          key: "rzp_test_5vCRZ6rLM2wGN4",
          amount: Number(amt) * 100,
          currency: "INR",
          name: "Vinayaka Trust",
          description: "Order Amount",
          image: "/Logo-1.jpg",
          email: email,
          handler: function (response) {
            alert(response.razorpay_payment_id);
            setpayid(response.razorpay_payment_id);
          },
          prefill: {
            email: email,
            phno: phno,
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };

  {
    /* ============================================================================================================== */
  }
  const [DonarList, setDonarList] = useState([]);
  const donarList = async (id) => {
    try {
      const res = await axios.get(
        `https://srivinayaka.info/api/volunteer/getDonarList/${id}`
      );
      if (res.status === 200) {
        setDonarList(res.data.donarList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  {
    /* ============================================================================================================== */
  }

  useEffect(() => {
    volDetails = JSON.parse(localStorage.getItem("encodeVolInfo"));
    donarList(volDetails?.id);
  }, []);
  useEffect(() => {
    if (payid) {
      addDonar();
    }
  }, [payid]);

  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4">
            <button onClick={handleShow}>Add Doners List</button>
          </div>
        </div>
        <div className="do-n">
          <div className="do-sear mt-4">
            <input
              type="text"
              placeholder="Search...."
              className="vi_0"
              onChange={(e) => setSearchContent(e.target.value)}
            />
          </div>
          {/* <div className="do-sear">
            <label>From</label>
            <br />
            <input
              type="date"
              className="vi_0"
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="do-sear ">
            <label>To</label>
            <br />
            <input
              type="date"
              className="vi_0"
              onChange={(e) => setendDate(e.target.value)}
            />
          </div>
          <div className="do-sear mt-4">
            <Button onClick={() => setSubmit(true)}>Submit</Button>
          </div> */}
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Contact No</th>
                  <th>Email Id</th>
                  <th>Donate Type</th>
                  <th>Expiration Date</th>
                  <th>Amount</th>
                  <th>Remarks</th>
                  <th>Receipt</th>
                </tr>
              </thead>
              <tbody>
                {DonarList.filter((item) => {
                  if (item?.fullName.includes(SearchContent)) {
                    return true;
                  }
                  if (item?.phno.includes(SearchContent)) {
                    return true;
                  }
                  if (item?.email.includes(SearchContent)) {
                    return true;
                  }

                  if (SearchContent === "") {
                    return true;
                  }
                  return false;
                }).map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                      <td>{item?.fullName}</td>
                      <td>{item?.phno}</td>
                      <td>{item?.email}</td>
                      <td>{item?.donateType}</td>
                      <td>
                        {item?.expDate ? (
                          <>{moment(item?.expDate).format("DD/MM/YYYY")}</>
                        ) : (
                          <>--,--</>
                        )}
                      </td>
                      <td>{item?.amt}</td>
                      <td>{item?.remark ? <>{item?.remark}</> : <>--,--</>}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* Add Model */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Doners</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Name*</label>
            <input
              type="text"
              placeholder="Enter Name"
              className="vi_0"
              onChange={(e) => setfullName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Contact No*</label>
            <input
              type="text"
              placeholder="Enter Contact No"
              className="vi_0"
              onChange={(e) => setphno(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Email Id*</label>
            <input
              type="email"
              placeholder="example123@gmail.com"
              className="vi_0"
              onChange={(e) => setemail(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Password*</label>
            <input
              type="password"
              placeholder="password"
              className="vi_0"
              onChange={(e) => setpassword(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Donate Type*</label>
            <Form.Select
              aria-label="Default select example "
              style={{
                border: " 2px solid #dee2e6",
                outlineWidth: " 0px",
                padding: " 7px",
                borderRadius: "5px",
                width: "100%",
              }}
              onChange={(e) => setDonatetype(e.target.value)}
            >
              <option>Donate Type</option>
              <option value="onetime">One Time</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </Form.Select>
          </div>
          {donatetype == "monthly" || donatetype == "yearly" ? (
            <div className="do-sear mt-2">
              <label>Expiration Date</label>
              <input
                type="date"
                className="vi_0"
                onChange={(e) => setexpDate(e.target.value)}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="do-sear mt-2">
            <label>Amount*</label>
            <input
              type="text"
              placeholder="Amount"
              className="vi_0"
              onChange={(e) => setamt(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Remarks</label>
            <input
              type="text"
              placeholder="Remarks"
              className="vi_0"
              onChange={(e) => setremark(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => donarValidation()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VolunteerDonerlist;
