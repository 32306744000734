import React from 'react'
import './blgfooter.css'
import { Link } from 'react-router-dom'

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
const BlgFooter = () => {
  return (
   <div>
     <div className='blgfooter'>
         <div
          style={{
            margin: "20px",

            width:'380px'
          }}
        >
          <p style={{color:'white'}}>POPULAR POSTS</p>
          <div
            style={{
              display: "flex",
            marginLeft:'-20px'
            }}
          >
            <div style={{ margin: "20px" }}>
              <img
                src="./slid1.png"
                style={{ height: "50px", width: "50px" }}
              ></img>
            </div>
            <div   style={{ margin: "20px" }}>
              <p 
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  width: "230px",
                  color: "#DA4453",
                }}
              >
                
              <Link className='post-text-f'>  How to Use WordPress Pingbacks And Trackbacks</Link>
              </p>
              <p style={{ color: "#6B6b6b", fontSize: "12px" }}>
                April 7, 2015
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",  marginLeft:'-20px',marginTop:'-10px'
            
            }}
          >
            <div style={{ margin: "20px" }}>
              <img
                src="./slid1.png"
                style={{ height: "50px", width: "50px" }}
              ></img>
            </div>
            <div style={{ margin: "20px" }}>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  width: "230px",
                  color: "#DA4453",
                }}
              >
                {" "}
                <Link className='post-text-f'>  How to Use WordPress Pingbacks And Trackbacks</Link>
              </p>
              <p style={{ color: "#6B6b6b", fontSize: "12px" }}>
                April 7, 2015
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",  marginLeft:'-20px',marginTop:'-10px'
            
            }}
          >
            <div style={{ margin: "20px" }}>
              <img
                src="./slid1.png"
                style={{ height: "50px", width: "50px" }}
              ></img>
            </div>
            <div style={{ margin: "20px" }}>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  width: "230px",
                  color: "#DA4453",
                }}
              >
                {" "}
                <Link className='post-text-f'>  How to Use WordPress Pingbacks And Trackbacks</Link>
               </p>
              <p style={{ color: "#6B6b6b", fontSize: "12px" }}>
                April 7, 2015
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",  marginLeft:'-20px',marginTop:'-10px'
              
            }}
          >
            <div style={{ margin: "20px" }}>
              <img
                src="./slid1.png"
                style={{ height: "50px", width: "50px" }}
              ></img>
            </div>
            <div style={{ margin: "20px" }}>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  width: "230px",
                  color: "#DA4453",
                }}
              >
                {" "}
                <Link className='post-text-f'>  How to Use WordPress Pingbacks And Trackbacks</Link>
              </p>
              <p style={{ color: "#6B6b6b", fontSize: "12px" }}>
                April 7, 2015
              </p>
            </div>
          </div>

 
     
           
        
        </div>



        <div
          style={{
            marginTop:'-130px',
            padding:"",
            width:"300px"
          }}
        >
          <p style={{color:'white'}}>CATEGORIES </p>

          <div className="category-f">
            <ul>
                <li ><div>lorem ipsum</div> <div>17</div></li>
               
            </ul>
          </div>

          <div className="category-f">
            <ul>
                <li ><div>lorem ipsum</div> <div>17</div></li>
               
            </ul>
          </div>
          <div className="category-f">
            <ul>
                <li ><div>lorem ipsum</div> <div>54</div></li>
               
            </ul>
          </div>
          <div className="category-f">
            <ul>
                <li ><div>lorem ipsum</div> <div>19</div></li>
               
            </ul>
          </div>
          <div className="category-f">
            <ul>
                <li ><div>lorem ipsum</div> <div>45</div></li>
               
            </ul>
          </div>


        </div>

        <div style={{width:'400px', marginTop:'-120px',marginLeft:'100px',color:'#dadada'}}>

           <div style={{padding:'20px',color:'#dadada'}}>
           <img src='' alt='imagehere'></img>BLOG
           </div>

          
            <p style={{padding:'10px',color:'white',fontSize:'14px',height:'250px',color:'#dadada'}}>

Awesome and completely free WordPress WooCommerce themes to take your ecommerce website to the next level.

If you are having problems with theme setup, please feel free to use Colorlib support forum.</p>

      
        </div>
        




    </div>

    <div className='footer-btm'>

    <div className="icons" style={{ padding: "10px  35px  10px 25px" }}>
          <FacebookIcon
            fontSize="large"
            style={{ margin: "10px", border: "" ,color:'white'}}
          />
          <TwitterIcon style={{ margin: "10px",color:'white' }} fontSize="large" />
          <YouTubeIcon style={{ margin: "10px" ,color:'white'}} fontSize="large" />
          <GitHubIcon style={{ margin: "10px" ,color:'white'}} fontSize="large" />
          <EmailIcon style={{ margin: "10px",color:'white' }} fontSize="large" />
        </div>


    </div>
   </div>
  )
}

export default BlgFooter