import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";

function Cause() {
  /* ======================================================================================================== */
  //   add cause modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [cause, setcause] = useState("");
  const [CauseList, setCauseList] = useState([]);
  const [Causeid, setCauseid] = useState("");
  const [Causeval, setCauseval] = useState("");

  /* ======================================================================================================== */

  //   edit cause modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  /* ======================================================================================================== */

  //   delete cause modal
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  /* ======================================================================================================== */
  const obj = {
    diseaseName: cause,
  };

  const addDisease = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/postDisease`,
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          setcause("");
          getDiseaseList();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */

  const getDiseaseList = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getDiseaseList")
        .then((res) => {
          if (res.status === 200) {
            setCauseList(res.data.diseaseList);
          }
        });
    } catch (error) {
      console.log(error);
      setCauseList([]);
    }
  };

  /* ======================================================================================================== */

  const obj1 = {
    diseaseName: cause,
  };

  const editCause = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/editDisease/${Causeid}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
        data: obj1,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setcause("");
          getDiseaseList();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  /* ======================================================================================================== */

  const deleteCause = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deleteDisease/${Causeid}`,
        method: "delete",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getDiseaseList();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  /* ======================================================================================================== */
  useEffect(() => {
    getDiseaseList();
  }, []);

  return (
    <div>
      <div
        className="main-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4>Diseases list</h4>
        <Button onClick={handleShow}>Add+</Button>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Diseases</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {CauseList.map((item, i) => {
              return (
                <tr>
                  <td> {++i}.</td>
                  <td>{item?.diseaseName}</td>
                  <td>
                    <BiEdit
                      style={{
                        color: "blue",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setCauseid(item?._id);
                        setCauseval(item);
                        handleShow1();
                      }}
                    />
                    /
                    <AiOutlineDelete
                      style={{ color: "red", marginLeft: "10px" }}
                      onClick={() => {
                        setCauseid(item?._id);
                        setCauseval(item);
                        handleShow2();
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {/* add disease cause modal - starts */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Disease</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>add Disease: </label>
          <input
            type="text"
            value={cause}
            required
            onChange={(e) => setcause(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => addDisease(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add Disease modal - ends */}

      {/* edit Disease modal - starts */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Disease name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label> Disease Name: </label>
          <input
            type="text"
            value={cause}
            placeholder={Causeval?.diseaseName}
            required
            onChange={(e) => setcause(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => editCause(e)}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit Disease modal - ends */}

      {/* delete Disease modal - starts */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the Disease name?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => deleteCause(e)}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete Disease modal - ends */}
    </div>
  );
}

export default Cause;
