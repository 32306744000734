import React, { useState } from "react";
import { Link } from "react-router-dom";

function VolunteerSideBar() {

  const[acc,setacc]=useState(true);
  const[acc1,setacc1]=useState(false);
  const[acc2,setacc2]=useState(false);
  const[acc3,setacc3]=useState(false);
  const[acc4,setacc4]=useState(false);
  const[acc5,setacc5]=useState(false);
  const[acc6,setacc6]=useState(false);
  return (
    <div className="si09">
      <div className="lo-ad">
        <img src="./implogo.jpeg" alt="adminlogo" style={{width:"230px",height:"57x",padding:"0px 11px"}}/>
      </div>
      <ul className="">
      <Link to="/volunteer-dashboard">
          <li className={`a-ele ${acc? "active-0":""}`} onClick={()=>{
            setacc(true);
            setacc1(false);
            setacc2(false);
            setacc3(false);
            setacc4(false);
            setacc5(false);
            setacc6(false);
          }}>Dashboard</li>
        </Link>
        <Link to="/volunteer-doner-list">
          <li className={`a-ele ${acc1 ? "active-0" : "null"}`} onClick={()=>{
            setacc(false);
            setacc1(true);
            setacc2(false);
            setacc3(false);
            setacc4(false);
            setacc5(false);
            setacc6(false);
          }}>Donars List</li>
        </Link>
        <Link to="/volunteer-profile">
          <li className={`a-ele ${acc2 ? "active-0" : "null"}`} onClick={()=>{
            setacc(false);
            setacc1(false);
            setacc2(true);
            setacc3(false);
            setacc4(false);
            setacc5(false);
            setacc6(false);
          }}>Profile</li>
        </Link>
        <Link to="/volunteer-bank-details">
          <li className={`a-ele ${acc3 ? "active-0" : "null"}`} onClick={()=>{
            setacc(false);
            setacc1(false);
            setacc2(false);
            setacc3(true);
            setacc4(false);
            setacc5(false);
            setacc6(false);
          }}>Bank Details</li>
        </Link>
        <Link to="/volunteer-expense">
          {" "}
          <li className={`a-ele ${acc4 ? "active-0" : "null"}`} onClick={()=>{
            setacc(false);
            setacc1(false);
            setacc2(false);
            setacc3(false);
            setacc4(true)
            setacc5(false);
            setacc6(false);
          }}>Expense</li>
        </Link>
        <Link to="/volunteer-id-card">
          {" "}
          <li className={`a-ele ${acc5 ? "active-0" : "null"}`} onClick={()=>{
            setacc(false);
            setacc1(false);
            setacc2(false);
            setacc3(false);
            setacc4(false);
            setacc5(true);
            setacc6(false)
          }}>ID Card</li>
        </Link>
        <Link to="/volunteer-change-password">
          {" "}
          <li className={`a-ele ${acc6 ? "active-0" : "null"}`} onClick={()=>{
            setacc(false);
            setacc1(false);
            setacc2(false);
            setacc3(false);
            setacc4(false);
            setacc5(false);
            setacc6(true);
          }}>Change Passwords</li>
        </Link>
      </ul>
    </div>
  );
}

export default VolunteerSideBar;
