import React, { useEffect, useState } from "react";
import "./services.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactElasticCarousel from "react-elastic-carousel";
import axios from "axios";
import parse from "html-react-parser";
const Services = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 4, itemsToScroll: 2 },
    { width: 760, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];

  const [Services, setServices] = useState([]);
  const getServices = async () => {
    try {
      await axios
        .get("https://srivinayaka.info/api/admin/getService")
        .then((res) => {
          if (res.status === 200) {
            setServices(res.data.ServiceList);
          }
        });
    } catch (error) {
      console.log(error);
      setServices([]);
    }
  };

  useEffect(() => {
    getServices();
  }, []);
  return (
    <Container fluid className="services" style={{}}>
      <div
        className="text-header"
        style={{ color: "white", paddingTop: "30px" }}
      >
        Services
      </div>

      <div className="services-cards">
        <ReactElasticCarousel breakPoints={breakPoints}>
          {Services?.map((item) => {
            return (
              <Card
                className="c1"
                style={{ width: "18rem", textAlign: "center" }}
              >
                <Card.Img
                  className="card-image"
                  variant="top"
                  src={`https://srivinayaka.info/Services/${item?.img}`}
                  style={{ height: "170px" }}
                />
                <Card.Body>
                  <Card.Title
                    className="card-title"
                    style={{ color: "#111", fontWeight: "400" }}
                  >
                    {item?.topic}
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "14px",
                      marginTop: "20px",
                      color: "#3c4d6b",
                      textAlign: "justify",
                    }}
                  >
                    {parse(item?.content?.substr(0, 150))}
                    {"..."}
                  </Card.Text>
                  <br></br>
                  <Link
                    to={`/services/${item?._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <Button variant="outline-danger"> KNOW MORE</Button>
                  </Link>
                </Card.Body>
              </Card>
            );
          })}
          {/* <Card className="c1" style={{ width: "18rem", textAlign: "center" }}>
            <Card.Img
              className="card-image"
              variant="top"
              src="./servi5.webp"
              style={{ height: "170px" }}
            />
            <Card.Body>
              <Card.Title
                className="card-title"
                style={{ color: "#111", fontWeight: "400" }}
              >
                Nursing & Personal Care
              </Card.Title>
              <Card.Text
                style={{
                  fontSize: "14px",
                  marginTop: "20px",
                  color: "#3c4d6b",
                  textAlign: "justify",
                }}
              >
                We provide self-contained rooms and 24 hour nursing & personal
                care for the aged person.
              </Card.Text>
              <br></br>
              <Link to="/services" style={{ textDecoration: "none" }}>
                {" "}
                <Button variant="outline-danger"> KNOW MORE</Button>
              </Link>
            </Card.Body>
          </Card>

          <Card className="c1" style={{ width: "18rem", textAlign: "center" }}>
            <Card.Img
              className="card-image"
              variant="top"
              src="./servi5.webp"
              style={{ height: "170px" }}
            />
            <Card.Body>
              <Card.Title
                className="card-title"
                style={{ color: "#111", fontWeight: "400" }}
              >
                Accommodation
              </Card.Title>
              <Card.Text
                style={{
                  fontSize: "14px",
                  marginTop: "20px",
                  color: "#3c4d6b",
                  textAlign: "justify",
                }}
              >
                All our accommodation rooms are well-furnished and come with
                plenty of in-room amenities.{" "}
              </Card.Text>
              <br></br>
              <Link to="/services" style={{ textDecoration: "none" }}>
                {" "}
                <Button variant="outline-danger"> KNOW MORE</Button>
              </Link>
            </Card.Body>
          </Card>

          <Card className="c1" style={{ width: "18rem", textAlign: "center" }}>
            <Card.Img
              className="card-image"
              variant="top"
              src="./acc.webp"
              style={{ height: "170px" }}
            />
            <Card.Body>
              <Card.Title
                className="card-title"
                style={{ color: "#111", fontWeight: "400" }}
              >
                Medical Check-Up
              </Card.Title>
              <Card.Text
                className="card-text"
                style={{
                  fontSize: "14px",
                  marginTop: "20px",
                  color: "#3c4d6b",
                  textAlign: "justify",
                }}
              >
                We conduct routine medical check-up and provide medication to
                all our elderly patients.
              </Card.Text>
              <br></br>
              <Link
                to="/services"
                className="btn-se"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <Button variant="outline-danger"> KNOW MORE</Button>
              </Link>
            </Card.Body>
          </Card> */}
        </ReactElasticCarousel>
      </div>
    </Container>
  );
};

export default Services;
