import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";

function AdminExpense() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div
            className="ad-b mt-4"
            style={{ display: "flex", gap: "21px", alignItems: "baseline" }}
          >
            <h4>Your Eligible to claim</h4>
            <button>10000</button>
            <button onClick={handleShow}>Claim Now</button>
          </div>
        </div>
        <div className="container">
      <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>From</th>
                <th>To</th>
                <th>Petrol</th>
                <th>Food</th>
                <th>Ticket</th>
                <th>Others</th>
                <th>Total Amount</th>
                <th>Remarks</th>
              
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
          <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
        {/* Add Model */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Expense</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="ad_exp mb-2">
              <div className="do-sear mt-2">
                <label>From</label>
                <input type="date" className="vi_0" />
              </div>
              <div className="do-sear mt-2">
                <label>To</label>
                <input type="date" className="vi_0" />
              </div>
            </div>
            <div className="ad_exp mb-2">
              {" "}
              <div className="do-sear mt-2">
                <label>Petrol</label>
              </div>
              <div className="do-sear mt-2">
                <input type="number" placeholder="4000" className="vi_0" />
              </div>
              <div className="do-sear mt-2">
                <input type="file" className="vi_0" />
              </div>
              <div className="do-sear mt-2">
                <Button variant="primary">Add</Button>
              </div>
            </div>
            <div className="ad_exp mb-2">
              {" "}
              <div className="do-sear mt-2">
                <label>Food</label>
              </div>
              <div className="do-sear mt-2">
                <input type="number" placeholder="4000" className="vi_0" />
              </div>
              <div className="do-sear mt-2">
                <input type="file" className="vi_0" />
              </div>
              <div className="do-sear mt-2">
                <Button variant="primary">Add</Button>
              </div>
            </div>
            <div className="ad_exp mb-2">
              {" "}
              <div className="do-sear mt-2">
                <label>Ticket</label>
              </div>
              <div className="do-sear mt-2">
                <input type="number" placeholder="4000" className="vi_0" />
              </div>
              <div className="do-sear mt-2">
                <input type="file" className="vi_0" />
              </div>
              <div className="do-sear mt-2">
                <Button variant="primary">Add</Button>
              </div>
            </div>
            <div className="ad_exp mb-2">
              {" "}
              <div className="do-sear mt-2">
                <label>Others</label>
              </div>
              <div className="do-sear mt-2">
                <input type="number" placeholder="4000" className="vi_0" />
              </div>
              <div className="do-sear mt-2">
                <input type="file" className="vi_0" />
              </div>
              <div className="do-sear mt-2">
                <Button variant="primary">Add</Button>
              </div>
            </div>
            <div className="do-sear mt-2">
              <label>Total Amount</label>
              <input
                type="number"
                placeholder="10000"
                className="vi_0"
              />
            </div>
            <div className="do-sear mt-2">
              <label>Remarks</label>
              <input type="text" placeholder="Remarks" className="vi_0" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary">Submit</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AdminExpense;
