import React from "react";
import "./idcard.css";

function IdCard() {
  return (
    <>
      <div className="id_vl container">
        <div className="row me-0">
          <div className="col-md-3">
            <div class="id-card-wrapper mt-3">
              <div className="logo-company">
                <img
                  src="./implogo.jpeg"
                  alt="adminlogo"
                  style={{ width: "256px", height: "100px" }}
                />
              </div>
              <div class="id-card semicircle">
                <div class="profile-row">
                  <div class="dp  ">
                    <div class="dp-arc-outer"></div>
                    <div class="dp-arc-inner"></div>
                    <img src="./man.png" />
                  </div>
                  <div class="desc">
                    <h1>Rahul</h1>
                    <p>ID: #68498</p>
                    <p>Place: Bangalore</p>
                    <p>Phone: 9089879890</p>
                    <p>
                     EXPIRES: <span>25Dec2023</span>
                    </p>
                  </div>
                  <div className="desc_0">
                    <p>VOLUNTEER</p>
                  </div>
                  <p className="text-center">www.vinayakatrust.com</p>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default IdCard;
