import React, { useState } from "react";
import "./signin.css";
import axios from "axios";
export default function (props) {
  const [Email, setEmail] = useState("");
  const [Pwd, setPwd] = useState("");

  const userLogin = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/user/userlogin",
        method: "post",
        baseURL: "https://srivinayaka.info/api",
        header: { "content-type": "application/json" },
        data: {
          email: Email,
          password: Pwd,
        },
      };

      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          // res.cookie("token", res.data.token, { httpOnly: true });
          // localStorage.setItem("voluteerToken", res.data.token);
          localStorage.setItem("encodeUserInfo", JSON.stringify(res.data.data));
          window.location.assign("/");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  {
    /* =============================================================================================== */
  }
  return (
    <div className="signin">
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="form-group mt-3">
              <label>Email Address</label>
              <input
                type="email or tel"
                className="form-control mt-1"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                onChange={(e) => setPwd(e.target.value)}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button
                type="submit"
                className="btn btn-primary btn-signin"
                onClick={(e) => userLogin(e)}
              >
                Submit
              </button>
            </div>
            {/* <p className="forgot-password text-right mt-2">
              Forgot <a href="#">password?</a>
            </p> */}
          </div>
        </form>
      </div>
    </div>
  );
}
