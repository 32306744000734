import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

function VolunteerExpense() {
  // const formdata = new FormData();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [show1, setShow1] = useState(false);
  // const handleClose1 = () => setShow1(false);
  // const handleShow1 = () => setShow1(true);

  const volToken = localStorage.getItem("voluteerToken");
  let volDetails = JSON.parse(localStorage.getItem("encodeVolInfo"));

  {
    /* ===================================================================================================== */
  }
  const [from, setfrom] = useState("");
  const [to, setto] = useState("");
  const [petrol, setpetrol] = useState(0);
  const [petrolRecipt, setpetrolRecipt] = useState("");
  // const [petrolRecipts, setpetrolRecipts] = useState([]);
  const [food, setfood] = useState(0);
  const [foodRecipt, setfoodRecipt] = useState("");
  // const [foodRecipts, setfoodRecipts] = useState([]);
  const [ticket, setticket] = useState(0);
  const [ticketRecipt, setticketRecipt] = useState("");
  // const [ticketRecipts, setticketRecipts] = useState([]);
  const [other, setother] = useState(0);
  const [otherRecipt, setotherRecipt] = useState("");
  // const [otherRecipts, setotherRecipts] = useState([]);
  const [totalAmt, settotalAmt] = useState(0);
  const [remarks, setremarks] = useState("");
  const [Recipt, setRecipt] = useState("");
  // function addPetrolReceipt() {
  //   const obj = {
  //     lastModified: petrolRecipt?.lastModified,
  //     lastModifiedDate: petrolRecipt?.lastModifiedDate,
  //     name: petrolRecipt?.name,
  //     size: petrolRecipt?.size,
  //     type: petrolRecipt?.type,
  //     webkitRelativePath: petrolRecipt?.webkitRelativePath,
  //   };
  //   setpetrolRecipts((curr) => [...curr, obj]);
  // }

  // function addfoodRecipt() {
  //   const obj = {
  //     lastModified: foodRecipt?.lastModified,
  //     lastModifiedDate: foodRecipt?.lastModifiedDate,
  //     name: foodRecipt?.name,
  //     size: foodRecipt?.size,
  //     type: foodRecipt?.type,
  //     webkitRelativePath: foodRecipt?.webkitRelativePath,
  //   };
  //   setfoodRecipts((curr) => [...curr, obj]);
  // }

  // function addticketRecipt() {
  //   const obj = {
  //     lastModified: ticketRecipt?.lastModified,
  //     lastModifiedDate: ticketRecipt?.lastModifiedDate,
  //     name: ticketRecipt?.name,
  //     size: ticketRecipt?.size,
  //     type: ticketRecipt?.type,
  //     webkitRelativePath: ticketRecipt?.webkitRelativePath,
  //   };
  //   setticketRecipts((curr) => [...curr, obj]);
  // }

  // function addotherRecipt() {
  //   const obj = {
  //     lastModified: otherRecipt?.lastModified,
  //     lastModifiedDate: otherRecipt?.lastModifiedDate,
  //     name: otherRecipt?.name,
  //     size: otherRecipt?.size,
  //     type: otherRecipt?.type,
  //     webkitRelativePath: otherRecipt?.webkitRelativePath,
  //   };
  //   setotherRecipts((curr) => [...curr, obj]);
  // }

  let obj = {
    from: from,
    to: to,
    petrol: petrol,

    petrolRecipt: petrolRecipt,
    food: food,
    foodRecipt: foodRecipt,
    ticket: ticket,
    ticketRecipt: ticketRecipt,
    other: other,
    otherRecipt: otherRecipt,
    totalAmt: totalAmt,
    remarks: remarks,
    volToken: volToken,
  };

  const expenseDetails = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: `/volunteer/addExpense/${volDetails?.id}`,
        method: "put",
        baseURL: "https://srivinayaka.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  {
    /* ===================================================================================================== */
  }

  const [Vol, setVol] = useState({});
  const getVolunteerById = async (id) => {
    try {
      const res = await axios.get(
        `https://srivinayaka.info/api/volunteer/getVolunteerByid/${id}`
      );
      if (res.status === 200) {
        setVol(res.data.volunteerDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  {
    /* ======================================================================================================= */
  }

  useEffect(() => {
    if (!volToken) {
      alert("Please login first");
      window.location.assign("/volunteerlogin");
    }
    const volDetails = JSON.parse(localStorage.getItem("encodeVolInfo"));
    getVolunteerById(volDetails?.id);
  }, []);

  useEffect(() => {
    let t = Number(petrol) + Number(food) + Number(ticket) + Number(other);
    settotalAmt(t);
    console.log("4334334334334", petrolRecipt);
  }, [petrol, food, ticket, other]);

  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div
            className="ad-b mt-4"
            style={{ display: "flex", gap: "21px", alignItems: "baseline" }}
          >
            <h4>Your Eligible to claim</h4>
            <button>10000</button>
            <button onClick={handleShow}>Claim Now</button>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Petrol</th>
                  <th>Petrol Receipt</th>
                  <th>Food</th>
                  <th>Food Receipt</th>
                  <th>Ticket</th>
                  <th>Ticket Receipt</th>
                  <th>Others</th>
                  <th>Other Receipt</th>
                  <th>Total Amount</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {Vol?.expenses?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{moment(item?.from).format("DD/MM/YYYY")}</td>
                      <td>{moment(item?.to).format("DD/MM/YYYY")}</td>
                      <td>{item?.petrol}</td>
                      <td>
                        <table>
                          <tbody>
                            {item?.petrolRecipt?.map((item1) => {
                              return (
                                <tr>
                                  <td>{item1}</td>
                                  <td>
                                    <a
                                      href={`https://srivinayaka.info/Volunteer/${item1}`}
                                      target="_blank"
                                    >
                                      <AiOutlineEye />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                      <td>{item?.food}</td>
                      <td>
                        <table>
                          <tbody>
                            {item?.foodRecipt?.map((item1) => {
                              return (
                                <tr>
                                  <td>{item1}</td>
                                  <td>
                                    <a
                                      href={`https://srivinayaka.info/Volunteer/${item1}`}
                                      target="_blank"
                                    >
                                      <AiOutlineEye />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                      <td>{item?.ticket}</td>
                      <td>
                        <table>
                          <tbody>
                            {item?.ticketRecipt?.map((item1) => {
                              return (
                                <tr>
                                  <td>{item1}</td>
                                  <td>
                                    <a
                                      href={`https://srivinayaka.info/Volunteer/${item1}`}
                                      target="_blank"
                                    >
                                      <AiOutlineEye />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                      <td>{item?.other}</td>
                      <td>
                        <table>
                          <tbody>
                            {item?.otherRecipt?.map((item1) => {
                              return (
                                <tr>
                                  <td>{item1}</td>
                                  <td>
                                    <a
                                      href={`https://srivinayaka.info/Volunteer/${item1}`}
                                      target="_blank"
                                    >
                                      <AiOutlineEye />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                      <td>{item?.totalAmt}</td>
                      <td>{item?.remarks ? item?.remarks : <>--/--</>}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        {/* Add Model - starts */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Expense</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="ad_exp mb-2">
              <div className="do-sear mt-2">
                <label>From</label>
                <input
                  type="date"
                  value={from}
                  className="vi_0"
                  onChange={(e) => setfrom(e.target.value)}
                />
              </div>
              <div className="do-sear mt-2">
                <label>To</label>
                <input
                  type="date"
                  value={to}
                  className="vi_0"
                  onChange={(e) => setto(e.target.value)}
                />
              </div>
            </div>
            <div className="ad_exp mb-2">
              {" "}
              <div className="do-sear mt-2">
                <label>Petrol</label>
              </div>
              <div className="do-sear mt-2">
                <input
                  type="number"
                  placeholder="4000"
                  value={petrol}
                  className="vi_0"
                  onChange={(e) => setpetrol(e.target.value)}
                />
              </div>
              <div className="do-sear mt-2">
                <input
                  type="file"
                  className="vi_0"
                  name="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    setpetrolRecipt(e.target.files);
                  }}
                  multiple
                />
                {/* <div>
                  <table>
                    <tbody>
                      {petrolRecipts.map((item) => (
                        
                        <tr>
                          <td>{item.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
              </div>
              {/* <div className="do-sear mt-2">
                <Button variant="primary" onClick={addPetrolReceipt}>
                  Add
                </Button>
              </div> */}
            </div>

            <div className="ad_exp mb-2">
              {" "}
              <div className="do-sear mt-2">
                <label>Food</label>
              </div>
              <div className="do-sear mt-2">
                <input
                  type="number"
                  placeholder="4000"
                  value={food}
                  className="vi_0"
                  onChange={(e) => setfood(e.target.value)}
                />
              </div>
              <div className="do-sear mt-2">
                <input
                  type="file"
                  className="vi_0"
                  name="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => setfoodRecipt(e.target.files)}
                  multiple
                />
                {/* <div>
                  <table>
                    <tbody>
                      {foodRecipts.map((item) => (
                        <tr>
                          <td>{item.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
              </div>
              {/* <div className="do-sear mt-2">
                <Button variant="primary" onClick={addfoodRecipt}>
                  Add
                </Button>
              </div> */}
            </div>
            <div className="ad_exp mb-2">
              {" "}
              <div className="do-sear mt-2">
                <label>Ticket</label>
              </div>
              <div className="do-sear mt-2">
                <input
                  type="number"
                  placeholder="4000"
                  value={ticket}
                  className="vi_0"
                  onChange={(e) => setticket(e.target.value)}
                />
              </div>
              <div className="do-sear mt-2">
                <input
                  type="file"
                  className="vi_0"
                  name="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => setticketRecipt(e.target.files)}
                  multiple
                />
                {/* <div>
                  <table>
                    <tbody>
                      {ticketRecipts.map((item) => (
                        <tr>
                          <td>{item.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
              </div>
              {/* <div className="do-sear mt-2">
                <Button variant="primary" onClick={addticketRecipt}>
                  Add
                </Button>
              </div> */}
            </div>
            <div className="ad_exp mb-2">
              {" "}
              <div className="do-sear mt-2">
                <label>Others</label>
              </div>
              <div className="do-sear mt-2">
                <input
                  type="number"
                  placeholder="4000"
                  value={other}
                  className="vi_0"
                  onChange={(e) => setother(e.target.value)}
                />
              </div>
              <div className="do-sear mt-2">
                <input
                  type="file"
                  className="vi_0"
                  name="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => setotherRecipt(e.target.files)}
                  multiple
                />
                {/* <div>
                  <table>
                    <tbody>
                      {otherRecipts.map((item) => (
                        <tr>
                          <td>{item.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
              </div>
              {/* <div className="do-sear mt-2">
                <Button variant="primary" onClick={addotherRecipt}>
                  Add
                </Button>
              </div> */}
            </div>
            <div className="do-sear mt-2">
              <label>Total Amount</label>
              <div>{totalAmt}</div>
              {/* <input
                type="number"
                placeholder="10000"
                value={totalAmt}
                className="vi_0"
                onChange={(e) => settotalAmt(e.target.value)}
              /> */}
            </div>
            <div className="do-sear mt-2">
              <label>Remarks</label>
              <input
                type="text"
                placeholder="Remarks"
                value={remarks}
                className="vi_0"
                onChange={(e) => setremarks(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => expenseDetails(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Add Model - ends */}

        {/* show image Model - starts */}
        {/* <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Receipt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={`https://srivinayaka.info/Volunteer/${Recipt}`} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
        {/* show image Model - ends */}
      </div>
    </>
  );
}

export default VolunteerExpense;
